import { useEffect, useState } from "react";
import {
  Paper, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Switch, Pagination
} from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { Boxed } from "../../Styles-Elements/Box"; 
import { API, PAGES } from "../../Enum";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { changePage } from "../../redux/reducers/page";
import { currentLocalData } from "../../redux/reducers/localData";
import { getDataByKeyLocale } from "../../Helpers/Helpers";
import globalRequest from "../../global-modules/globalRequest";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import ConfirmBox from "../../dialog/ConfirmBox";
import editIcon from '../../assets/images/structure/edit-black.svg';
import deleteIcon from '../../assets/images/structure/delete-black.svg';

const ManageUser = () => {
    
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  const limit = 10;
  const [users, setUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  let [page, setPage] = useState(1);
  let [pageTotal, setPageTotal] = useState(1);
  let [orderBy, setOrderBy] = useState("id");
  let [order, setOrder] = useState("desc");
  let [selectedStatus, setSelectedStatus] = useState("");
  let [storeOptions, setStoreOptions] = useState([]);

  const Sorting = ({ column, sort }) => {
    return (<div className="short-box">
      <ArrowDropUp onClick={() => sort(column, "asc")} />
      <ArrowDropDown onClick={() => sort(column, "desc") } />
    </div>);
  };

  const sortUsers = (column, orderAD) => {
    orderBy = column;
    setOrderBy(column);
    order = orderAD;
    setOrder(order);
    getUsers();
  };

  const getUsers = async () => {
    dispatch(changeLoader(true));
    let url = `${API.MANAGE_USERS.GET_USERS}?limit=${limit}&page=${page}&sort_by=${orderBy}&order_by=${order}`;
    try {
      const response = await globalRequest('get', url, {}, {}, true, true);
      if (response?.data?.ack === 1) {
        setUsers(response.data.results.rows);
        setPageTotal(Math.ceil(response.data.results.count / limit));
      } else {
        setUsers([]);
        setPageTotal(1);
      }
    } catch (error) {
      dispatch(changeSnackbar({
        message: error.message,
        isOpen: true,
        state: "error",
      }));
      orderBy = "id";
      setOrderBy(orderBy);
      order = "desc";
      setOrder(order);
      page = 1;
      setPage(page);
      getUsers();
    }
    dispatch(changeLoader(false));
  };

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + localData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    if (response.data.ack === 1) {
      storeOptionArray = response.data.stores
      // store.is_approved === null || 
        .filter((store) => store.userId == 0 && (store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    }
    storeOptions = storeOptionArray;
    setStoreOptions(storeOptions);
    dispatch(changeLoader(false));
  };

  const handleStatusChange = async () => {
    setShowStatusModal(false);
    dispatch(changeLoader(true));
    const response = await globalRequest(
      'post',
      API.MANAGE_USERS.CHANGE_STATUS(selectedId),
      { status: selectedStatus }, {}, true, true
    );
    if (response?.data?.ack === 1) {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "success",
      }));
      getUsers();
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    dispatch(changeLoader(false));
  };

  const checkStatusChange = async (id, status) => {
    const statusValue = status ? 'active' : 'inactive';
    setSelectedId(id);
    selectedStatus = statusValue;
    setSelectedStatus(selectedStatus);
    setShowStatusModal(true);
  };

  const deleteVendor = async () => {
    setShowDeleteModal(false)
    dispatch(changeLoader(true));
    const response = await globalRequest('delete', API.MANAGE_USERS.DELETE_USER(selectedId), {}, {}, true, true);
    if (response?.data?.ack === 1) {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "success",
      }));
      getStoreOptions();
      getUsers();
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoreOptions();
    getUsers();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper"> 
          <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={"20px"}
        >
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}> {t('manageUsers_ManageUser')} </h3>
          </div>
          {storeOptions.length > 0 &&
            <Button
            variant="contained"
            size="small"
            sx={{ width: "150px" }}
            className="date-picker-btn" 
            onClick={() => {
              sessionStorage.removeItem('user_id');
              dispatch(changePage(PAGES.MANAGE_USER_ADD_EDIT));
            }}
          > {t('manageUsers_AddUser')} </Button>}
        </Boxed>

        {users.length > 0?
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="short-div">
                    {t('manageUsers_UsersName')}
                    <Sorting column="full_name" sort={sortUsers} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="short-div">
                    {t('manageUsers_Store')}
                    <Sorting column="business_name" sort={sortUsers} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="short-div">
                    {t('manageUsers_storeAddress')}
                    <Sorting column="business_address" sort={sortUsers} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="short-div">
                    {t('manageUsers_Email')}
                    <Sorting column="email" sort={sortUsers} />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="short-div">
                    {t('manageUsers_Mobile')}
                    <Sorting column="contact_number" sort={sortUsers} />
                  </div>
                </TableCell>
                <TableCell align="center">{t('manageUsers_Status')}</TableCell>
                <TableCell align="center">{t('manageUsers_Action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user, index) => (<TableRow
                key={`vendor-user-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{user?.full_name}</TableCell>
                <TableCell>{user?.store?.stores_locales[0].business_name} </TableCell>
                <TableCell>{user?.store?.business_address} </TableCell>
                <TableCell>{user.email} </TableCell>
                <TableCell>+{user.country_code} {user.contact_number}</TableCell>
                <TableCell align="center">
                  <Switch
                    checked={user.status === 'active'}
                    onChange={(e) => checkStatusChange(user.id, e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </TableCell>
                <TableCell align="center">
                  <img
                    src={editIcon} alt="edit"
                    className="icon16 mx-2 pointer"
                    onClick={() => {
                      sessionStorage.setItem('user_id', user.id);
                      dispatch(changePage(PAGES.MANAGE_USER_ADD_EDIT));
                    }}
                  />  
                  <img
                    src={deleteIcon} alt="delete"
                    className="icon16 mx-2 pointer"
                    onClick={() => {
                      setSelectedId(user.id);
                      setShowDeleteModal(true);
                    }}
                  />  
                </TableCell>
              </TableRow>))}
            </TableBody>
          </Table>
        </TableContainer>:
        <NoDataFound text={t('manageUsers_NoDataFound')} />}
        {pageTotal > 1 && <div className="pagination-wrapper">
          <Pagination
            count={pageTotal}
            page={page}
            onChange={(e, value) => {
              page = value;
              setPage(value);
              getUsers();
            }}
            className="mt-4"
          />
        </div>}
      </div>
      {showDeleteModal && (
        <ConfirmBox 
          isOpen={showDeleteModal}
          message={t('manageUsers_ConfirmDelete')}
          handleClose={() => setShowDeleteModal(false)}
          confirm={deleteVendor}
        />
      )}
      {showStatusModal && (
        <ConfirmBox 
          isOpen={showStatusModal}
          message={t('manageUsers_ConfirmStatusChange', {status: selectedStatus})}
          handleClose={() => setShowStatusModal(false)}
          confirm={handleStatusChange}
        />
      )}
    </div>
  );
};

export default ManageUser;
