import { useEffect, useState } from "react";
import { Button, Grid, InputAdornment, TextField, IconButton} from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import globalRequest from "../../global-modules/globalRequest";
import { API, PAGES } from "../../Enum";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import validEmail from "../../global-modules/validEmail";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg"
import {PhoneMask} from "../../global-modules/MaskedInputs";
import { useSelector, useDispatch } from "react-redux";
import { currentLocalData } from "../../redux/reducers/localData";
import {changeLoader} from "../../redux/reducers/loader";
import {changeSnackbar} from "../../redux/reducers/snackbar";
import { changePage } from "../../redux/reducers/page";
import {getDataByKeyLocale} from "../../Helpers/Helpers";

const ManageUser = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  const [pageType, setPageType] = useState('add');
  const [storeId, setStoreId] = useState(null);
  const [storeIdErr, setStoreIdErr] = useState("");
  const [fullName, setFullName] = useState("");
  const [fullNameErr, setFullNameErr] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  let [storeOptions, setStoreOptions] = useState([]);
  let [showPassword, setShowPassword] = useState(false);
  let [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + localData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    if (response.data.ack === 1) {
      let uid = sessionStorage.getItem('user_id')? sessionStorage.getItem('user_id'): 0;
      storeOptionArray = response.data.stores
      // store.is_approved === null || 
        .filter((store) => (store.userId == 0 || store.userId == uid) && (store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
      if (!sessionStorage.getItem('user_id') && storeOptionArray.length > 0) {
        setStoreId(storeOptionArray[0]);
      }
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = storeOptionArray;
    setStoreOptions(storeOptions);
    if (sessionStorage.getItem('user_id')) {
      setPageType('edit');
      getUserForEdit(sessionStorage.getItem('user_id'));
    }
    dispatch(changeLoader(false));
  };

  const getUserForEdit = async (id) => {
    dispatch(changeLoader(true));
    const response = await globalRequest('get', API.MANAGE_USERS.GET_USERS_BY_ID(id), {}, {}, true, true);
    if (response.data.ack === 1) {
      const userData = response.data.results;
      setFullName(userData.full_name);
      setMobile(userData.contact_number);
      setEmail(userData.email);
      const storeSelected = storeOptions.find((store) => store.value === userData?.store.id);
      setStoreId(storeSelected);
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    dispatch(changeLoader(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(changeLoader(true));
    let isValid = true;
    if (!storeId) {
      setStoreIdErr(t("manageUsers_StoreIsRequired"));
      isValid = false;
    }
    if (fullName.trim() === "") {
      setFullNameErr(t("manageUsers_FullNameIsRequired"));
      isValid = false;
    }
    if (mobile.trim() === "") {
      setMobileErr(t("manageUsers_MobileNumberIsRequired"));
      isValid = false;
    } else if (mobile.length !== 8) {
      setMobileErr(t("manageUsers_MobileNumberMustBe8Digits"));
      isValid = false;
    }
    if (email.trim() === "") {
      setEmailErr(t("manageUsers_EmailAddressIsRequired"));
      isValid = false;
    } else if (!validEmail(email)) {
      setEmailErr(t('signup_valid_email'));
      isValid = false;
    }
    if (pageType === 'add') {
      if (password.trim() === "") {
        setPasswordErr(t("manageUsers_PasswordIsRequired"));
        isValid = false;
      }
      if (confirmPassword.trim() === "") {
        setConfirmPasswordErr(t("manageUsers_ConfirmPasswordIsRequired"));
        isValid = false;
      }
      if (password !== confirmPassword) {
        setConfirmPasswordErr(t("manageUsers_PasswordAndConfirmPasswordDoesNotMatch"));
        isValid = false;
      }
    }
    if (isValid) {
      const data = {
        email: email,
        full_name: fullName,
        country_code: "+974",
        contact_number: mobile,
        password: password,
        role:'vendor_users',
        storeId: storeId.value
      }
      let method = 'post';
      let url = API.MANAGE_USERS.ADD_USER;
      if (pageType === 'edit') {
        method = 'put';
        url = API.MANAGE_USERS.UPDATE_USER(sessionStorage.getItem('user_id'));
        delete data.password;
      }
      const response = await globalRequest(method, url, data, {}, true, true);
      if (response.data.ack === 1) {
        dispatch(changeSnackbar({
          message: response.data.msg,
          isOpen: true,
          state: "success",
        }));
        dispatch(changeLoader(false));
        if (sessionStorage.getItem('user_id')) {
          sessionStorage.removeItem('user_id');
        }
        dispatch(changePage(PAGES.MANAGE_USER));
      } else {
        if (response.data.errMsg) {
          let message = [];
          response.data.errMsg.forEach((element) => {
            for (const [key, value] of Object.entries(element)) {
              message.push(value);
            }
          });
          dispatch(changeSnackbar({
            message: message.join(', '),
            state: 'error',
            isOpen: true,
          }));
        } else {
          dispatch(changeSnackbar({
            message: response.data.msg,
            isOpen: true,
            state: "error",
          }));
        }
      }
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getStoreOptions();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper  max-w-810">
        <form onSubmit={handleSubmit} >
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"20px"}
          >
            <div className="store-detail-head" style={{ marginBottom: "0px" }}>
              <img
                src={arrowLeftBlack} alt="" className="rotate180"
                onClick={() => {
                  if (sessionStorage.getItem('user_id')) { sessionStorage.removeItem('user_id'); }
                  dispatch(changePage(PAGES.MANAGE_USER));
                }}
              />
              <h3 className="h3" style={{ marginRight: "0px" }}>
                {pageType ==='add'? t("manageUsers_AddUser"): t("manageUsers_EditUser")}
              </h3>
            </div>
          </Boxed>
          <div className="card-white">
            <div className="business-detail-wrap">
              <h6 className="h6">{t("manageUsers_UserDetails")}</h6>
              <p className="p3">
                {t("manageUsers_UpdateTheUserDetailWhichAreUsedForLoginPurpose")}
              </p>
              <div className="signup-right max-w-548">
                <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                  {storeOptions.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="form-group">
                      <SearchSelectSingleStore
                        options={storeOptions}
                        value={storeId}
                        handleOptionsChange={(event, newValues) => {
                          setStoreId(newValues);
                          setStoreIdErr("");
                        }}
                      />
                      <span className="redspan">{storeIdErr}</span>
                    </div>
                  </Grid>}
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField 
                        value={fullName}
                        onChange={(e) => {
                          setFullName(e.target.value)
                          setFullNameErr("")
                        }}
                        label={`${t("manageUsers_fullName")}*`} />
                      <span className="redspan">{fullNameErr}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        value={mobile}
                        onChange={(e) => {
                          setMobile(e.target.value)
                          setMobileErr("")
                        }}
                        label={`${t('manageUsers_mobileNumber')}*`}
                        inputProps={{ maxLength: 8 }}
                        InputProps={{
                          startAdornment: (<InputAdornment position="start">+974</InputAdornment>),
                          inputComponent: PhoneMask,
                        }}
                        />
                      <span className="redspan">{mobileErr}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value)
                          setEmailErr("")
                        }}
                      label={`${t("manageUsers_emailAddress")}*`} />
                      <span className="redspan">{emailErr}</span>
                    </div>
                  </Grid>
                  {pageType === 'add' &&<><Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        value={password}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((show) => !show)}
                                edge="end"
                              >
                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          setPassword(e.target.value)
                          setPasswordErr("")
                        }}
                        label={`${t("manageUsers_password")}*`}
                      />
                      <span className="redspan">{passwordErr}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        value={confirmPassword}
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowConfirmPassword((show) => !show)}
                                edge="end"
                              >
                                {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                          setConfirmPasswordErr("")
                        }}
                        label={`${t("manageUsers_confirmPassword")}*`}
                      />
                      <span className="redspan">{confirmPasswordErr}</span>
                    </div>
                  </Grid></>}
                </Grid>
              </div>
            </div>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "150px" }}
              type="submit"
              className="date-picker-btn" 
            > {t('manageUsers_save')} </Button>
          </Boxed>
        </form>
      </div>
    </div>
  );
};

export default ManageUser;
