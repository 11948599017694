/** @format */

import React, { useEffect, useState } from "react";
import "./addProduct.scss";
import TextField from "@mui/material/TextField";
import { Grid, Button, Select, MenuItem } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import arrowLeftBlack from "../../../assets/images/structure/arrow-left-black.svg";
import uploadImg from "../../../assets/images/structure/upload-black-icon.svg";
import cancelRed from "../../../assets/images/structure/cancel-red.svg";
import strings from "../../../localisation_en.json";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import errorMessage from "../../../global-modules/errorMessage";
import { currentLocalData } from "../../../redux/reducers/localData";
import { API, PAGES } from "../../../Enum";
import globalRequest from "../../../global-modules/globalRequest";
import { changeLoader } from "../../../redux/reducers/loader";
import { useNavigate } from "react-router";
import getBase64 from "../../../global-modules/getBase64";
import { currentProductData } from "../../../redux/reducers/editProductData";
import { API_URL } from "../../../BaseUrl";
import { changePage } from "../../../redux/reducers/page";
import { useTranslation } from "react-i18next";

let itemCodeTimer = null;

const EditUOM = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(true);
  const localData = useSelector(currentLocalData);
  const tokenData = localData.token;
  const adminCommission = tokenData?.store?.admin_commission;
  const productData = useSelector(currentProductData);
  const businessTypeId = tokenData?.store?.businessTypeId;
  const [productName, setProductName] = useState("");
  const [productNameErr, setProductNameErr] = useState(true);
  const [productNameCount, setProductNameCount] = useState(0);
  const [price, setPrice] = useState("");
  const [priceErr, setPriceErr] = useState(true);
  const [salePrice, setSalePrice] = useState("");
  const [productImageErr, setProductImageErr] = useState(true);
  const [tag, setTag] = useState([]);
  const [tagValue, setTagValue] = useState("");
  const [tagArabic, setTagArabic] = useState([]);
  const [tagValueArabic, setTagValueArabic] = useState("");
  const [unit, setUnit] = useState("");
  const [unitData, setUnitData] = useState([]);
  const [productNCondition, setProductNCondition] = useState([
    {
      name: t("manageProducts_FrontViewFeatured"),
      type: "frontView",
      ifUploaded: false,
      product_image: null,
      isRequired: true,
    },
    {
      name: t("manageProducts_OpenView"),
      type: "openView",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
    },
    {
      name: t("manageProducts_CloseUpView"),
      type: "closeUpView",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
    },
    {
      name: t("manageProducts_TopView"),
      type: "topView",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
    },
    {
      name: t("manageProducts_SideView"),
      type: "sideView",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
    },
  ]);
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryDatas, setSubCategoryDatas] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [subSubCategoryDatas, setSubSubCategoryDatas] = useState([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);
  const [platformFee, setPlatformFee] = useState(0);
  const [youWillEarn, setYouWillEarn] = useState(0);
  const [isItemDup, setIsItemDup] = useState(true);
  const [isItemDupErr, setIsItemDupErr] = useState(true);
  const [featureImgErr, setFeatureImgErr] = useState(true);
  const [quantity, setQuantity] = useState("");
  const [quantityErr, setQuantityErr] = useState(true);

  useEffect(() => {
    if (productData !== null) {
      setProductName(productData?.title);
      setPrice(productData?.price);
      setSalePrice(productData?.salePrice);

      let a = [];
      productNCondition.forEach((obj, ind) => {
        let ins = productData?.products_images[ind];
        if (ins === undefined) {
          a.push(obj);
        } else {
          a.push({
            ...obj,
            id: ins.id,
            product_image: `${API_URL}${ins.image}`,
          });
        }
      });
      setProductNCondition(a);
    }
  }, [productData]);

  const beforeUploads = (file, type = "frontView") => {
    const isJpgOrPng =
      file.type === "image/jpeg" || file.type === "image/png" || file.type == "image/jpg";
    if (!isJpgOrPng) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageProducts_UploadedImage"),
          state: "error",
        })
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageProducts_UploadedImageSmaller"),
          state: "error",
        })
      );
    }

    let f = isJpgOrPng && isLt5M;
    let a = [];
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, ifUploaded: f });
      } else {
        a.push(obj);
      }
    });
    setProductNCondition(a);
    return f;
  };

  const onImageChange = async ({ fileList }, type) => {
    let base64 = null;
    if (fileList.length > 0) {
      base64 = await getBase64(fileList[fileList.length - 1]?.originFileObj);
    }

    let a = [];
    let isInserted = false;
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, product_image: base64 });
        isInserted = obj.ifUploaded;
      } else {
        a.push(obj);
      }
    });

    if (isInserted) {
      setProductNCondition(a);
    }
  };

  const deleteImage = (type) => {
    let a = [];
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, product_image: null });
      } else {
        a.push(obj);
      }
    });
    setProductNCondition(a);
  };

  const submit = () => {
    if (productName && price && productNCondition[0].product_image !== null) {
      const form = new FormData();
      form.append("title", productName);
      form.append("price", price);
      form.append("salePrice", salePrice);
      form.append("image", productNCondition[0].product_image);
      form.append("quantity", quantity);

      if (productNCondition[1].product_image !== null) {
        form.append("image", productNCondition[1].product_image);
      }
      if (productNCondition[2].product_image !== null) {
        form.append("image", productNCondition[2].product_image);
      }
      if (productNCondition[3].product_image !== null) {
        form.append("image", productNCondition[3].product_image);
      }
      if (productNCondition[4].product_image !== null) {
        form.append("image", productNCondition[4].product_image);
      }

      dispatch(changeLoader(true));
      globalRequest("put", API.UPDATE_PRODUCT(productData?.id), form, {}, true, true)
        .then((res) => {
          const data = res?.data;
          if (data?.ack === 1) {
            dispatch(changePage(PAGES.MANAGE_PRODUCT));
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data?.msg,
              state: data?.ack == 1 ? "success" : "error",
            })
          );
          dispatch(changeLoader(false));
        })
        .catch((err) => {
          dispatch(changeLoader(false));
        });
    } else {
      setProductNameErr(false);
      setPriceErr(false);
      setProductImageErr(false);
    }
  };

  useEffect(() => {
    if (salePrice) {
      let p = (salePrice / 100) * adminCommission;
      setPlatformFee(p);
      setYouWillEarn(salePrice - p);
    } else {
      if (price) {
        let p = (price / 100) * adminCommission;
        setPlatformFee(p);
        setYouWillEarn(price - p);
      }
    }
  }, [price, salePrice]);

  return (
    <div className="main-mid-wrapper">
      <div className="add-product-wrapper">
        <div className="add-product-head">
          <img
            src={arrowLeftBlack}
            className="icon24 rotate180"
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(changePage(PAGES.MANAGE_PRODUCT))}
          />
          <h3 className="h3">{t("manageProducts_EditUOM")}</h3>
        </div>

        <div className="card-white">
          <h6 className="h6">{t("manageProducts_UOMInfoJasminCheeseSlices170gm")}</h6>
          <Grid
            container
            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
            xs={12}
            sm={12}
            md={12}
            lg={10}
          >
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group mb-0">
                <TextField
                  required 
                  label={t("manageProducts_Quantity_Unit")}
                  id="outlined-start-adornment"
                  value={quantity}
                  onChange={(e) => {
                    let m = String(e.target.value).replace(/\D/g, "");
                    setQuantity(m);
                  }}
                  className={`${canEdit ? "" : "no-event"}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        <Select
                          className={`${canEdit ? "" : "no-event"}`}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={unit}
                          label={t("manageProducts_Unit")}
                          displayEmpty
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          <MenuItem value="">Unit</MenuItem>
                          {unitData.map((obj, index) => {
                            return (
                              <MenuItem key={index} value={obj.id}>
                                {obj.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="form-group mb-0" >
                <TextField required label={t("manageProducts_RelationWithBase")} />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="form-group">
                <TextField className="disabled50"
                  required
                  label={t('manageProducts_Price')}
                  value={price}
                  onChange={(e) => {
                    let m = String(e.target.value).replace(/\D/g, "");
                    setPrice(m);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {t('manageProducts_QAR')}
                      </InputAdornment>
                    ),
                  }}
                />
                {!price && !priceErr ? (
                  <span className="redspan">{t('manageProducts_productPrice')}</span>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="form-group">
                <TextField className="disabled50"
                  value={salePrice}
                  onChange={(e) => {
                    let m = String(e.target.value).replace(/\D/g, "");
                    setSalePrice(m);
                  }}
                  label={t('manageProducts_SalePrice')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {t('manageProducts_QAR')}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="form-group">
                <TextField
                  className="disabled50 outline-none"
                  value={platformFee}
                  label={`Platform Fee (${adminCommission}%)`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {t('manageProducts_QAR')}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="form-group">
                <TextField
                  className="disabled50 "
                  value={youWillEarn}
                  label={t('manageProducts_YoullEarn')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        {t('manageProducts_YoullEarn')}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <h6 className="h6 mb-0">{t('manageProducts_ProductImages')}</h6>
          <p className="p2">{t('manageProducts_Fileformat_JPGJPEGandPNG')}</p>
          <Grid container xs={12} sm={12} md={12} lg={10}>
            <div className="uploads-row">
              {productNCondition.map((obj, index) =>
                  <div className="" key={index}>
                    <div className="upload-multiple">
                      {obj?.id !== undefined ? 
                        <img src={obj.product_image} alt=".." className="id-img" />
                      : obj?.id === undefined && obj.product_image !== null ? 
                        <img src={obj.product_image} alt=".." className="id-img" />
                      : <>
                            <Upload
                              listStyle={{ borderRadius: "50%" }}
                              listType="picture-card"
                              showUploadList={false}
                              beforeUpload={(files) => beforeUploads(files, obj.type)}
                              accept={".jpg,.jpeg,.png"}
                              disabled={true}
                            >
                              <img src={uploadImg} alt=".." className="upload-icon" />
                              <p className="upload-text">Upload</p>
                            </Upload>
                          {/* <ImgCrop></ImgCrop> */}
                        </>
                      }
                    </div>
                    <p className="p2">
                      {obj.name} {obj.isRequired ? <span className="required-star">*</span> : null}
                    </p>
                  </div>
              )}
            </div>
            {productNCondition[0].product_image == null && !featureImgErr ? (
              <span className="redspan">{t('manageProducts_featureImage')}</span>
            ) : null}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default EditUOM;
