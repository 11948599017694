/** @format */

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,Autocomplete , 
} from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import modalClose from "../assets/images/structure/close-black.svg";
import Select from "@mui/material/Select";
import InputAdornment from "@mui/material/InputAdornment";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../redux/reducers/loader";
import { currentLocalData } from "../redux/reducers/localData";
import { useTranslation } from "react-i18next";
import uploadImg from "../assets/images/structure/upload-black-icon.svg";
import cancelRed from "../assets/images/structure/cancel-red.svg";
import getBase64 from "../global-modules/getBase64";
import { API } from "../Enum";
import globalRequest from "../global-modules/globalRequest";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { API_URL } from "../BaseUrl";
import { amountMask, wholeNumberMask } from "../global-modules/MaskedInputs";
import { currentBaladiInfo } from "../redux/reducers/baladiInfo";

const AddUOM = ({
  isOpen = false,
  unitData = [],
  handleClose,
  updateValue,
  updateEditValue,
  uom,
  uomsCount,
  addOnOption,
  storeId
  // editProductData,
  // isEdit = false
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  const baladiInfo = useSelector(currentBaladiInfo);
  const tokenData = localData.token;
  const adminCommission = tokenData?.store?.admin_commission;
  const [canEdit, setCanEdit] = useState(true);
  let [productId, setProductId] = useState(0);
  let [quantity, setQuantity] = useState("");
  let [quantityError, setQuantityError] = useState("");
  let [unit, setUnit] = useState(null);
  let [unitError, setUnitError] = useState("");
  let [inStock, setInStock] = useState(true);
  let [inStatus, setInStatus] = useState(true);
  let [barcode, setBarcode] = useState("");
  let [uomcode, setUomcode] = useState("");
  let [uomentry, setUomentry] = useState("");
  let [stockqunatity, setStockqunatity] = useState("");
  let [uomentryError, setUomentryError] = useState("");
  let [barcodeOld, setBarcodeOld] = useState("");
  let [barcodeError, setBarcodeError] = useState("");
  let [uomcodeError, setUomcodeError] = useState("");
  let [relationBase, setRelationBase] = useState("");
  let [relationBaseError, setRelationBaseError] = useState("");
  let [stockqunatityError, setStockqunatityError] = useState("");
  let [uomNameEr, setUomNameEr] = useState("");
  let [uomNameErError, setUomNameErError] = useState("");
  let [price, setPrice] = useState(null);
  let [priceError, setPriceError] = useState("");
  let [salePrice, setSalePrice] = useState(null);
  let [salePriceError, setSalePriceError] = useState("");
  let [platformFee, setPlatformFee] = useState(null);
  let [youEarn, setYouEarn] = useState(null);
  let [featureImgErr, setFeatureImgErr] = useState("");
  let [removedImages, setRemovedImages] = useState([]);
  let [productNCondition, setProductNCondition] = useState([
    {
      name: t("manageProducts_FrontViewFeatured"),
      type: "front",
      ifUploaded: false,
      product_image: null,
      isRequired: true,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: t("manageProducts_OpenView"),
      type: "open",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: t("manageProducts_CloseUpView"),
      type: "closeup",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: t("manageProducts_TopView"),
      type: "top",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
    {
      name: t("manageProducts_SideView"),
      type: "side",
      ifUploaded: false,
      product_image: null,
      isRequired: false,
      isUploaded: false,
      path: "",
      percentage: 1,
    },
  ]);
  let [editRequest, setEditRequest] = useState(false);
  let [isDefault, setIsDefault] = useState(true);
  let [requestCreated, setRequestCreated] = useState(false);
  let [selectedAddOns, setSelectedAddOns] = useState([]);
  let [isEdit, setIsEdit] = useState(false);

  const Timer = ({ type }) => {
    const [time, setTime] = useState(0);
    const f = () => {
      setTime(time + 4);
    };

    useEffect(() => {
      f();
    }, []);

    useEffect(() => {
      if (time < 100) {
        let find = productNCondition.find((a) => a.type == type);
        if (find) {
          if (find.path) {
            setTime(100);
          } else {
            setTimeout(() => {
              f();
            }, 1500);
          }
        } else {
          setTimeout(() => {
            f();
          }, 1500);
        }
      }
    }, [time, productNCondition]);

    return (
      <>
        {time < 100 ? (
          <div className="progress-img">
            <span className="progress-count">{time}%</span>
            <div className="progress-box">
              <span
                className="progress-content"
                style={{ width: `${time}%` }}
              ></span>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  const handleRequestChange = () => {
    setRequestCreated(true);
  };

  const uploadSingleImage = (type, productImage, dataCallback) => {
    globalRequest("post", API.UPLOAD_PRODUCT_IMAGE_UOM, { image: productImage }, {}, true, true)
      .then((res) => {
        let data = res?.data;
        if (data?.ack === 1) {
          editRequest = true;
          setEditRequest(editRequest);
          dataCallback(data?.imageName);
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: `${type} ${t("manageProducts_imageUploadedSuccessfully")}`,
            state: data?.ack === 1 ? "success" : "error",
          })
        );
        handleRequestChange();
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
      });
  };

  const beforeUploads = (file, type = "frontView") => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type == "image/jpg";
    if (!isJpgOrPng) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageProducts_UploadedImage"),
          state: "error",
        })
      );
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("manageProducts_UploadedImageSmaller"),
          state: "error",
        })
      );
    }
    let f = isJpgOrPng && isLt5M;
    let a = [];
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, ifUploaded: f });
      } else {
        a.push(obj);
      }
    });
    setProductNCondition(a);
    return f;
  };

  const deleteImage = async (type) => {
    let a = [];
    let removedImage = [];
    let productNConditionS = productNCondition.map((obj) => {
      if (obj.type === type) {
        if (obj.path !== "") {
          removedImage.push({ name: obj.path, type: type });
        }
        obj.product_image = null;
        obj.path = "";
      }
      return obj;
    });
    setRemovedImages([...removedImages, removedImage[0]]);
    setProductNCondition(productNConditionS);
  };

  const onImageChange = async ({ fileList }, type) => {
    setFeatureImgErr("");
    let base64 = null;
    if (fileList.length > 0) {
      base64 = await getBase64(fileList[fileList.length - 1]?.originFileObj);
    }
    let a = [];
    let isInserted = false;
    productNCondition.forEach((obj) => {
      if (obj.type === type) {
        a.push({ ...obj, product_image: base64 });
        isInserted = obj.ifUploaded;
      } else {
        a.push(obj);
      }
    });
    if (isInserted) {
      setProductNCondition(a);
      uploadSingleImage(type, base64, (data) => {
        let a = [];
        productNCondition.forEach((obj) => {
          if (obj.type === type) {
            a.push({ ...obj, path: data, product_image: base64 });
          } else {
            a.push(obj);
          }
        });
        setProductNCondition(a);
      });
    }
  };

  const resetInputs = () => {
    productId = 0;
    setProductId(productId);
    setQuantity("");
    setUnit("");
    setUomNameEr("");
    setInStock(true);
    setInStatus(true);
    setBarcode("");
    setRelationBase("");
    setPrice("");
    setSalePrice("");
    setPlatformFee("");
    setYouEarn("");
    setIsDefault(false);
    setProductNCondition([
      {
        name: t("manageProducts_FrontViewFeatured"),
        type: "front",
        ifUploaded: false,
        product_image: null,
        isRequired: true,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: t("manageProducts_OpenView"),
        type: "open",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: t("manageProducts_CloseUpView"),
        type: "closeup",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: t("manageProducts_TopView"),
        type: "top",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
      {
        name: t("manageProducts_SideView"),
        type: "side",
        ifUploaded: false,
        product_image: null,
        isRequired: false,
        isUploaded: false,
        path: "",
        percentage: 1,
      },
    ]);
    setRelationBaseError("");
    setBarcodeError("");
    setQuantityError("");
    setUnitError("");
    setPriceError("");
    setFeatureImgErr("");
    if (uom === null) {
      isDefault = uomsCount === 0 ? true : false;
      setIsDefault(isDefault);
      setRelationBase(uomsCount === 0 ? "1" : "");
    } else {
      isDefault = false;
      setIsDefault(isDefault);
      setRelationBase("");
    }
    selectedAddOns = [];
    setSelectedAddOns(selectedAddOns);
  };

  const handleAddUom = async (e) => {
    e.preventDefault();
    let isValid = true;
    dispatch(changeLoader(true));
    if (relationBase === "") {
      setRelationBaseError(t("manageProducts_RelationBaseRequired"));
      isValid = false;
    }
    if (barcode === "") {
      setBarcodeError(t("manageProducts_BarcodeRequired"));
      isValid = false;
    }
    if (quantity === "") {
      setQuantityError(t("manageProducts_QuantityRequired"));
      isValid = false;
    } else if (unit === "") {
      setQuantityError(t("manageProducts_UnitRequired"));
      isValid = false;
    }
    if (uomNameEr === "") {
      setUomNameErError(t("manageProducts_uomNameErRequired"));
      isValid = false;
    }
    if (price === null) {
      setPriceError(t("manageProducts_PriceRequired"));
      isValid = false;
    }
    if (salePrice !== null) {
      if (parseFloat(price) < parseFloat(salePrice)) {
        setSalePriceError(t("manageProducts_SalePriceShouldBeLess"));
        isValid = false;
      }
    }
    productNCondition.forEach((obj) => {
      if (obj.type === "front" && obj.path === "") {
        setFeatureImgErr(t("manageProducts_FrontView"));
        isValid = false;
        document
          .getElementById("imageSpan")
          .scrollIntoView({ behavior: "smooth" });
      }
    });
    if (isValid) {
      const images = productNCondition
        .map((image) => {
          if (image.path !== "") {
            return {
              name: image.path,
              type: image.type,
            };
          }
        })
        .filter((image) => image !== undefined);
      let uomData = {
        isEdited: true,
        index: uom?.index,
        barcode: barcode,
        uomName: quantity,
        uomNameEr: uomNameEr,
        unitId: unit,
        UoMCode: uomcode,
        UoMEntry: uomentry,
        stockQuantity: stockqunatity === ''? 0: stockqunatity,
        relationWithBase: relationBase? parseInt(relationBase): 0,
        price: price? parseFloat(price): 0,
        salePrice: salePrice ? parseFloat(salePrice): 0,
        stock: inStock ? 1 : 0,
        isDefault: isDefault ? 1 : 0,
        image: images,
        status: inStatus? "active": "inactive",
        addons: selectedAddOns.map((obj) => { return { addonId: obj.id }; }),
        activeStore: uom?.activeStore,
        inactiveStore: uom?.inactiveStore,
      };
      let dataCheck = { barcode: barcode };
      if (uom) {
        dataCheck.uomId = uom.id;
      }
      globalRequest("post", API.UOM.CHECK_BARCODE, dataCheck, {}, true, true)
        .then(({ status, data }) => {
          if (data.ack === 1) {
            dispatch(changeLoader(false));
            if (uom) {
              if (uom?.id) {
                uomData.id = uom?.id;
              }
              updateEditValue(uomData, editRequest, requestCreated);
            } else {
              updateValue(uomData, editRequest, requestCreated);
            }
          } else {
            dispatch(changeLoader(false));
            barcodeError = data?.uomBarcode;
            setBarcodeError(barcodeError);
            document.getElementById("manageProducts_Barcode").focus();
          }
        }).catch((error) => {});
    } else {
      dispatch(changeLoader(false));
    }
  };

  const handleCloseDialog = () => {
    resetInputs();
    handleClose();
  };

  useEffect(() => {
    if (salePrice && salePrice !== "" && salePrice !== 0 && salePrice !== "0") {
      let p = (salePrice / 100) * adminCommission;
      setPlatformFee(p.toFixed(2));
      setYouEarn((salePrice - p).toFixed(2));
    } else {
      if (price) {
        let p = (price / 100) * adminCommission;
        setPlatformFee(p.toFixed(2));
        setYouEarn((price - p).toFixed(2));
      } else {
        platformFee = "";
        youEarn = "";
        setPlatformFee(platformFee);
        setYouEarn(youEarn);
      }
    }
  }, [price, salePrice]);

  useEffect(() => {
    resetInputs();
  }, []);

  useEffect(() => {
    isEdit = uom ? true : false;
    setIsEdit(isEdit);
    if (uom) {
      productId = uom.productId;
      setProductId(productId);
      quantity = uom.uomName;
      setQuantity(quantity);
      uomNameEr = uom.uomNameEr;
      setUomNameEr(uomNameEr);
      unit = uom.unitId;
      setUnit(unit);
      inStock = uom.stock === 1 ? true : false;
      inStatus = uom.status === "active" ? true : false;
      setInStock(inStock);
      setInStatus(inStatus);

      let barcodes = [];
      if (uom?.uom_barcode_relations) {
        barcodes = uom?.uom_barcode_relations?.map((obj) => obj.barcode);
        barcode = barcodes.join(",");
      } else {
        barcode = uom?.barcode;
      }
      setBarcode(barcode);
      barcodeOld = barcode;
      setBarcodeOld(barcodeOld);

      setUomcode(uom.UoMCode);
      setUomentry(uom.UoMEntry);
      setStockqunatity(uom.stockQuantity);

      relationBase = uom?.relationWithBase?.toString();
      setRelationBase(relationBase);
      price = uom?.price?.toString();
      setPrice(price);
      salePrice = uom?.salePrice?.toString();
      setSalePrice(salePrice);
      if (salePrice !== "" && salePrice !== "0") {
        let p = (salePrice / 100) * adminCommission;
        setPlatformFee(p.toFixed(2));
        setYouEarn((salePrice - p).toFixed(2));
      } else {
        if (price !== "") {
          let p = (price / 100) * adminCommission;
          setPlatformFee(p.toFixed(2));
          setYouEarn((price - p).toFixed(2));
        } else {
          platformFee = "";
          youEarn = "";
          setPlatformFee(platformFee);
          setYouEarn(youEarn);
        }
      }
      let frontImage = "";
      let openImage = "";
      let closeupImage = "";
      let topImage = "";
      let sideImage = "";
      if (uom?.uom_images?.length > 0) {
        uom?.uom_images?.forEach((element) => {
          if (element.type === "front") {
            frontImage = element.image;
          }
          if (element.type === "open") {
            openImage = element.image;
          }
          if (element.type === "closeup") {
            closeupImage = element.image;
          }
          if (element.type === "top") {
            topImage = element.image;
          }
          if (element.type === "side") {
            sideImage = element.image;
          }
        });
      } else if (uom?.image?.length > 0) {
        uom?.image?.forEach((element) => {
          if (element.type === "front") {
            frontImage = element.name;
          }
          if (element.type === "open") {
            openImage = element.name;
          }
          if (element.type === "closeup") {
            closeupImage = element.name;
          }
          if (element.type === "top") {
            topImage = element.name;
          }
          if (element.type === "side") {
            sideImage = element.name;
          }
        });
      }
      productNCondition = [
        {
          name: t("manageProducts_FrontViewFeatured"),
          type: "front",
          ifUploaded: false,
          product_image: null,
          isRequired: true,
          isUploaded: false,
          path: frontImage,
          percentage: 1,
        },
        {
          name: t("manageProducts_OpenView"),
          type: "open",
          ifUploaded: false,
          product_image: null,
          isRequired: false,
          isUploaded: false,
          path: openImage,
          percentage: 1,
        },
        {
          name: t("manageProducts_CloseUpView"),
          type: "closeup",
          ifUploaded: false,
          product_image: null,
          isRequired: false,
          isUploaded: false,
          path: closeupImage,
          percentage: 1,
        },
        {
          name: t("manageProducts_TopView"),
          type: "top",
          ifUploaded: false,
          product_image: null,
          isRequired: false,
          isUploaded: false,
          path: topImage,
          percentage: 1,
        },
        {
          name: t("manageProducts_SideView"),
          type: "side",
          ifUploaded: false,
          product_image: null,
          isRequired: false,
          isUploaded: false,
          path: sideImage,
          percentage: 1,
        },
      ];
      setProductNCondition(productNCondition);
      isDefault = uom.isDefault === 1 ? true : false;
      setIsDefault(isDefault);
      selectedAddOns = [];
      if (uom?.uom_addon_relations && uom?.uom_addon_relations.length > 0) {
        let ids = uom?.uom_addon_relations?.map((obj) => obj.addonId); 
        selectedAddOns = (uom?.uom_addon_relations?.length > 0) ?
          addOnOption.filter((obj) => ids.includes(obj.id)):
          []
      } else {
        let ids = uom?.addons?.map((obj) => obj.addonId);
        selectedAddOns = addOnOption.filter((obj) => ids?.includes(obj.id));
      }
      setSelectedAddOns(selectedAddOns);
    }
    setTimeout(() => {
      setRequestCreated(false);
    }, 1000);
  }, [uom]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="main-modal maxwidth-600"
    >
      <img
        src={modalClose}
        alt="..." 
        onClick={handleCloseDialog}
        className="modal-close"
      />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: 18 }}>
          {t("manageProducts_UOMInfo")}
        </h4>
        <div className="uom-content">
          <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: "24px" }}>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <div className="form-group mb-0">
                <TextField
                  id="manageProducts_Barcode"
                  label={t("manageProducts_Barcode") + " *"}
                  value={barcode}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setBarcode(e.target.value);
                    setBarcodeError("");
                    handleRequestChange();
                  }}
                />
                <span className="redspan">{barcodeError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <div className="form-group mb-0">
                <TextField
                  label={t("manageProducts_Quantity_Unit") + " *"}
                  id="uomName"
                  inputProps={{ maxLength: 50 }}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value);
                    setQuantityError("");
                    handleRequestChange();
                  }}
                  className={`${canEdit ? "" : "no-event"}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className="bg-pink">
                        <Select
                          className={`${canEdit ? "" : "no-event"}`}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={unit}
                          label={t("manageProducts_Unit")}
                          onChange={(e) => {
                            setUnit(e.target.value);
                            handleRequestChange();
                          }}
                        >
                          {/* <MenuItem value="" style={{ display: "none" }}>
                            {t("manageProducts_Unit")}
                          </MenuItem> */}
                          {unitData.map((obj, index) => (
                            <MenuItem key={index} value={obj.id}>
                              {obj?.unit_locales[0]?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
                <span className="redspan">{quantityError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{
              marginTop: '20px'
            }}>
              <div className="form-group mb-0">
                <TextField
                  label={t("manageProducts_RelationWithBase") + " *"}
                  id="relationWithBase"
                  value={relationBase}
                  inputProps={{ maxLength: 5 }}
                  InputProps={{ inputComponent: wholeNumberMask }}
                  disabled={isDefault}
                  onChange={(e) => {
                    setRelationBase(isDefault ? "1" : e.target.value);
                    setRelationBaseError("");
                    handleRequestChange();
                  }}
                />
                <span className="redspan">{relationBaseError}</span>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{
              marginTop: '20px'
            }}>
              <div className="form-group mb-0">
                <TextField
                  label={t("manageProducts_uomNameEr") + " *"}
                  id="relationWithBase"
                  value={uomNameEr}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e) => {
                    setUomNameEr(e.target.value);
                    setUomNameErError("");
                    handleRequestChange();
                  }}
                />
                <span className="redspan">{uomNameErError}</span>
              </div>
            </Grid>

            {/*  */}
            {storeId === baladiInfo.BALADI_BRAND_Id?<Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{
              marginTop: '20px'
            }}>
              <div className="form-group mb-0">
                <TextField
                  id="manageProducts_uomCode"
                  label={t("manageProducts_UomCode") + " *"}
                  value={uomcode}
                  onChange={(e) => {
                    setUomcode(e.target.value);
                    setUomcodeError("");
                    handleRequestChange();
                  }}
                />
                <span className="redspan">{barcodeError}</span>
              </div>
            </Grid>: null}
            {storeId === baladiInfo.BALADI_BRAND_Id?<Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{
              marginTop: '20px'
            }}>
              <div className="form-group mb-0">
                <TextField
                  id="manageProducts_uomEntry"
                  label={t("manageProducts_uomEntry") + " *"}
                  value={uomentry}
                  onChange={(e) => {
                    setUomentry(e.target.value);
                    setUomentryError("");
                    handleRequestChange();
                  }}
                />
                <span className="redspan">{barcodeError}</span>
              </div>
            </Grid>: null}

            {/* {storeId === baladiInfo.BALADI_BRAND_Id?
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{
              marginTop: '20px'
            }}>
              <div className="form-group mb-0">
                <TextField
                  label={t("manageProducts_stockQunatity") + " *"}
                  id="stockqunatity"
                  value={stockqunatity}
                  onChange={(e) => {
                    setStockqunatity(e.target.value);
                    setStockqunatityError("");
                    handleRequestChange();
                  }}
                />
                <span className="redspan">{relationBaseError}</span>
              </div>
            </Grid>: null} */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              container
              columnSpacing={{ xs: 0, sm: 2, md: 3, lg: "24px" }}
              style={{
                marginTop: '20px'
              }}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    label={t("manageProducts_Price") + " *"}
                    className={`${canEdit ? "" : "no-event"}`}
                    value={price}
                    id="price"
                    onChange={(e) => {
                      setPrice(e.target.value);
                      setPriceError("");
                      editRequest = true;
                      setEditRequest(editRequest);
                      handleRequestChange();
                    }}
                    InputProps={{
                      inputComponent: amountMask,
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          {t("manageProducts_QAR")}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="redspan">{priceError}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    className={`${canEdit ? "" : "no-event"}`}
                    label={t("manageProducts_SalePrice")}
                    value={salePrice}
                    id="salePrice"
                    onChange={(e) => {
                      setSalePrice(e.target.value);
                      setSalePriceError("");
                      editRequest = true;
                      setEditRequest(editRequest);
                      handleRequestChange();
                    }}
                    InputProps={{
                      inputComponent: amountMask,
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          {t("manageProducts_QAR")}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <span className="redspan">{salePriceError}</span>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    className="disabled50 outline-none"
                    placeholder={`${t(
                      "manageProducts_PlatformFee"
                    )} (${adminCommission}%)`}
                    value={platformFee}
                    inputProps={{ readOnly: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          {t("manageProducts_QAR")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <div className="form-group">
                  <TextField
                    className="disabled50"
                    placeholder={t("manageProducts_YoullEarn")}
                    value={youEarn}
                    inputProps={{ readOnly: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" className="bg-pink">
                          {t("manageProducts_YoullEarn")}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="switch-box">
                  <label className="h6">
                    {t("manageProducts_ProductUOMNote")}
                  </label>
                </div>
              </Grid>
              {/* {storeId !== 1?<Grid item xs={12}>
                <div className="switch-box">
                  <label className="h6">
                    {t("manageProducts_Stock")}
                    <span className="required-star">*</span>
                  </label>
                  <Switch
                    className={`${canEdit ? "" : "no-event"}`}
                    checked={inStock}
                    onChange={(e) => setInStock(e.target.checked)}
                  />
                </div>
              </Grid>: null} */}
              <Grid item xs={12}>
                <div className="switch-box">
                  <label className="h6">
                    {t("manageProducts_Status")}
                    <span className="required-star">*</span>
                  </label>
                  <Switch
                    className={`${canEdit ? "" : "no-event"}`}
                    checked={inStatus}
                    onChange={(e) => setInStatus(e.target.checked)}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <h6 className="h6 ">{t("manageProducts_ProductImages")}</h6>
          <p className="p3">{t("manageProducts_Fileformat_JPGJPEGandPNG")}</p>
          <Grid container xs={12} sm={12} md={12} lg={12}>
            <div className={`uploads-row ${canEdit ? "" : "no-event"}`}>
              {productNCondition.map((obj, index) => (
                <div className="" key={index}>
                  <div className="upload-multiple">
                    {obj.product_image !== null ? (
                      <>
                        <img
                          src={cancelRed}
                          alt=".."
                          onClick={() => {
                            deleteImage(obj.type);
                            handleRequestChange();
                          }}
                          className="delete-icon"
                        />
                        <img
                          src={obj.product_image}
                          alt=".."
                          className="id-img"
                        />
                        <Timer type={obj.type} />
                      </>
                    ) : obj.path !== "" ? (
                      <>
                        <img
                          src={cancelRed}
                          alt=".."
                          onClick={() => {
                            deleteImage(obj.type);
                            handleRequestChange();
                          }}
                          className="delete-icon"
                        />
                        <img
                          src={`${API_URL}${obj.path}`}
                          alt=".."
                          className="id-img"
                        />
                      </>
                    ) : (
                      <>
                          <Upload
                            listStyle={{ borderRadius: "50%" }}
                            listType="picture-card"
                            showUploadList={false}
                            beforeUpload={(files) =>
                              beforeUploads(files, obj.type)
                            }
                            accept={".jpg,.jpeg,.png"}
                            onChange={(file) => onImageChange(file, obj.type)}
                          >
                            <img
                              src={uploadImg}
                              alt=".."
                              className="upload-icon"
                            />
                            <p className="upload-text">
                              {t("manageProducts_Upload")}
                            </p>
                          </Upload>
                        {/* <ImgCrop></ImgCrop> */}
                      </>
                    )}
                  </div>
                  <p className="p2">
                    {obj.name}{" "}
                    {obj.isRequired ? (
                      <span className="required-star">*</span>
                    ) : null}
                  </p>
                </div>
              ))}
              <span className="redspan" id="imageSpan">
                {featureImgErr}
              </span>
            </div>
          </Grid>

          <Grid xs={12} sm={12} md={12} lg={12}>
            <h6 className="h6">{t("Add on")}</h6>
            <p className="p3">{t("Add add on to this UOM which you want to add")}</p>
              <div className="form-group">
              <Autocomplete
                multiple
                fullWidth
                className="add-on-select-option"
                id="checkboxes-tags-demo"
                options={addOnOption}
                value={selectedAddOns}
                disableCloseOnSelect
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Select Add on" placeholder="Select Add on" />
                )}
                onChange={(event, value) => {
                  selectedAddOns = value;
                  setSelectedAddOns(selectedAddOns)
                }}
              />
              </div>
          </Grid>
        </div>
        <div className="uom-footer">
          <FormControlLabel
            control={
              <Checkbox
                checked={isDefault}
                onChange={(e) => {
                  setIsDefault(e.target.checked);
                  setRelationBase(e.target.checked ? "1" : relationBase);
                }}
              />
            }
            label={t("manageProducts_Setitasdefaultbase")}
          />
          <Button
            variant="contained"
            size="large"
            className="min-width140"
            onClick={handleAddUom}
          >
            {t("manageProducts_Save")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddUOM;
