import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogContent } from "@mui/material";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { API } from "../Enum";
import { currentLocalData } from "../redux/reducers/localData";
import modalClose from "../assets/images/structure/close-black.svg";
import globalRequest from "../global-modules/globalRequest";
import SearchSelectStore from "../shared/SearchSelectStore";

const ProductInactiveModal = ({
  isOpen = false,
  handleClose,
  productToEdit,
  storeOptions,
  handleSuccess
}) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  let [selectedStores, setSelectedStores] = useState([]);

  const handleSave = async () => {
    dispatch(changeLoader(true));
    const data = {
      status: productToEdit.status,
      productId: productToEdit.product.id.toString(),
      storeId: selectedStores.filter((store) => store.value !== 0).map((store) => store.value).join(","),
    };
    globalRequest('post', API.MANAGE_PRODUCTS.CHANGE_PRODUCT_STATUS, data, {}, true, true)
    .then((res) => {
      if (res.data.ack === 1) {
        dispatch(changeSnackbar({
          isOpen: true,
          message: res.data.msg,
          state: "success",
        }));
        handleSuccess();
      } else {
        if (res.data.errMsg) {
          res.data.errMsg?.forEach(element => {
            for (const [key, value] of Object.entries(element)) {
              dispatch(
                changeSnackbar({
                  isOpen: true,
                  message: value,
                  state: "error",
                })
              );    
            }
          });
        } else {
          dispatch(changeSnackbar({
            isOpen: true,
            message: res.data.msg,
            state: "error",
          }));
        }
      }
    }).catch((err) => {
      dispatch(changeSnackbar({
        isOpen: true,
        message: t("somethingWentWrong"),
        state: "error",
      }));
    });
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    selectedStores = tokenData.token.role === 'vendor_users'?
      storeOptions.filter((store) => store.value === tokenData.token.store.id):
      storeOptions;
    setSelectedStores(selectedStores);
  }, [productToEdit]);

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px' }}>
          {t("manageStore_doYouWantToMakeThisProductInactive")}
        </h4>
        <p className="p3" style={{ marginBottom: "16px" }}>
          {t("manageStore_onceDeactivatedProductWillBeDisabledAutomatically")}
        </p>
        <div className="background-box" style={{ marginBottom: "24px"}}>
          <h6 className="h6" style={{ marginBottom: "8px",marginTop: '8px' }}>
            #{productToEdit.product.itemCode}
          </h6>
          <p className="p2" style={{ marginBottom: "8px",marginTop: '8px' }}>
            {productToEdit.product.products_locales[0].title}
          </p>
        </div>
        <div className="form-group"
          style={tokenData.token.role === 'vendor_users' ? { pointerEvents: 'none', opacity: '0.4' }: {}}
        >
          <SearchSelectStore
            options={storeOptions}
            value={selectedStores?.map((store) => store.value)}
            changeSelectedStores={(e) => setSelectedStores(storeOptions.filter((store) => e.includes(store.value)))}
          />
        </div>
        <Boxed>
          <Button
            type="button" variant="contained" sx={{ width: "100%", marginTop: "20px" }}
            onClick={handleSave}
          >{t("manageStore_save")}</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default ProductInactiveModal;
