import { Boxed } from "../../Styles-Elements/Box"
import { Grid, Button } from '@mui/material';
import "./OrderDetails.scss"
import Header2 from '../layout/header2/Header2';
import Sidebar from '../layout/sidebar/Sidebar'
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg"
import downloadRed from "../../assets/images/structure/download-red.svg"
import pizzaImgSmall from "../../assets/images/products/pizza-img-small.svg"
import locationBlack from "../../assets/images/structure/location-black.svg"
import messageBlack from "../../assets/images/structure/message-black.svg"
// Importing localised strings
const strings = require('../../localisation_en.json');

const OrderDetails = () => {

  return (
    <div>
      <div className="main-wrapper full-bg">
        <Header2 />
        <Sidebar />
        <div className="main-mid-wrapper">
          <div className="order-detail-wrapper">
            <div className="card-white">
              <div className="store-detail-head">
                <img src={arrowLeftBlack} alt="" className="rotate180" />
                <h3 className="h3">#646546544</h3>
                <p className="p2 p2-tertiary">{strings.pending}</p>
                <p className="p2 p2-tertiary">{strings.processing}</p>
                <p className="p2 p2-secondary">{strings.readyToPick}</p>
                <p className="p2 p2-secondary">{strings.shipped}</p>
                <p className="p2 p2-secondary">{strings.completed}</p>
                <p className="p2 p2-primary">{strings.rejected}</p>
                <p className="p2 p2-primary">{strings.cancelled}</p>
              </div>
              <div className="store-bottom-head-block">
                <Boxed display={"flex"} alignItems={"center"}>
                  <div className="block-boxes">
                    <p className="p3">{strings.orderDate}</p>
                    <p className="p2">17 Jan 2023 - 11:30 AM</p>
                  </div>
                  <div className="block-boxes">
                    <p className="p3">{strings.paymentMethod}</p>
                    <p className="p2">{strings.cod}</p>
                  </div>
                  <div className="block-boxes">
                    <p className="p3">{strings.deliverBy}</p>
                    <p className="p2">18 Jan 2023 - 11:30 AM</p>
                  </div>
                </Boxed>
                <Boxed display={"flex"} alignItems={"center"} className="download-box">
                  <img src={downloadRed} alt="" />
                  <p className="p2">{strings.downloadInvoice}</p>
                </Boxed>
              </div>
            </div>
            <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4 }}>
              <Grid item xs={8}>
                <div className="card-white">
                  <div className="order-product-detail-wrap">
                    <p className="p3">{strings.productDetails}</p>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img src={pizzaImgSmall} className="product-blocks-img" alt="" />
                        <div className="product-blocks-content">
                          <p className="p2">Margherita {strings.pizza}</p>
                          <p className="p3">1 x {strings.QAR} 20.00</p>
                        </div>
                      </Boxed>
                      <p className="p2">{strings.QAR} 20.00</p>
                    </div>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img src={pizzaImgSmall} className="product-blocks-img" alt="" />
                        <div className="product-blocks-content">
                          <p className="p2">Farmhouse {strings.pizza}</p>
                          <p className="p3">2 x {strings.QAR} 20.00</p>
                        </div>
                      </Boxed>
                      <p className="p2">{strings.QAR} 40.00</p>
                    </div>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img src={pizzaImgSmall} className="product-blocks-img" alt="" />
                        <div className="product-blocks-content">
                          <p className="p2">Peppy Paneer {strings.pizza}</p>
                          <p className="p3">1 x {strings.QAR} 10.00</p>
                        </div>
                      </Boxed>
                      <p className="p2">{strings.QAR} 10.00</p>
                    </div>
                    <div className="product-blocks">
                      <Boxed display={"flex"} alignItems={"center"} className="">
                        <img src={pizzaImgSmall} className="product-blocks-img" alt="" />
                        <div className="product-blocks-content">
                          <p className="p2">Fresh Veggie {strings.pizza}</p>
                          <p className="p3">2 x {strings.QAR} 15.00</p>
                        </div>
                      </Boxed>
                      <p className="p2">{strings.QAR} 30.00</p>
                    </div>
                  </div>
                </div>
                <div className="card-white">
                  <div className="order-shipping-detail-wrap">
                    <p className="p3">{strings.shippingDetails}</p>
                    <div className="shipping-blocks">
                      <h6 className="h6">Ellias Andrew | +974 98765432</h6>
                      <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                        <img src={locationBlack} alt="" />
                        <p className="p3">219 Goldfield Drive, New York, United States,NY 10004</p>
                      </Boxed>
                      <Boxed display={"flex"} alignItems={"center"} mb={"8px"}>
                        <img src={messageBlack} alt="" />
                        <p className="p3">allex_carry@gmail.com</p>
                      </Boxed>
                    </div>
                  </div>
                </div>
                <div className="card-white">
                  <div className="order-order-tracking-wrap">
                    <p className="p3">{strings.orderTracking}</p>
                    <div className="order-tracking-block">
                      <div className="order-tracking-list finish success">
                        <small className="dot-circle"></small>
                        <span className="p2">{strings.orderReceived}</span>
                        <span className="p3">17 Jan 2023 - 11:30 AM</span>
                      </div>
                      <div className="order-tracking-list success">
                        <small className="dot-circle"></small>
                        <span className="p2">{strings.processing}</span>
                        <span className="p3">18 Jan 2023 - 11:30 AM</span>
                      </div>
                      <div className="order-tracking-list">
                        <small className="dot-circle"></small>
                        <span className="p2">{strings.readyToPick}</span>
                        <span className="p3">18 Jan 2023 - 11:30 AM</span>
                      </div>
                      <div className="order-tracking-list">
                        <small className="dot-circle"></small>
                        <span className="p2">{strings.shipped}</span>
                        <span className="p3">18 Jan 2023 - 11:30 AM</span>
                      </div>
                      <div className="order-tracking-list">
                        <small className="dot-circle"></small>
                        <span className="p2">{strings.completed}</span>
                        <span className="p3">18 Jan 2023 - 11:30 AM</span>
                        {/* <span className='p3' style={{color: '#A9ACAA'}}>{strings.itemYetToBeDelivered}</span> */}
                      </div>
                      <div className="order-tracking-list">
                        <small className="dot-circle reject-cancel"></small>
                        <span className="p2">{strings.rejected}</span>
                        <span className="p3">18 Jan 2023 - 11:30 AM</span>
                      </div>
                      <div className="order-tracking-list">
                        <small className="dot-circle reject-cancel"></small>
                        <span className="p2">{strings.cancelled}</span>
                        <span className="p3">18 Jan 2023 - 11:30 AM</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="card-white">
                  <div className="order-payment-details-wrap">
                    <p className="p3">{strings.paymentDeatils}</p>
                    <div className="payment-lists">
                      <span className="p2">{strings.orderTotal}</span>
                      <span className="p2-bold">{strings.QAR} 100.00</span>
                    </div>
                    <div className="payment-lists">
                      <span className="p2">{strings.tax}</span>
                      <span className="p2-bold">{strings.QAR} 20.15</span>
                    </div>
                    <div className="payment-lists">
                      <span className="p2">{strings.deliveryCharges}</span>
                      <span className="p2-bold">{strings.QAR} 20.00</span>
                    </div>
                    <div className="payment-lists-total">
                      <span className="p2">{strings.totalAmount}</span>
                      <span className="p2">{strings.QAR} 140.15</span>
                    </div>
                  </div>
                </div>
                <Boxed
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mt={"20px"}
                >
                  <Button
                    className="secondary-gray"
                    variant="contained"
                    size="large"
                    sx={{ width: "140px" }}
                  >
                    {strings.reject}
                  </Button>
                  <Button
                    className="secondary"
                    variant="contained"
                    size="large"
                    sx={{ width: "140px" }}
                  >
                    {strings.accept}
                  </Button>
                </Boxed>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
