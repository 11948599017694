import React, { useEffect, useState } from "react";
import Geocode from "react-geocode";
import {
  Button, FormControl, Grid, InputAdornment, Autocomplete,
  createFilterOptions, MenuItem, TextField, InputLabel, Select,
} from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { changeLoader } from "../../redux/reducers/loader";
import globalRequest from "../../global-modules/globalRequest";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg";
import uploadBlackIcon from "../../assets/images/structure/upload-black-icon.svg";
import cancelRed from "../../assets/images/structure/cancel-red.svg";
import closeGray from "../../assets/images/structure/close-gray.svg";
import { currentLocalData } from "../../redux/reducers/localData";
import readImageFile from "../../global-modules/readImageFile";
import { changePage } from "../../redux/reducers/page";
import { API, ROLE, PAGES, COUNTRY_CODE, KEYS } from "../../Enum";
import { currentStoreData, currentStoreOption } from "../../redux/reducers/editStoreData";
import { geocodeByPlaceId, } from 'react-google-places-autocomplete';
import GoogleAutocompletePlaces from '../../global-modules/Google-libraries/GoogleAutocompletePlaces';
import MyMapComponent from '../../global-modules/Google-libraries/MapComponent';
import AutocompleteAddress from '../../global-modules/OpenStreetMap-library/AutocompleteAddress';

const placeAutocompleteOptions = 'google';

const MyStoreDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storeOption = useSelector(currentStoreOption);
  const storeData = useSelector(currentStoreData);
  const localData = useSelector(currentLocalData);
  let [cuisineList, setCuisineList] = useState([]);
  let [buildingAddress, setBuildingAddress] = useState("");
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [buildingNo, setBuildingNo] = useState("");
  let [apartmentNo, setApartmentNo] = useState("");
  let [floorNo, setFloorNo] = useState("");
  let [googleAddress, setGoogleAddress] = useState([]);
  const [country, setCountry] = useState("Qatar");
  let [showMaps, setShowMaps] = useState(false);
  const [businessTypeList, setBusinessTypeList] = useState([]);
  let [bType, setBType] = useState("");
  const [bName, setBName] = useState("");
  const [bNameArabic, setBNameArabic] = useState("");
  const [businessLogo, setBusinessLogo] = useState(null);
  const [companyRegistrationFile, setCompanyRegistrationFile] = useState([]);
  const [ownerName, setOwnerName] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerMobile, setOwnerMobile] = useState("");
  
  let [bannerImage, setBannerImage] = useState({
    image: null,
    file: null,
    isBase64: false,
  });
  let [marketPlaceMerchant, setMarketPlaceMerchant] = useState("no");
  let [deliveryCharge, setDeliveryCharge] = useState(0);
  let [selectedOption, setSelectedOption] = useState([]);

	let [buildingAddressErr, setBuildingAddressErr] = useState("");
	const [bNameErr, setBNameErr] = useState('');
	const [bNameArabicErr, setBNameArabicErr] = useState('');
	const [businessLogoErr, setBusinessLogoErr] = useState('');
	const [companyRegistrationErr, setCompanyRegistrationErr] = useState('');

  const handleOptionChange = (event, newValue) => setSelectedOption(newValue);

  const filterOptions = createFilterOptions({stringify: (option) => option.cuisineName})

  const enableResetGoogleAddress = () => {
    try {
      let elements = document.getElementsByClassName("css-tlfecz-indicatorContainer");
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", resetGoogleAddress, true);
      }
    } catch (error) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: 'Something went wrong',
        state: "error",
      }));
    }
  };

  const removeFile = (index) => {
    setCompanyRegistrationFile(companyRegistrationFile.filter((obj) => obj.index !== index));
  };

  const getAllBusinessType = async () => {
    const response = await globalRequest("get", API.BUSINESS_TYPE, {}, {}, true, true);
    setBusinessTypeList(response.data.businesstypes.filter((item) => item?.slug !== "grocery"));
    bType = localData.token.store.businessTypeId
		setBType(bType);
		setOwnerName(localData.token.full_name);
		setOwnerEmail(localData.token.email)
		setOwnerMobile(localData.token.contact_number)
    getCuisine();
  };

  const populateAddress = async (address) => {
    if (address) {
      if (placeAutocompleteOptions === 'google') {
        // ---------- Google Address ---------- //
        googleAddress = address;
        setGoogleAddress(googleAddress);
        enableResetGoogleAddress();
        const geocode = await geocodeByPlaceId(address.value.place_id);
        buildingAddress = geocode[0].formatted_address;
        setBuildingAddress(buildingAddress);
        setLatitude(geocode[0].geometry.location.lat());
        setLongitude(geocode[0].geometry.location.lng());
        setBuildingAddressErr('');
        // ---------- Google Address ---------- //
      } else {
        // ---------- Open Street Map Address ---------- //
        buildingAddress = address.display_name;
        setBuildingAddress(buildingAddress);
        setLatitude(address.lat);
        setLongitude(address.lon);
        setBuildingAddressErr('');
        // ---------- Open Street Map Address ---------- //
      }
    }
  };

	
  const getAdressFromLatLng = async (lat, lng) => {
    const response = await Geocode.fromLatLng(lat, lng);
    const address = response.results[0].formatted_address;
    return address;
  };


  const getAddressFromLatLng = async (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    buildingAddress = await getAdressFromLatLng(lat, lng);
    setBuildingAddress(buildingAddress);
  };

  const getCuisine = () => {

    const url = API.GET_CUISINE + `?businessTypeId=${bType}`;
    globalRequest("GET", url, {}, {}, true, true).then((res) => {
      cuisineList = [];
      res?.data?.data.filter(da => da.cuisines.length > 0).map((item) => {
        const cuisi = item.cuisines.map((cui) => {
          cui.categoryName = item.name;
          cui.cuisineName = cui.cuisines_locales[0].name;
          return cui
        });
        cuisineList.push(...cuisi);
      });
      setCuisineList(cuisineList);
    }).catch((e) => {});
  };

  const handleSubmit = async (e) => {
		e.preventDefault();
		dispatch(changeLoader(true));
		let isValid = true;
    if (bName.trim() === "") {
      setBNameErr(t('signup_business_name'));
      isValid = false;
    }
    if (bNameArabic.trim() === "") {
      setBNameArabicErr(t('signup_business_name'));
      isValid = false;
    }
    if (!buildingAddress) {
      setBuildingAddressErr(t('signup_enterBusinessAddress'));
      isValid = false;
    }
    if (!businessLogo && businessLogo === null) {
      setBusinessLogoErr(t('signup_business_logo'));
      isValid = false;
    }
    if (companyRegistrationFile.length === 0) {
      setCompanyRegistrationErr(t('signup_company_registration'));
      isValid = false;
    }
		
		if (isValid) {
			const formData = new FormData();
			formData.append("business_name", bName);
      formData.append("business_name_arabic", bNameArabic);
      formData.append("businessTypeId", bType);
      formData.append("business_address", buildingAddress);
      formData.append("zone_number", '');
      formData.append("street_number", floorNo);
      formData.append("building_number", buildingNo);
      formData.append("apartment_number", apartmentNo);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("country", country);
			formData.append("business_logo", businessLogo);
			companyRegistrationFile.forEach(element => {
				formData.append("image", element.file);
			});
			formData.append("market_place", marketPlaceMerchant === 'yes' ? 1 : 0);
			formData.append("delivery_charges", deliveryCharge);
			formData.append("cuisineId", selectedOption.map((item) => item.id).join(","));
			const response = await globalRequest("post", API.ADD_STORE, formData, {}, true, true);

      if (response.data.ack === 1) {
        dispatch(changeLoader(false));
        dispatch(changeSnackbar({ 
          isOpen: true,
          state: "success",
          message: response.data.msg
        }));
        dispatch(changePage(PAGES.MANAGE_STORE));
      } else {
        dispatch(changeSnackbar({ 
          isOpen: false,
          state: "error",
          message: response.data.msg
        }));
      }
		}
		dispatch(changeLoader(false));
  };

  useEffect(() => {
    getAllBusinessType();
  }, [storeOption]);

	useEffect(() => {
    setTimeout(() => {
      setShowMaps(true);
    }, 1000);
  }, []);

	useEffect(() => {
    if (businessLogo !== null) {
      readImageFile(businessLogo, "imageFile");
    }
  }, [businessLogo]);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper  max-w-810">
        <form onSubmit={handleSubmit}>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={"20px"}
          >
            <div className="store-detail-head" style={{ marginBottom: "0px" }}>
              <img
                src={arrowLeftBlack} alt=""
                className="rotate180"
                onClick={() => { dispatch(changePage(PAGES.MANAGE_STORE)); }}
              />
              <h3 className="h3" style={{ marginRight: "0px" }}>
                {t("manageStore_addNewStore")}
              </h3>
            </div>
          </Boxed>
          <div className="card-white">
            <div className="business-detail-wrap">
              <h6 className="h6">{t("manageStore_StoreInformation")}</h6>
              <p className="p3 mb-0">
                {t("manageStore_TheBusinessEmailAddressAndPhoneNumberWillBeUsedForLoginPurpose")}
              </p>
              <div className="signup-right max-w-548">
                <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        className={""}
                        label={t("manageStore_storeNameEnglish") + " *"}
                        value={bName}
                        inputProps={{ maxLength: 256 }}
                        onChange={(e) => {
                          setBName(e.target.value);
                          setBNameErr('');
                        }}
                      />
                      <span className="errorspan">{bNameErr}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        className={""}
                        value={bNameArabic}
                        onChange={(e) => {
                          setBNameArabic(e.target.value);
                          setBNameArabicErr('');
                        }}
                        label={t("manageStore_storeNameArebic") + " *"}
                        inputProps={{ maxLength: 256 }}
                      />
                      <span className="errorspan">{bNameArabicErr}</span>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("manageStore_storeType")} *
                        </InputLabel>
                        <Select
                          disabled={true}
                          className={""}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t("manageStore_storeType")}
                          defaultValue=""
                          value={bType}
                          onChange={(e) => {
                            setBType(e.target.value);
                          }}
                        >
                          {businessTypeList.map((obj, index) => {
                            if (obj.name != "Grocery")
                              return (
                                <MenuItem key={index} value={obj.id}>
                                  {obj.business_type_locales[0].name}
                                </MenuItem>
                              );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1 mb-0">{t("manageStore_storeAddress")}</p>
                  </Grid>

									<Grid item xs={12} sm={12} style={{ position: 'relative' }} mb={"25px"}>
                    {placeAutocompleteOptions === 'google'?
                    <GoogleAutocompletePlaces
                      googleAddress={googleAddress}
                      onChange={populateAddress}
                    />: <AutocompleteAddress
                      setAddress={populateAddress}
                    />}
                  </Grid>
                  {showMaps? <Grid item xs={12} sm={12} style={{ position: 'relative' }} mb={"25px"}>
                    <MyMapComponent
                      lat={latitude}
                      lng={longitude}
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${KEYS.GOOLGE_MAP_ADDRESS_KEY}&libraries=places`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `200px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      ChangeAddress={(lat, lng) => getAddressFromLatLng(lat, lng)}
                    />
                  </Grid>: null}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="form-group">
                      <TextField
                        className={""}
                        value={buildingAddress}
                        label={t("manageStore_storeAddress") + " *"}
                        onChange={(e) => {
                          setBuildingAddressErr('');
                        }}
                      />
											<span className="errorspan">{buildingAddressErr}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        disabled={false}
                        className={""}
                        value={apartmentNo}
                        label={t("manageStore_ApartmentNumber")}
                        onChange={(e) => {
                          let m = e.target.value.replace(/\D/g, "");
                          setApartmentNo(m);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        className={""}
                        label={t("manageStore_buildingNumber")}
                        value={buildingNo}
                        onChange={(e) => {
                          let m = e.target.value.replace(/\D/g, "");
                          setBuildingNo(m);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        className={""}
                        value={floorNo}
                        label={t("manageStore_FloorNumber")}
                        onChange={(e) => {
                          let m = e.target.value.replace(/\D/g, "");
                          setFloorNo(m);
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="form-group">
                      <TextField
                        className={""}
                        inputProps={{ readOnly: true }}
                        label={t("manageStore_country") + " *"}
                        value={t("manageStore_countryName")}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1 mb-0">{t("manageStore_storeLogo")}</p>
                  </Grid>
									<Grid item xs={12}>
                    <label className="img-upload-file">
                      <Boxed
                        display={"flex"}
                        alignItems={"center"}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={uploadBlackIcon} className="upload-icon" alt="" />
                        <div className="">
                          <p className="p2">{t('signup_uploadFile')}</p>
                          <p className="p3">{t('signup_fileFormat')}</p>
                        </div>
                      </Boxed>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={(e) => {
                          let files = e.target.files[0];
                          if (files) {
                            let sizeInMB = (files.size / (1024 * 1024)).toFixed(2);
                            if (sizeInMB <= 5) {
                              if (
                                files.type == "image/jpeg" ||
                                files.type == "image/png" ||
                                files.type == "image/jpg"
                              ) {
                                setBusinessLogo(files);
                                setBusinessLogoErr('');
                              } else {
                                dispatch(
                                  changeSnackbar({
                                    isOpen: true,
                                    message: t('signup_accept_file_logo'),
                                    state: "error",
                                  })
                                );
                              }
                            } else {
                              dispatch(
                                changeSnackbar({
                                  isOpen: true,
                                  message: t('signup_mb5_file'),
                                  state: "error",
                                })
                              );
                            }
                          }
                        }}
                      />
                      <span className="errorspan">{businessLogoErr}</span>
                    </label>
                    <Boxed>
                      {businessLogo !== null ? (
                        <div className="uploaded-image-box">
                          <img
                            id={"imageFile"}
                            src=""
                            className="store-logo"
                            alt=""
                          />
                          <img
                            src={cancelRed}
                            onClick={() => setBusinessLogo(null)}
                            className="cancel-red"
                            alt=""
                          />
                        </div>
                      ) : null}
                    </Boxed>
                  </Grid>
                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1">
                      {t("manageStore_companyRegistrationIDDocuments")}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <label className="img-upload-file">
                      <Boxed
                        display={"flex"}
                        alignItems={"center"}
                        style={{ cursor: "pointer" }}
                      >
                        <img src={uploadBlackIcon} className="upload-icon" alt="" />
                        <div className="">
                          <p className="p2">{t('signup_uploadFile')}</p>
                          <p className="p3">{t('signup_Max3Files')}, {t('signup_fileFormatDoc')}</p>
                        </div>
                      </Boxed>
                      <input
                        type="file"
                        accept=".png,.jpeg,.jpg,.png,.doc,.pdf"
                        id="businessCategory"
                        onChange={(e) => {
                          let files = e.target.files[0];
                          if (files) {
                            let sizeInMB = (files.size / (1024 * 1024)).toFixed(2);
                            if (sizeInMB <= 5) {
                              if (
                                files.type == "image/jpeg" ||
                                files.type == "image/png" ||
                                files.type == "image/jpg" ||
                                files.type == "application/pdf" ||
                                files.type == "application/msword"
                              ) {
                                if (companyRegistrationFile.length < 3) {
                                  setCompanyRegistrationFile((companyRegistrationFile) => [
                                    ...companyRegistrationFile,
                                    {
                                      index: parseInt(Math.random() * 1000),
                                      file: e.target.files[0],
                                    },
                                  ]);
                                  setCompanyRegistrationErr('');
                                } else {
                                  dispatch(
                                    changeSnackbar({
                                      isOpen: true,
                                      message: t('signup_cannot_upload_more_than_3'),
                                      state: "error",
                                    })
                                  );
                                }
                              } else {
                                dispatch(
                                  changeSnackbar({
                                    isOpen: true,
                                    message: t('signup_accept_file_doc'),
                                    state: "error",
                                  })
                                );
                              }
                            } else {
                              dispatch(
                                changeSnackbar({
                                  isOpen: true,
                                  message: t('signup_mb5_file'),
                                  state: "error",
                                })
                              );
                            }
                          }
                          document.getElementById("businessCategory").value = "";
                        }}
                      />
                      <span className="errorspan">{companyRegistrationErr}</span>
                    </label>
                    {companyRegistrationFile.map((obj, index) => {
                      return (
                        <div key={index} className="uploaded-text-block">
                          <span className="p2">{obj.file?.name}</span>
                          <img
                            src={closeGray}
                            className="close-icon"
                            onClick={(e) => removeFile(obj.index)}
                            alt=""
                          />
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} mb={"16px"}>
                    <p className="p1">
                      {t("manageStore_GeneralSetting")}
                    </p>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="form-group">
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple={true} value={selectedOption} onChange={handleOptionChange}
                          options={cuisineList} getOptionLabel={(option) => option.cuisineName}
                          groupBy={(option) => option.categoryName} filterOptions={filterOptions}
                          renderInput={(params) => <TextField {...params} label="Select cuisine" variant="outlined" />}
                          disableCloseOnSelect={true}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="card-white">
            <div className="business-detail-wrap">
              <h6 className="h6">{t("manageStore_brandOwnerContactInformation")}</h6>
              <p className="p3">
                {t("manageStore_ThisIsJustForInformationPurpose")}
              </p>
              <div className="signup-right max-w-548">
                <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                  <Grid item xs={12}>
                    <div className="form-group">
                      <TextField
                        value={ownerName}
                        disabled
                        label={t("manageStore_ownerFullName")}
                        inputProps={{ maxLength: 256 }}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        value={ownerMobile}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{COUNTRY_CODE}</InputAdornment>
                          ),
                        }}
                        disabled
                        label={t("manageStore_ownerMobileNumber")}
                      />
                    </div>
                    <div className="form-group">
                      <TextField
                        type="email"
                        value={ownerEmail}
                        disabled
                        label={t("manageStore_ownerOwnerAddress")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "150px" }}
              type="submit"
              className="date-picker-btn"
            >
              {t("manageStore_save")}
            </Button>
          </Boxed>
        </form>
      </div>
    </div>
  );
};

export default MyStoreDetail;
