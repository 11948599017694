/**
 * @fileoverview socket.io client
 * @description socket.io client for real-time communication between client and server
 * @package socket.io-client - https://www.npmjs.com/package/socket.io-client
 * @returns socket - socket.io client instance (conditionally created)
 */

import { io } from "socket.io-client";
import { API_URL } from "./BaseUrl";

console.log("API_URL", API_URL);

let socket = null; // Initialize as null

// Check if the localStorage contains the specified key
if (localStorage.getItem("nc1P98LKd")) {
  socket = io(API_URL, {
    transports: ["websocket"],
    autoConnect: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 10000,
  });
} else {
  console.log("Socket connection not initialized");
}

export default socket;
