import "./MyStores.scss";
import { useEffect, useState } from "react";
import { Grid, MenuItem, Button, Switch, FormControl, Select, InputAdornment, Tooltip } from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useSelector, useDispatch } from "react-redux";
import { API, PAGES } from "../../Enum";
import { useNavigate } from "react-router-dom";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { changeHolidays } from "../../redux/reducers/editStoreData";
import { API_URL } from "../../BaseUrl";
import { useTranslation } from "react-i18next";
import { changeStoreData, changeStoreOption } from "../../redux/reducers/editStoreData";
import { changePage } from "../../redux/reducers/page";
import { currentLocalData, changeLocalData } from "../../redux/reducers/localData";
import { STORAGE } from "../../Enum";
import { getDataByKeyLocale } from "../../Helpers/Helpers";
import calenderIcon from "../../assets/images/structure/calender-red-icon.svg";
import editIcon from "../../assets/images/structure/edit-black.svg";
import addDeleteGetLocalStorage from "../../global-modules/addDeleteGetLocalStorage";
import globalRequest from "../../global-modules/globalRequest";
import OfflineAllStore from "../../dialog/OfflineAllStore";

const MyStores = () => {

  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const userStore = tokenData?.token?.store;
  const [store, setStore] = useState([]);
  const [bulkStatus, setBulkStatus] = useState('online');
  const [openOfflineStore, setOpenOfflineStore] = useState(false);
  const [openOfflineStoreText, setOpenOfflineStoreText] = useState('');
  const [openOfflineAllStore, setOpenOfflineAllStore] = useState(false);
  let [storeStatus, setStoreStatus] = useState('all');  
  let [storeId, setStoreId] = useState(null);
  let [storeStatusOnOf, setStoreStatusOnOf] = useState(null);
  let [bulkEdit, setBulkEdit] = useState(false);
  let [isUntillFurtherNotice, setIsUntillFurtherNotice] = useState(false);
  let [storeOptions, setStoreOptions] = useState([]);


  const handleChangeStoreStatus = (event) => {
    storeStatus = event.target.value;
    setStoreStatus(storeStatus);
    getStoreDetails();
  };
  
  const getStoreDetails = () => {
    let params = {
      params: {
        // limit: 20,
        // page: 1,
        sort_by: "id",
        order_by: "desc",
        search: "",
        filter_by: storeStatus,
      },
    };
    dispatch(changeLoader(true));
    globalRequest("GET", API.GET_ALL_MY_STORES, {}, params, true, true)
      .then((res) => {
        const data = res?.data;
        if (data.ack == 1) {
          setStore(data.stores);
          setBulkStatus(data.bulkonline)
          let isUntillFurtherNotice = false;
          if (data.bulkonline === 'offline') {
            isUntillFurtherNotice = true;
            const storeOnline = data.stores.find((store) => store.online_status === 0 && store.online_status_not_update_by_cron === 0);
            if (storeOnline) {
              isUntillFurtherNotice = false;
            }
          }
          setIsUntillFurtherNotice(isUntillFurtherNotice);
        }
        dispatch(changeLoader(false));
      })
      .catch((e) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  const getStoreOptions = async () => {
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions = []
    if (response.data.ack === 1) {
      storeOptions = [{ label: "All Stores", value: 0, secondLabel: "" }]
      storeOptionArray = response.data.stores.filter(
        (store) => (store.is_approved === null || store.is_approved === true) && store.manage_working_hours.length > 0
      ).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
  };

  useEffect(() => {
    getStoreDetails();
    getStoreOptions();
  }, []);

  const getUpdatedToken = async () => {
    globalRequest("GET", API.REFRESH_TOKEN, {}, {}, true, true).then((res) => {
      if (res?.data?.ack === 1) {
        addDeleteGetLocalStorage(STORAGE.TOKEN, res?.data?.data, "add", "single");
        dispatch(changeLocalData());
      } else {
        navigate("/");
      }
    }).catch((err) => {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t("somethingWentWrong"),
        })
      );
    });
  }

  useEffect(() => {
    getUpdatedToken();
  }, []);

  
  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper">
        <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={"20px"}
        >
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}>
              {t('manageStore_manageStores')}
              {isUntillFurtherNotice?<Tooltip className="mx-2" title={openOfflineStoreText} placement="bottom" >
                <img src={calenderIcon} alt="calender "/>
              </Tooltip>: null}
            </h3>
          </div>

          {tokenData?.token?.role === 'vendor'?<>
            <FormControl className="store-status" sx={{ margin: "0px 4px 0px auto", width: 165 }}>
              <Select
                size="small"
                value={storeStatus}
                onChange={handleChangeStoreStatus}
                className="bg-white"
                displayEmpty
                inputProps={{"aria-label": "Without label",}}
                MenuProps={{ disableScrollLock: true }}
                startAdornment={<InputAdornment position="start">{t('manageStore_status')}:</InputAdornment>}
              >
                <MenuItem value={"all"} > {t('manageStore_All')} </MenuItem>
                <MenuItem value={"approved"} > {t('manageStore_Approved')} </MenuItem>
                <MenuItem value={"rejected"} >{t('manageStore_Rejected')}</MenuItem>
                <MenuItem value={"pending_approval"} >{t('manageStore_PendingApproval')}</MenuItem>

                <MenuItem value={"online"} >{t('manageStore_online')}</MenuItem>
                <MenuItem value={"offline"} >{t('manageStore_offline')}</MenuItem>

                {/* <MenuItem value={"active"} >{t('manageStore_Active')}</MenuItem>
                <MenuItem value={"inactive"} >{t('manageStore_Inactive')}</MenuItem> */}
              </Select>
            </FormControl>
            {userStore.id !== 1?<Button
              variant="contained"
              size="small"
              sx={{ width: "150px" }}
              className="date-picker-btn"
              onClick={() => {
                dispatch(changePage(PAGES.ADD_STORE));
                dispatch(changeStoreOption(null));
                dispatch(changeHolidays(null));
              }}
            >
              {t('manageStore_addNewStore')}
            </Button>:null}
          </>: null}
        </Boxed>

        {tokenData?.token?.role === 'vendor'?<Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"start"}
          mb={"20px"}
          className="table-switch-box"
        >
          <Switch 
            checked={bulkStatus === 'online'}
            onClick={() => {
              setBulkEdit(!bulkEdit);
              setOpenOfflineAllStore(true);
              setStoreStatusOnOf(bulkStatus === 'online'? 'offline' : 'online');
            }}
          />
          {bulkStatus === 'online'?
          <div className="relative mx-3">
            <h6 className="h6">{t('manageStore_MakeAllStoresOffline')}</h6>
            <span className="p3">{t('manageStore_MakeAllStoresOfflineText')}</span>
          </div>:
          <div className="relative mx-3">
            <h6 className="h6">{t('manageStore_MakeAllStoresOnline')}</h6>
            <span className="p3">{t('manageStore_MakeAllStoresOnlineText')}</span>
          </div>}
        </Boxed>: null}
        {store.map((store, index) => {
          return (
            <Grid container columnSpacing={4} key={index}>
              <Grid item xs={12}>
                <div
                  className={
                    store.status == "inactive"
                      ? "my-stores-wrap row-grayOut"
                      : "my-stores-wrap"
                  }
                >
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    className="first-div-logo pointer"
                    onClick={() => {
                      dispatch(changePage(PAGES.MANAGE_STORE_DETAIL));
                      dispatch(changeStoreOption("edit"));
                      dispatch(changeStoreData(store.id));
                    }}
                  >
                    <div className="img-block">
                      <img
                        src={`${API_URL}/${store.business_logo}`}
                        className="logo-icon"
                        alt=""
                      />
                    </div>
                    <div className="content-block">
                      <p className="p1">{store?.stores_locales[0]?.business_name}</p>
                    </div>
                  </Boxed>
                  <p className="address-block pointer"  onClick={() => {
                    dispatch(changeStoreOption("edit"));
                    dispatch(changeStoreData(store.id));
                    dispatch(changePage(PAGES.MANAGE_STORE_DETAIL));
                  }}>{store?.business_address}</p>
                  <p className="p2 gray-chips pointer" onClick={() => {
                    dispatch(changeStoreOption("edit"));
                    dispatch(changeStoreData(store.id));
                    dispatch(changePage(PAGES.MANAGE_STORE_DETAIL));
                  }} >
                    {store.is_approved === null
                      ? "Pending.."
                      : store.is_approved === false
                        ? "Rejected"
                        : "Approved"}
                  </p>

                  <Tooltip className="mx-4" title="Unavailable until further notice" placement="bottom" 
                    style={
                      store.online_status === 0 && store.online_status_not_update_by_cron === 1?null:
                      {visibility: 'hidden'}
                    }
                  >
                    <img src={calenderIcon} alt="calender " className=""  />
                  </Tooltip>

                  <Switch
                    checked={store.online_status === 1}
                    // disabled={store.manage_working_hours.length === 0}
                    className="ml-auto" onClick={(e) => {
                      if (store.manage_working_hours.length === 0) {
                        dispatch(
                          changeSnackbar({
                            isOpen: true,
                            state: "error",
                            message: t('manageStore_workingHoursNotSet'),
                          })
                        );
                        return;
                      }
                      e.stopPropagation();
                      storeId = store.id;
                      setStoreId(storeId);
                      storeStatusOnOf = store.online_status === 1 ? 'offline' : 'online';
                      setStoreStatusOnOf(storeStatusOnOf);
                      bulkEdit = false;
                      setBulkEdit(bulkEdit);
                      setOpenOfflineStore(true);
                    }} />
                  {/* <BasicMenu store={store} /> */}
                  <img src={editIcon} alt="edit image " className="pointer" onClick={() => {
                    dispatch(changePage(PAGES.MANAGE_STORE_DETAIL));
                    dispatch(changeStoreOption("edit"));
                    dispatch(changeStoreData(store.id));
                  }} />
                </div>
              </Grid>
            </Grid>
          );
        })}
      </div>
      {openOfflineStore?
      <OfflineAllStore 
        isOpen={true}
        storeId={storeId}
        storeStatusOnOf={storeStatusOnOf}
        stores={store.map((str) => {
          return {
            label: getDataByKeyLocale(str.stores_locales, 'business_name'),
            value: str.id,
            secondLabel: ''
          }
        })}
        bulkEdit={bulkEdit}
        handleClose={() => {
          setOpenOfflineStore(false);
        }}
        handleGetStore={() => {
          setOpenOfflineStore(false);
          getStoreDetails();
        }}
      />: null}

      {openOfflineAllStore?
      <OfflineAllStore 
        isOpen={true}
        storeStatusOnOf={storeStatusOnOf}
        // storeId={store.map((str) => str.id)}
        storeId={storeOptions.map((str) => str.value)}
        bulkEdit={true}
        stores={storeOptions}
        handleClose={() => {
          setOpenOfflineAllStore(false);
        }}
        handleGetStore={() => {
          setOpenOfflineAllStore(false);
          getStoreDetails();
        }}
      />: null}

    </div>
  );
};

export default MyStores;
