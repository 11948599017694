import "./ManageCategories.scss";
import React,{ useEffect, useState, useRef } from "react";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import {
  Box, Grid, Switch, Tooltip, Typography, Pagination, FormControl,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import editIcon from "../../assets/images/structure/edit-icon-red.svg";
import timeIcon from "../../assets/images/structure/time-red.svg";
import uploadBlackIcon from "../../assets/images/structure/upload-black-icon.svg";
import { TreeItem, TreeView } from "@material-ui/lab";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import SearchInput from "../../shared/SearchInput";
import AddEditTimeSlots from "../../dialog/AddEditTimeSlots";
import CategoryImageModal from "../../dialog/CategoryImageModal";
import SubCategoryComponent from "./SubCategoryComponent";
import globalRequest from "../../global-modules/globalRequest";
import { API, PAGES, IMAGE_URL } from "../../Enum";
import { useSelector, useDispatch } from "react-redux";
import { currentLocalData } from "../../redux/reducers/localData";
import { changeLoader } from "../../redux/reducers/loader";
import { formatPrice } from "../../Helpers/Helpers";
import { changeMainProductData } from "../../redux/reducers/mainEditProductData";
import { changePage } from "../../redux/reducers/page";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import { convertTimeTo12Hour, getFrontImage, getDataByKeyLocale, getdataByKey } from "../../Helpers/Helpers";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";

const ManageCategories = () => {
  const dispatch = useDispatch();
  const tokenData = useSelector(currentLocalData);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const limit = 10;
  const { t } = useTranslation();
  const [openAddEditTimeSlots, setOpenAddEditTimeSlots] = useState(false);
  const [openCategoryImageModal, setOpenCategoryImageModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  let [page, setPage] = useState(1);
  let [selectedId, setSelectedId] = useState(0);
  let [selectedName, setSelectedName] = useState("");
  let [timeSlots, setTimeSlots] = useState([]);
  let [sort, setSort] = useState("id");
  let [order, setOrder] = useState("desc");
  let [products, setProducts] = useState([]);
  const [categoryStore, setCategoryStore] = React.useState("All");
  const categoryStoreChange = (event) => {
    setCategoryStore(event.target.value);
  };
  let [search, setSearch] = useState("");
  let [uploadImageData, setUploadImageData] = useState({
    id: 0,
    name: "",
    image: "",
  });
  let [storeId, setStoreId] = useState(null);
  let [selectedStore, setSelectedStore] = useState(null);
  let [isParent, setIsParent] = useState(true);
  let [totalOrderCount, setTotalOrderCount] = useState(0);
  let [storeOptions, setStoreOptions] = useState([]);

  const openUploadImage = (id, name, image) => {
    uploadImageData = { id: id, name: name, image: image };
    setUploadImageData(uploadImageData);
    setOpenCategoryImageModal(true);
  };

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    if (response.data.ack === 1) {
      storeOptionArray = response.data.stores
        .filter((store) => store.is_approved === null || store.is_approved === true).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    }
    storeOptions = storeOptionArray;
    setStoreOptions(storeOptions);
    selectedStore = storeOptions[0];
    if (tokenData.token.role === 'vendor_users') {
      selectedStore = storeOptions.find((store) => store.value === tokenData.token.store.id);
    }
    setSelectedStore(selectedStore);
    dispatch(changeLoader(false));
    getAllCategories();
  };

  const getAllCategories = async () => {
    const response = await globalRequest(
      "get",
      API.CATEGORY_MANAGEMENT.GET_ALL_CATEGORY(selectedStore?.value),
      {},
      {},
      true,
      true
    );
    setCategoryList(response?.data?.results);
    if (selectedId === 0) {
      selectedId = response?.data.results[0]?.id;
      setSelectedId(selectedId);
      selectedName = response?.data.results[0]?.name;
      setSelectedName(selectedName);
      if (response?.data?.results.length > 0) {
        timeSlots =
          response?.data?.results[0]?.timejson !== ""
            ? JSON.parse(response?.data?.results[0]?.timejson)
            : [];
      } else {
        timeSlots = [];
      }
      setTimeSlots(timeSlots);
    } else {
      let category = response?.data.results.find(
        (item) => item.id === selectedId
      );
      if (category) {
        timeSlots =
          category.timejson !== "" ? JSON.parse(category.timejson) : [];
        setTimeSlots(timeSlots);
      }
    }
  };

  const handleCategoryStatusChange = async (event, category) => {
    const status = event.target.checked ? "active" : "inactive";
    const data = {
      storeId: selectedStore?.value,
      categoryId: category.id,
      status: status,
    };
    const response = await globalRequest(
      "post",
      API.CATEGORY_MANAGEMENT.UPDATE_CATEGORY_HOURS,
      data,
      {},
      true,
      true
    );
    if (response.data.ack === 1) {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "success",
          isOpen: true,
        })
      );
      getAllCategories();
      getProducts(category.id);
    } else {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "error",
          isOpen: true,
        })
      );
    }
    getAllCategories();
  };

  const getProducts = async (id, pagen = "") => {
    if (id && categoryList.length > 0) {
      dispatch(changeLoader(true));
      let url = `${
        API.CATEGORY_MANAGEMENT.GET_PRODUCTS
      }?category_id=${id}&page=${
        pagen ? 1 : page
      }&limit=${limit}&storeId=${selectedStore?.value}`;
      url += `&order_by=${order}&sort_by=${sort}`;
      url += `&search=${search}`;
      const response = await globalRequest("get", url, {}, {}, true, true);
      totalOrderCount = response?.data?.count;
      setTotalOrderCount(totalOrderCount);
      setProducts(
        response.data.products.map((product) => {
          const isInactive = product.manage_store_products.filter((msp) => msp.storeId== selectedStore?.value);
          product.status = isInactive.length > 0?'inactive':'active';
          return product
        })
      );
      dispatch(changeLoader(false));
    } else {
      setProducts([]);
    }
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = async (e, index) => {
    const copyListItems = [...categoryList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setCategoryList(copyListItems);
    const newSequence = copyListItems.map((item, index) => {
      return {
        catStoreId: item.catStoreId,
        sequence: index + 1,
      };
    });
    await globalRequest(
      "post",
      API.CATEGORY_MANAGEMENT.CHANGE_SEQUENCE,
      newSequence,
      {},
      true,
      true
    );
  };

  const handleProductChange = async (event, id) => {
    const status = event.target.checked ? "active" : "inactive";
    // API.MANAGE_PRODUCTS.CHANGE_PRODUCT_STATUS
    const data = {
      status: status,
      productId: id.toString(),
      storeId: selectedStore?.value.toString(),
    };
    const response = await globalRequest("post", API.MANAGE_PRODUCTS.CHANGE_PRODUCT_STATUS,data, {}, true, true);
    if (response.data.ack === 1) {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "success",
          isOpen: true,
        })
      );
    } else {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "error",
          isOpen: true,
        })
      );
    }
    getProducts(selectedId);
  };

  const handleStoreSync = async () => {
    const response = await globalRequest(
      "get",
      API.CATEGORY_MANAGEMENT.SYNC_CATEGORY(selectedStore?.value),
      {},
      {},
      true,
      true
    );
    if (response.data.ack === 1) {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "success",
          isOpen: true,
        })
      );
    } else {
      dispatch(
        changeSnackbar({
          message: response.data.msg,
          state: "error",
          isOpen: true,
        })
      );
    }
  };

  useEffect(() => {
    // getAllCategories();
    getStoreOptions();
    // getWorkingHours();
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      getProducts(selectedId);
    }, 500);
    return () => clearTimeout(getData);
  }, [search]);

  useEffect(() => {
    setPage(1);
    setTimeout(() => {
      getProducts(selectedId, 1);
    }, 100);
  }, [selectedId]);

  return (
    <>
      <div className="main-mid-wrapper">
        <div className="manage-category-wrapper">
          <Boxed className="top-flex-row" mb={"20px"}>
            <div
              className="store-detail-head"
              display={"flex"}
              alignItems={"center"}
              style={{
                marginBottom: "0px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <h3 className="h3"> {t("manage_ManageCategories")} </h3>
              <Boxed style={{display: 'flex'}}>
              {tokenData.token.role === 'vendor'?<FormControl sx={{ margin: "0px 16px", width: '231px', backgroundColor: '#ffffff' }}>
              <SearchSelectSingleStore
                options={storeOptions}
                value={selectedStore}
                size={'size'}
                handleOptionsChange={(event, newValues) => {
                  selectedId = 0;
                  setSelectedId(selectedId);
                  selectedStore = newValues;
                  setSelectedStore(selectedStore);
                  getAllCategories();
                }}
              />
              </FormControl>: null}
                {/* <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={handleStoreSync}
                >
                  {" "}
                  Sync Categories{" "}
                </Button> */}
              </Boxed>
            </div>
          </Boxed>
          <Grid container columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <div className="left-side left-side-new">
                <Boxed
                  style={{
                    background: "#fff",
                    borderRadius: "10px",
                    border: "1px solid #e8e8e8 ",
                  }}
                >
                  <Boxed
                    padding={"16px 16px"}
                    width={"100%"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    style={{ borderBottom: "1px solid #e8e8e8" }}
                  >
                    <h3 className="h3 mb-0"> Categories </h3>
                  </Boxed>
                  {categoryList?.length > 0 ? (
                    categoryList.map((category, index) =>
                      category.subCategory.length > 0 ? (
                        <TreeView
                          aria-label="file system navigator"
                          key={`category-${index}`}
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={(e) => drop(e, index)}
                          draggable
                        >
                          <TreeItem
                            nodeId={`category${category.id}`}
                            className="main-tree"
                            label={
                              <Boxed
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"flex-start"}
                                onClick={() => {
                                  selectedId = category.id;
                                  setSelectedId(selectedId);
                                  selectedName = category.name;
                                  setSelectedName(selectedName);
                                  timeSlots =
                                    category.timejson !== ""
                                      ? JSON.parse(category.timejson)
                                      : [];
                                  setTimeSlots(timeSlots);
                                  setIsParent(true);
                                }}
                              >
                                <DragIndicatorIcon sx={{ color: "#a7a7a7" }} />
                                <Typography
                                  variant="p"
                                  component="p"
                                  width={"100%"}
                                >
                                  {category.name}
                                </Typography>
                                <Box display={"flex"} alignItems={"center"}>
                                  <img
                                    src={category.image ? IMAGE_URL(category.image): uploadBlackIcon}
                                    onError={(e) => { e.target.onerror = null; e.target.src = uploadBlackIcon; }}
                                    width={"20px"}
                                    alt=""
                                    style={{ marginRight: "10px" }}
                                    onClick={() => {
                                      openUploadImage(
                                        category.id,
                                        category.name,
                                        category.image
                                      );
                                    }}
                                  />
                                  <Switch
                                    className="mx-1"
                                    checked={
                                      category.status === "active"
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      handleCategoryStatusChange(e, category);
                                    }}
                                  />
                                </Box>
                              </Boxed>
                            }
                          >
                            <SubCategoryComponent
                              subCategory={category.subCategory}
                              handleSelectedId={(selId, selName, selTime) => {
                                selectedId = selId;
                                setSelectedId(selectedId);
                                selectedName = selName;
                                setSelectedName(selectedName);
                                setIsParent(false);
                              }}
                              openUploadImage={openUploadImage}
                              storeId={selectedStore?.value}
                              handleCategoryget={getAllCategories}
                              handleProductget={getProducts}
                            />
                          </TreeItem>
                        </TreeView>
                      ) : (
                        <Boxed
                          key={`category-${index}`}
                          className="category-boxedinner"
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-start"}
                          style={{ cursor: "pointer" }}
                          onDragStart={(e) => dragStart(e, index)}
                          onDragEnter={(e) => dragEnter(e, index)}
                          onDragEnd={(e) => drop(e, index)}
                          draggable
                        >
                          <DragIndicatorIcon sx={{ color: "#a7a7a7" }} />
                          <Typography
                            variant="p"
                            component="p"
                            width={"100%"}
                            onClick={() => {
                              selectedId = category.id;
                              setSelectedId(selectedId);
                              selectedName = category.name;
                              setSelectedName(selectedName);
                              timeSlots =
                                category.timejson !== ""
                                  ? JSON.parse(category.timejson)
                                  : [];
                              setTimeSlots(timeSlots);
                              setIsParent(true);
                            }}
                          >
                            {category.name}
                          </Typography>
                          <Box display={"flex"} alignItems={"center"}>
                            <img
                              src={category.image ? IMAGE_URL(category.image): uploadBlackIcon}
                              onError={(e) => { e.target.onerror = null; e.target.src = uploadBlackIcon; }}
                              width={"20px"}
                              alt=""
                              style={{ marginRight: "10px" }}
                              onClick={() => {
                                openUploadImage(
                                  category.id,
                                  category.name,
                                  category.image
                                );
                              }}
                            />
                            <Switch
                              className="mx-1"
                              checked={
                                category.status === "active" ? true : false
                              }
                              onChange={(e) => {
                                handleCategoryStatusChange(e, category);
                              }}
                            />
                          </Box>
                        </Boxed>
                      )
                    )
                  ) : (
                    <NoDataFound text="No categories available" />
                  )}
                </Boxed>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <div className="top-row-head">
                <h3 className="h3 d-flex items-center justify-start">
                  {selectedName}
                  {selectedName && isParent ? (
                    <Tooltip
                      title="Add the category visibility timeslots"
                      placement="right"
                    >
                      <img
                        src={editIcon}
                        className="icon16 mx-2"
                        alt="..."
                        onClick={() => setOpenAddEditTimeSlots(true)}
                      />
                    </Tooltip>
                  ) : null}
                </h3>
                {selectedName ? (
                  <div className="relative d-flex">
                    <div className="time-tool-box d-flex mx-2">
                      <img src={timeIcon} alt="..." className="icon16" />
                      {timeSlots?.length > 0 ? (
                        <>
                          <p className="p4">
                            {convertTimeTo12Hour(timeSlots[0].starttime)} to{" "}
                            {convertTimeTo12Hour(timeSlots[0].endtime)}{" "}
                          </p>
                          {timeSlots.length > 1 ? (
                            <Tooltip
                              title={
                                <>
                                  {timeSlots?.map((ti, index) => {
                                    if (index === 0) return null;
                                    return (
                                      <p className="p4" key={`time-${index}`}>
                                        {convertTimeTo12Hour(ti.starttime)} to{" "}
                                        {convertTimeTo12Hour(ti.endtime)}{" "}
                                      </p>
                                    );
                                  })}
                                </>
                              }
                              placement="bottom"
                              arrow
                            >
                              {timeSlots.length > 1 ? (
                                <p className="p4 mx-2">
                                  {" "}
                                  | + {timeSlots.length - 1} more
                                </p>
                              ) : null}
                            </Tooltip>
                          ) : null}
                        </>
                      ) : (
                        "No time slots"
                      )}
                    </div>
                    {/* <div className="d-flex">
                    <img src={deleteIcon} alt="..." className="icon16" />
                    <p className="p4 red sign-up">Delete</p>
                  </div> */}
                  </div>
                ) : null}
              </div>
              <SearchInput
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
                valueSearch={search}
              />
              {products.length > 0 ? (
                <div className="table-block">
                  <div className="table-wrapper">
                  <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 265px)" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("manageProducts_ItemCode")}</TableCell>
                          <TableCell>{t("manageProducts_product")}</TableCell>
                          <TableCell>{t("manageProducts_BaseUOM")}</TableCell>
                          <TableCell>{t("manageProducts_sellingPrice")}</TableCell>
                          <TableCell>{t("manageProducts_Status")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products.map((product, index) => {
                          let defaultUom = product.uoms?.find((opm) => opm.isDefault);
                          if (defaultUom === undefined) {defaultUom = product.uoms?product.uoms[0]:null;}
                          return (
                          <TableRow
                            className={`pointer`}
                            key={`index-${index}`}
                            sx={{"&:last-child td, &:last-child th": { border: 0 }}}
                          >
                            <TableCell
                            onClick={() => {
                              sessionStorage.setItem("returnto", 'categories');
                              dispatch(changeMainProductData(product));
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            }}
                            >#{product?.itemCode}</TableCell>
                            <TableCell
                            onClick={() => {
                              sessionStorage.setItem("returnto", 'categories');
                              dispatch(changeMainProductData(product));
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            }}
                            >{getdataByKey(product?.products_locales, 'title', "en")}</TableCell>
                            <TableCell
                            onClick={() => {
                              sessionStorage.setItem("returnto", 'categories');
                              dispatch(changeMainProductData(product));
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            }}
                            >{defaultUom?.uomName}</TableCell>
                            <TableCell
                            onClick={() => {
                              sessionStorage.setItem("returnto", 'categories');
                              dispatch(changeMainProductData(product));
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            }}
                            >{t("manageProducts_QAR")}{" "}
                            {defaultUom?.salePrice &&
                              defaultUom?.salePrice !== 0 &&
                              defaultUom?.salePrice !== '' &&
                              defaultUom?.salePrice !== '0'
                            ? formatPrice(defaultUom?.salePrice) : formatPrice(defaultUom?.price)}</TableCell>
                            <TableCell>
                            <Switch
                              checked={product?.status === "active" ? true : false}
                              onChange={(e) => handleProductChange(e, product?.id)}
                            />
                            </TableCell>
                            <TableCell
                            onClick={() => {
                              sessionStorage.setItem("returnto", 'categories');
                              dispatch(changeMainProductData(product));
                              dispatch(changePage(PAGES.ADD_PRODUCT));
                            }}
                            >
                              <Box display={"flex"} alignItems={"center"}>
                                <img
                                  src={editIcon}
                                  width={"20px"}
                                  alt=""
                                  style={{ marginRight: "10px" }}
                                />
                                </Box>
                            </TableCell>
                          </TableRow>
                          );
                        }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  </div>
                </div>
              ) : (
                <NoDataFound text="No products available" />
              )}
              <div className="pagination-wrap">
                {Math.ceil(totalOrderCount / limit) > 1 ? (
                  <Pagination
                    count={Math.ceil(totalOrderCount / limit)}
                    page={page}
                    onChange={(e, val) => {
                      page = val;
                      setPage(page);
                      getProducts(selectedId);
                    }}
                  />
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <AddEditTimeSlots
        isOpen={openAddEditTimeSlots}
        timeSlots={timeSlots}
        selectedId={selectedId}
        selectedName={selectedName}
        storeId={selectedStore?.value}
        handleClose={() => {
          setOpenAddEditTimeSlots(false);
        }}
        onSuccess={() => {
          setOpenAddEditTimeSlots(false);
          getAllCategories();
        }}
      />
      <CategoryImageModal
        isOpen={openCategoryImageModal}
        handleClose={() => {
          setOpenCategoryImageModal(false);
        }}
        handleSuccess={() => {
          setOpenCategoryImageModal(false);
          getAllCategories();
        }}
        storeId={selectedStore?.value}
        uploadImageData={uploadImageData}
      />
    </>
  );
};

export default ManageCategories;
