import { Button, Dialog, DialogContent, Grid, InputAdornment, TextField } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import globalRequest from "../global-modules/globalRequest";
import { API, COUNTRY_CODE, KEYS, ROLE, STORAGE } from "../Enum";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../redux/reducers/snackbar";
import { changeLoader } from "../redux/reducers/loader";
import { useEffect } from "react";
import validEmail from "../global-modules/validEmail";
import addDeleteGetLocalStorage from "../global-modules/addDeleteGetLocalStorage";
import { useTranslation } from "react-i18next";
import {PhoneMask} from "../global-modules/MaskedInputs";

const Recaptcha = require("react-recaptcha");

const ForgetPassword = ({ isOpen = false, handleClose, openForgetOtp, emailCallback }) => {

  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(true);
  const dispatch = useDispatch();
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  const [captchaErr, setCaptchaErr] = useState(true);
  const [isPhone, setPhone] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const email = addDeleteGetLocalStorage(STORAGE.EMAIL, {}, "get", "single");
      if (email) {
        setEmail(email);
      }
    } else {
      setEmail("");
      setIsCaptchaDone(false);
    }
  }, [isOpen]);

  const handleSubmit = (e) => {
    let isValid = true;

    if (!email) {
      setEmailErr(false);
    }

    if (!isCaptchaDone) {
      setCaptchaErr(false);
    }

    if (isNaN(email)) {
      isValid = validEmail(email);
    } else {
      if (email.length !== 8) {
        isValid = false;
      }
    }
    if (isCaptchaDone && email && isValid) {
      dispatch(changeLoader(true));
      globalRequest(
        "post",
        API.FORGET_PASSWORD_OTP_SEND,
        {
          uname: email,
          role: ROLE,
        },
        {},
        false
      )
        .then((res) => {
          let data = res?.data;
          if (data?.ack == 1) {
            openForgetOtp();
            emailCallback(email);
            addDeleteGetLocalStorage(STORAGE.EMAIL, "", "add", "single");
          }
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: data?.ack == 1 ? "success" : "error",
              message: data?.msg ? data?.msg : data?.errMsg[0]?.uname,
            })
          );
          dispatch(changeLoader(false));
        })
        .catch((e) => {
          dispatch(changeLoader(false));
        });
    }
  };

  const captchCallback = (response) => {
    if (response) {
      setIsCaptchaDone(true);
    }
  };

  const checkChangeIdentity = async (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 3 && !isNaN(e.target.value)) {
      setPhone(true);
      document.getElementById("identity").focus();
    } else {
      setPhone(false);
      setTimeout(() => {
        document.getElementById("identity").focus();
      }, 100);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h2 className="h3 text-center">{t('forgot_forgotPassword')}</h2>
        <p className="p3 text-center">
          {t('forgot_enterYourRegisteredEmailOrMobileWellSendYouResetPasswordOTP')}
        </p>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className="form-group">
              <TextField
                value={email}
                id="identity"
                onChange={(e) => checkChangeIdentity(e)}
                required={true}
                label={t('forgot_emailOrMobileNo')}
                InputProps={ isPhone? {
                  startAdornment: (<InputAdornment position="start">{COUNTRY_CODE}</InputAdornment>),
                  inputComponent: PhoneMask,
                }
              : null}
              />
              {!emailErr && !email ? <span className="errorspan">{t('forgot_mobile_email')} </span>
                : isNaN(email) ? ( validEmail(email) ? null : <span className="errorspan">{t('forgot_valid_email')} </span>
              ) : email.length !== 8 && email ? <span className="errorspan">{t('forgot_mobile_no_8_digit')} </span>: null}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <Recaptcha
                elementID="forget-recaptch"
                sitekey={KEYS.GOOGLE_RECAPTCHA_SITE_KEY}
                verifyCallback={captchCallback}
              />
              {!captchaErr && !isCaptchaDone ? (
                <span className="errorspan">{t('forgot_verify_captcha')}</span>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              onClick={handleSubmit}
              variant="contained"
              size="large"
              sx={{ width: 1, marginTop: "16px" }}
            >
              {t('forgot_send')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ForgetPassword;
