import axios from "axios";
import { API, STORAGE } from "../Enum";
import addDeleteGetLocalStorage from "./addDeleteGetLocalStorage";
import  socket from "../socket";
const sessionLogout = () => {
  const lang = localStorage.getItem('i18nextLng')
  localStorage.clear();
  sessionStorage.clear();
  localStorage.setItem('i18nextLng', lang)
  window.location.href = '/';
  console.log("socket disconnected.");
  socket.disconnect();
};

const globalRequest = (method = "get", url, data = {}, options = {}, token = false, isHeader = true) => {
  // global axios request for post get put and delete

  let headers = {};

  let d = {
    method: method,
    url: url,
    ...options,
  };

  let tokenData = {};

  if (token) {
    let token = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
    tokenData = { authorization: `Bearer ${token}` };
  }

  if (isHeader) {
    headers = {
      "x-api-key": API.X_API_KEY,
      "lang": localStorage.getItem("i18nextLng") || "en",
    };
  }

  d.headers = { ...headers, ...tokenData };

  if (data) {
    d.data = data;
  }

  return new Promise((resolve, reject) => {
    axios(d).then((response) => {
      resolve(response);
    }).catch((err) => {
      if (err?.response?.status === 401) {
        sessionLogout();
      }
      reject(err);
    });
  });
};

export default globalRequest;
