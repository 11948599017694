import { useEffect, useState } from "react";
import "./../addProduct.scss";
import { Dropdown } from "primereact/dropdown";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const Category = ({
	categoryLevel,
	canEdit,
	categoryData,
	updateParent,
	updateSubCategory,
	updateSubSubCategory,
	isEdit,
	item,
  categoryArray,
  categoryIndex
}) => {

  const { t } = useTranslation();
  const [selectedCategoryArray, setSelectedCategoryArray] = useState([])
	let [selectedCategory, setSelectedCategory] = useState(null);
  let [subCategoryDatas, setSubCategoryDatas] = useState([]);
  let [selectedSubCategory, setSelectedSubCategory] = useState(null);
  let [subSubCategoryDatas, setSubSubCategoryDatas] = useState([]);
  let [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);

	const handleCategoryChange = (e) => {
		selectedCategory = e.target.value;
		setSelectedCategory(selectedCategory);
		setSelectedSubCategory(null);
		setSelectedSubSubCategory(null);
		subCategoryDatas = selectedCategory?.subCategory;
		setSubCategoryDatas(subCategoryDatas);
		updateParent(selectedCategory?.id)
		updateSubCategory("")
		updateSubSubCategory("")
	};

	const handleSubCategoryChange = (e) => {
		selectedSubCategory = e.target.value;
		setSelectedSubCategory(selectedSubCategory);
		setSelectedSubSubCategory(null);
		subSubCategoryDatas = selectedSubCategory.chiledCategory;
		setSubSubCategoryDatas(subSubCategoryDatas);
		updateSubCategory(selectedSubCategory?.id)
		updateSubSubCategory("")
	};

	const handleSubSubCategoryChange = (e) => {
		selectedSubSubCategory = e.target.value;
		setSelectedSubSubCategory(selectedSubSubCategory);
		updateSubSubCategory(selectedSubSubCategory?.id)
	};

	const CategoryOptionTemplate = (option) => {
    return (
      <div className="Category-item">
        <div>{option.name}</div>
      </div>
    );
  };

	useEffect(() => {
		if (isEdit) {
			if (item) {
				selectedCategory = categoryData?.find((x) => x.id == item?.parent);
				setSelectedCategory(selectedCategory);
				subCategoryDatas = selectedCategory?.subCategory;
				setSubCategoryDatas(subCategoryDatas);
				selectedSubCategory = subCategoryDatas?.find((x) => x.id == item?.subCategory);
				setSelectedSubCategory(selectedSubCategory);
				subSubCategoryDatas = selectedSubCategory?.chiledCategory;
				setSubSubCategoryDatas(subSubCategoryDatas);
				selectedSubSubCategory = subSubCategoryDatas?.find((x) => x.id == item?.subSubCategory);
				setSelectedSubSubCategory(selectedSubSubCategory);
			}
		}
	}, [isEdit]);

  useEffect(() => {
    const selected = categoryArray.filter((item, index) => index != categoryIndex)
    switch (categoryLevel) {
      case 1:
        setSelectedCategoryArray(selected.map(item => item.parent))
        break;
      case 2:
        setSelectedCategoryArray(selected.map(item => item.subCategory))
        break;
      case 3:
        setSelectedCategoryArray(selected.map(item => item.subSubCategory))
        break;
      default:
        break;
    } 
  }, [categoryArray]);

  return (
	<Grid
    container
    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}
    xs={12} sm={12} md={12} lg={12}
  >
		<Grid
      item={true} xs={12} sm={6}
      md={categoryLevel == 1 ? 12 : categoryLevel == 2 ? 6 : 4}
      lg={categoryLevel == 1 ? 12 : categoryLevel == 2 ? 6 : 4}
    >
      <div className={`form-group ${canEdit ? "" : "no-event"}`}>
			<span className="form-group p-float-label">
          <Dropdown
            value={selectedCategory}
            options={categoryData?.filter(item => !selectedCategoryArray.includes(item.id))}
            onChange={handleCategoryChange}
            optionLabel="name"
            placeholder="Select a Category"
            itemTemplate={CategoryOptionTemplate}
            filter
          />
          <label htmlFor="dropdown">{t("manageProducts_Category")} <span className="required-star">*</span></label>
        </span>
      </div>
    </Grid>

    {categoryLevel == 2 || categoryLevel == 3 ?
    <Grid
      item={true} xs={12} sm={6}
      md={categoryLevel == 2 ? 6 : 4}
      lg={categoryLevel == 2 ? 6 : 4}
    >
      <div className={`form-group ${canEdit ? "" : "no-event"}`}>
				<span className="form-group p-float-label">
          <Dropdown
            value={selectedSubCategory}
            options={subCategoryDatas?.filter(item => !selectedCategoryArray.includes(item.id))}
            onChange={handleSubCategoryChange}
            optionLabel="name"
            placeholder="Select a Sub Category"
            disabled={categoryLevel >= 2 ? false : true}
            itemTemplate={CategoryOptionTemplate}
            filter
          />
          <label htmlFor="dropdown">
            {t("manageProducts_SubCategory")} <span className="required-star">*</span>
          </label>
        </span>
      </div>
    </Grid>: null}
    {categoryLevel == 3 ?
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <div className={`form-group ${canEdit ? "" : "no-event"}`}>
				<span className="form-group p-float-label">
          <Dropdown
            value={selectedSubSubCategory}
            options={subSubCategoryDatas?.filter(item => !selectedCategoryArray.includes(item.id))}
            onChange={handleSubSubCategoryChange}
            optionLabel="name"
            placeholder="Select a Sub Category"
            itemTemplate={CategoryOptionTemplate}
            disabled={categoryLevel >= 3 ? false : true}
            filter
          />
          <label htmlFor="dropdown">
            {t("manageProducts_SubSubCategory")}{" "}
            <span className="required-star">*</span>
          </label>
        </span>
      </div>
    </Grid>: null}
	</Grid>
	)
};

export default Category;