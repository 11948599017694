import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import strings from "../localisation_en.json";
import modalClose from "../assets/images/structure/close-black.svg";
import { useTranslation } from "react-i18next";

const ConfirmBox = ({ isOpen = false, handleClose, confirm, message, title='' }) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="main-modal theme-dialog"
    >
      {/* <img
        src={modalClose}
        alt="..."
        onClick={handleClose}
        className="modal-close"
      /> */}
      <DialogContent>
        {title !== ''?<h4 className="h4">{title}</h4>: null}
        <h4 className="h4" style={{ marginBottom: "20px" }}>
          {message}
        </h4>
       
        <div className="delete-modal-footer">
        <Button
          onClick={confirm}
          variant="contained"
          size="small"
          sx={{ width: 1, marginTop: "0px" }}
        >
          {t("confirm")}
        </Button>
        <Button
          onClick={handleClose}
          style={{ cursor: "pointer" }}
          color="secondary"
          variant="text"
          size="small"
          sx={{ width: 1, marginTop: "0px" }}
        >
          {t("cancel")}
        </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmBox;
