import { createSlice } from "@reduxjs/toolkit";

export const storeTiming = createSlice({
  name: "storeTiming",
  initialState: {
    value: null,
  },
  reducers: {
    changeStoreTimingData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStoreTimingData } = storeTiming.actions;
export const currentStoreTimingData = (state) => state.storeTiming.value;
export default storeTiming.reducer;
