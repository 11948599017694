import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Boxed } from "../../Styles-Elements/Box";
import { useSelector, useDispatch } from "react-redux";
import globalRequest from "../../global-modules/globalRequest";
import { useTranslation } from "react-i18next";
import { changePage } from "../../redux/reducers/page";
import { changeStorePayoutsData } from "../../redux/reducers/storePayouts";
import { API, PAGES } from "../../Enum";
import { currentLocalData, } from "../../redux/reducers/localData";
import editIcon from "../../assets/images/structure/edit-black.svg";
import { getDataByKeyLocale } from "../../Helpers/Helpers";

const ManagePayouts = () => {

  const { t } = useTranslation();
  const localData = useSelector(currentLocalData);
  const dispatch = useDispatch();
  const [payouts, setPayouts] = useState([]);

  const getManagePayouts = async () => {
    let url = API.MANAGE_PAYOUTS.GET_MANAGE_PAYOUTS;
    const managePayoutsResponse = await globalRequest("get", url, {}, {}, true, true);
    setPayouts(managePayoutsResponse.data.store.filter(
      (store) => store.is_approved !== false
    ));
  };

  const hideAccountNumber = (accountNumber) => {
    if (!accountNumber) return "-";
    return accountNumber.slice(0, -4).replace(/./g, "X") + accountNumber.slice(-4);
    // return accountNumber.replace(/\d(?=\d{4})/g, "X");
  };

  useEffect(() => {
    getManagePayouts();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper">
        <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={"20px"}
        >
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}>
              {t("manageStore_ManagePayoutDetail")}
            </h3>
          </div>
          {localData.token.role === 'vendor_users'? null :
            <Button
            variant="contained"
            size="small"
            sx={{ width: "150px" }}
            className="date-picker-btn"
            onClick={() => {

              dispatch(changeStorePayoutsData({
                type: 'add-all',
                store_id: null,
                store_name: null,
                store_payout: null,
              }));
              dispatch(changePage(PAGES.MANAGE_PAYOUTS_DETAIL));
            }}
          >
            {t("manageStore_AddPayouts")}
          </Button>}
        </Boxed>

        {localData.token.role === 'vendor_users'?  null :
          <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"start"}
          mb={"20px"}
          padding={"16px 16px"}
          className="table-switch-box"
        >
          <div className="relative">
            <h6 className="h6 mb-1">
            {t('manageStore_wantToEditPayoutDetailForAllStores')}
            </h6>
            <span className="p3">
            {t('manageStore_wantToEditPayoutDetailForAllStoresText')}
            {" "} <a
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(changeStorePayoutsData({
                    type: 'edit-all',
                    store_id: null,
                    store_name: null,
                    store_payout: null,
                  }));
                  dispatch(changePage(PAGES.MANAGE_PAYOUTS_DETAIL));
                }}
              >
                {t('manageStore_wantToEditPayoutDetailForAllStoresTextToEdit')}
              </a>{" "}
              {t('manageStore_wantToEditPayoutDetailForAllStoresTextHere')}.
            </span>
          </div>
        </Boxed>}

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Stores</TableCell>
                <TableCell>Store Address</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payouts.map((payout, index) => (
                <TableRow
                  key={`payout-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {getDataByKeyLocale(payout?.stores_locales, 'business_name')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {payout?.business_address}
                  </TableCell>
                  
                  <TableCell align="right">{hideAccountNumber(payout?.payout_detail?.bankAccountNumber)}</TableCell>
                  <TableCell align="center">
                    {payout.payout_detail !== null &&
                      <img
                      src={editIcon}
                      alt="edit"
                      className="icon16 mx-2 pointer"
                      onClick={() => {
                        dispatch(changeStorePayoutsData({
                          type: 'edit',
                          store_id: payout.id,
                          store_name: getDataByKeyLocale(payout.stores_locales, 'business_name'),
                          store_payout: payout.payout_detail
                        }));
                        dispatch(changePage(PAGES.MANAGE_PAYOUTS_DETAIL));
                      }}
                    />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ManagePayouts;
