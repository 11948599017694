import { createSlice } from "@reduxjs/toolkit";

export const editProductData = createSlice({
  name: "editProductData",
  initialState: {
    value: null,
  },
  reducers: {
    changeProductData: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeProductData } = editProductData.actions;
export const currentProductData = (state) => state.editProductData.value;
export default editProductData.reducer;
