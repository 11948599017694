/** @format */

import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import "./header.scss";
import headerLogo from "../../../assets/images/logo/header-logo.svg";
import headerLogoAr from "../../../assets/images/logo/header-logo.svg";
import userWhite from "../../../assets/images/structure/user-white.svg";
import englishLangIcon from "../../../assets/images/language/english.svg";
import arabicLangIcon from "../../../assets/images/language/arabic.svg";
import dawnArrowBlack from "../../../assets/images/structure/dawn-arrow-black.svg";
// import TopToolbar from "./top-toolbar/TopToolbar";
import { Boxed } from "../../../Styles-Elements/Box";
import Login from "../../../dialog/Login";
import ForgetPassword from "../../../dialog/ForgetPassword";
import ForgetOtp from "../../../dialog/ForgetOtp";
import UpdatePassword from "../../../dialog/UpdatePassword";
import { useNavigate } from "react-router-dom";
import { currentLocalData } from "../../../redux/reducers/localData";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import globalRequest from "../../../global-modules/globalRequest";
import { API } from "../../../Enum";
import defaultImage from "../../../assets/images/structure/default.jpeg";

const Header = () => {
  const { t, i18n } = useTranslation();
  const languages = [
    {
      name: "English",
      code: "en",
      icon: englishLangIcon,
    },
    {
      name: "عربي",
      code: "ar",
      icon: arabicLangIcon,
    },
  ];

  const [lang, setLang] = React.useState(false);
  const [selectedLang, setSelectedLang] = React.useState("en");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openLogin, setOpenLogin] = useState(false);
  const [openForgetPassword, setOpenForgetPassword] = useState(false);
  const [openForgotOtp, setOpenForgotOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [openUpdatePassword, setOpenUpdatePassword] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const localData = useSelector(currentLocalData);
  let tokenData = localData?.token;

  const changeLoc = (lang) => {
    setSelectedLang(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    setLang(!lang);
    if (tokenData) {
      globalRequest('post', API.CHANGE_LANGUAGE, { language: lang }, {}, true, true)
    }
  }

  const changeLanguage = (lang) => {
    changeLoc(lang);
    window.location.reload();
  };

  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng")) {
      changeLoc(localStorage.getItem("i18nextLng"));
    } else {
      changeLoc("en");
    }
  }, []);

  return (
    <>
      <div className="header-wrapper">
        {/* <TopToolbar /> */}
        <div className="container-body">
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            padding={"10px 0"}
          >
            <img
              onClick={() => {
                navigate("/");
              }}
              src={
                selectedLang === "ar"
                  ? headerLogoAr : headerLogo
              }
              className="header-logo"
              alt=""
            />
            <div className="right-wrap">
              <div className="language-block">
                <Button
                  id="language-header-button"
                  aria-controls={open ? "language-header-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <img
                    src={
                      languages.find((lang) => lang.code === selectedLang)?.icon
                    }
                    className="language-img"
                    alt=""
                  />
                  <span className="language-text">
                    {languages.find((lang) => lang.code === selectedLang)?.name}
                  </span>
                  <img
                    src={dawnArrowBlack}
                    alt=""
                    onClick={() => setLang(!lang)}
                  />
                </Button>
                <Menu
                  id="language-header-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "language-header-button",
                  }}
                >
                  <div
                    className={lang ? "nav-drop-down" : "hidden"}
                    onClick={handleClose}
                  >
                    {languages.map((language, index) => (
                      <MenuItem
                        onClick={() => {
                          changeLanguage(language?.code);
                        }}
                        className={
                          selectedLang === language?.code
                            ? "nav-dropdown-content active"
                            : "nav-dropdown-content"
                        }
                        key={`language-${index}`}
                      >
                        <img
                          src={language?.icon}
                          className="language-img"
                          alt={language?.name}
                        />
                        {language?.name}
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
              </div>
              {tokenData == null ? (
                <Button
                  variant="contained"
                  size="large"
                  className="header-btn"
                  onClick={() => setOpenLogin(true)}
                >
                  <img src={userWhite} className="header-btn-icon" alt="" />{" "}
                  {t("header_footer_login")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="large"
                  className="header-btn"
                  onClick={() => navigate("/dashboard")}
                >
                  <img src={userWhite} className="header-btn-icon" alt="" />{" "}
                  {t("header_footer_dashboard")}
                </Button>
              )}
            </div>
          </Boxed>
        </div>
      </div>

      <Login
        isOpen={openLogin}
        handleClose={() => {
          setOpenLogin(false);
        }}
        openForget={() => {
          setOpenForgetPassword(true);
          setOpenLogin(false);
        }}
      />

      <ForgetPassword
        isOpen={openForgetPassword}
        handleClose={() => {
          setOpenForgetPassword(false);
        }}
        openForgetOtp={() => {
          setOpenForgotOtp(true);
          setOpenForgetPassword(false);
        }}
        emailCallback={(email) => {
          setEmail(email);
        }}
      />

      <ForgetOtp
        isOpen={openForgotOtp}
        handleClose={() => {
          setOpenForgotOtp(false);
        }}
        email={email}
        openUpdatePassword={(data) => {
          setData(data);
          setOpenUpdatePassword(true);
          setOpenForgotOtp(false);
        }}
      />

      <UpdatePassword
        isOpen={openUpdatePassword}
        handleClose={() => {
          setOpenUpdatePassword(false);
        }}
        data={data}
        openLogin={() => {
          setOpenLogin(true);
          setOpenUpdatePassword(false);
        }}
      />
    </>
  );
};

export default Header;
