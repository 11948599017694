import thankyouImg from "../assets/images/structure/offer-thankyou.png"
import modalClose from "../assets/images/structure/close-black.svg"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent'
import { Boxed } from '../Styles-Elements/Box';
import { useTranslation } from "react-i18next";

const ThankyouOffer = ({ isOpen = false, handleClose}) => {

  const { t } = useTranslation();

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
          <Boxed className="text-center" style={{ marginBottom: "20px", marginTop: "10px" }}>
            <img src={thankyouImg} alt="" />
          </Boxed>
          <h2 className="h3 text-center" style={{ marginBottom: "8px" }}>
            {t('myOffer_Thankyou')}
          </h2>
          <p className="p3 text-center" style={{ marginBottom: "12px", fontSize: "14px" }}>
            {t('myOffer_offerThankyouMsg')}
          </p>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ThankyouOffer;
