import "./StaticPage.scss";
import {useState, useEffect} from "react";
import {useParams, useNavigate} from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import { useTranslation } from "react-i18next";
import globalRequest from "../../global-modules/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { API } from "../../Enum";

const StaticPage = () => {

  const navigate = useNavigate();
  const { pageType } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [terms, setTnc] = useState(null);
  const title = [
    {
      name: t('static_pages_disclaimer'),
      type: 'disclaimer'
    },
    {
      name: t('static_pages_warrenty_policy'),
      type: 'warrenty_policy'
    },
    {
      name: t('static_pages_delivery_information'),
      type: 'delivery_information'
    },
    {
      name: t('static_pages_about_baladi'),
      type: 'about_baladi'
    },
    {
      name: t('static_pages_privacy_policy'),
      type: 'privacy_policy'
    }
  ];

  const getStatic = () => {
    dispatch(changeLoader(true));
    globalRequest( "get", API.STATIC_PAGES.GET_STATIC_PAGES(pageType), {}, {}, true, true).then(({data, status}) => {
      if (status === 400) {
        dispatch(changeLoader(false));
      } else {
        terms = data?.cms;
        setTnc(terms);
        dispatch(changeLoader(false));
      }
    }).catch((error) => {
      dispatch(changeLoader(false));
    })
  }

  useEffect(() => {
    getStatic();
    window.scrollTo(0, 0);
  }, [pageType]);
  
  return (
    <div>
      <Header />
      <div className="term-condition-wrapper">
        <div className="term-condition-head">
          <h3 className="h3">{title.find((t) => t.type === pageType)?.name}</h3>
        </div>
        <div className="container-body">
          <div className="" dangerouslySetInnerHTML={{__html: terms?.cms_locales[0].description,}}></div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StaticPage;
