import "./BecomeAPartnerForm.scss";
import Geocode from "react-geocode";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { geocodeByPlaceId, } from 'react-google-places-autocomplete';
import {
  OutlinedInput, IconButton, Grid, Button, InputLabel, FormControl,
  TextField, MenuItem, Select, InputAdornment, Checkbox
} from "@mui/material";
import { Boxed } from "../../../Styles-Elements/Box";
import { API, COUNTRY_CODE, KEYS, ROLE, API_GET_GEOLOCATION, VALIDATE_COORDINATES } from "../../../Enum";
import { changeSnackbar, currentSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import uploadBlackIcon from "../../../assets/images/structure/upload-black-icon.svg";
import cancelRed from "../../../assets/images/structure/cancel-red.svg";
import closeGray from "../../../assets/images/structure/close-gray.svg";
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import readImageFile from "../../../global-modules/readImageFile";
import globalRequest from "../../../global-modules/globalRequest";
import validEmail from "../../../global-modules/validEmail";
import GoogleAutocompletePlaces from '../../../global-modules/Google-libraries/GoogleAutocompletePlaces';
import MyMapComponent from '../../../global-modules/Google-libraries/MapComponent';
import AutocompleteAddress from '../../../global-modules/OpenStreetMap-library/AutocompleteAddress';
import timeOut from "../../../global-modules/timeOut";
import ThanksDialog from "../../../dialog/ThanksDialog";
import Recaptcha from "react-recaptcha";
// const Recaptcha = require("react-recaptcha");

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const placeAutocompleteOptions = 'google';

const BecomeAPartnerForm = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState('');
  const [mobile, setMobile] = useState("");
  const [mobileErr, setMobileErr] = useState('');
  const [bType, setBType] = useState("");
  const [bTypeErr, setBTypeErr] = useState('');
  const [bName, setBName] = useState("");
  const [bNameErr, setBNameErr] = useState('');
  const [bNameArabic, setBNameArabic] = useState("");
  const [bNameArabicErr, setBNameArabicErr] = useState('');
  const [country, setCountry] = useState("Qatar");
  const [businessLogo, setBusinessLogo] = useState(null);
  const [businessLogoErr, setBusinessLogoErr] = useState('');
  const [companyRegistrationFile, setCompanyRegistrationFile] = useState([]);
  const [companyRegistrationErr, setCompanyRegistrationErr] = useState('');
  const [ownerName, setOwnerName] = useState("");
  const [ownerNameErr, setOwnerNameErr] = useState('');
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerEmailErr, setOwnerEmailErr] = useState('');
  const [businessTypeList, setBusinessTypeList] = useState([]);
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [isAgree, setIsAgree] = useState(false);
  const [isAgreeErr, setIsAgreeErr] = useState('');
  const [captchaErr, setCaptchaErr] = useState('');
  const snackbar = useSelector(currentSnackbar);
  const [openDialog, setOpenDialog] = useState(false);
  const [isCaptchaDone, setIsCaptchaDone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [brandNameEnglish, setBrandNameEnglish] = useState('');
  const [brandNameEnglishErr, setBrandNameEnglishErr] = useState('');
  const [brandNameArabic, setBrandNameArabic] = useState('');
  const [brandNameArabicErr, setBrandNameArabicErr] = useState('');
  let [buildingNo, setBuildingNo] = useState("");
  let [apartmentNo, setApartmentNo] = useState("");
  let [floorNo, setFloorNo] = useState("");
  let [buildingAddress, setBuildingAddress] = useState("");
  let [buildingAddressErr, setBuildingAddressErr] = useState('');
  let [googleAddress, setGoogleAddress] = useState([]);
  let [showMaps, setShowMaps] = useState(false);
  let [password, setPassword] = useState('');
  let [confirmPassword, setConfirmPassword] = useState('');
  let [passwordErr, setPasswordErr] = useState('');
  let [confirmPasswordErr, setConfirmPasswordErr] = useState('');
  
  useEffect(() => {
    globalRequest("get", API.BUSINESS_TYPE, {}, {}, false).then((res) => {
      let data = res?.data;
      if (data?.ack == 1) {
        setBusinessTypeList(data?.businesstypes.filter((item) => item?.slug !== 'grocery'));
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          state: "error",
          isOpen: true,
          message: t('somethingWentWrong'),
        })
      );
    });
  }, []);

  const validatePassword = (password) => {
    const passwordRegex = /\d/;
    return passwordRegex.test(password);
  }

  const getAdressFromLatLng = async (lat, lng) => {
    const response = await Geocode.fromLatLng(lat, lng);
    const address = response.results[0].formatted_address;
    return address;
  };

  const populateAddress = async (address) => {
    if (address) {
      if (placeAutocompleteOptions === 'google') {
        // ---------- Google Address ---------- //
        googleAddress = address;
        setGoogleAddress(googleAddress);
        enableResetGoogleAddress();
        const geocode = await geocodeByPlaceId(address.value.place_id);
        buildingAddress = geocode[0].formatted_address;
        setBuildingAddress(buildingAddress);
        setLatitude(geocode[0].geometry.location.lat());
        setLongitude(geocode[0].geometry.location.lng());
        setBuildingAddressErr('');
        // ---------- Google Address ---------- //
      } else {
        // ---------- Open Street Map Address ---------- //
        buildingAddress = address.display_name;
        setBuildingAddress(buildingAddress);
        setLatitude(address.lat);
        setLongitude(address.lon);
        setBuildingAddressErr('');
        // ---------- Open Street Map Address ---------- //
      }
    }
  };

  const enableResetGoogleAddress = () => {
    try {
      let elements = document.getElementsByClassName("css-tlfecz-indicatorContainer");
      for (let i = 0; i < elements.length; i++) {
        elements[i].addEventListener("click", resetGoogleAddress, true);
      }
    } catch (error) {
      dispatch(changeSnackbar({
        ...snackbar,
        isOpen: true,
        message: 'Something went wrong',
        state: "error",
      }));
    }
  };

  const getAddressFromLatLng = async (lat, lng) => {
    setLatitude(lat);
    setLongitude(lng);
    buildingAddress = await getAdressFromLatLng(lat, lng);
    setBuildingAddress(buildingAddress);
    setBuildingAddressErr('');
  }

  useEffect(() => {
    if (businessLogo !== null) {
      readImageFile(businessLogo, "imageFile");
    }
  }, [businessLogo]);

  const captchCallback = (response) => {
    if (response) {
      setIsCaptchaDone(true);
      setCaptchaErr('');
    }
  };

  const handleSubmit = (e) => {
    let isValid = true;
    // if (!email) {
    //   setEmailErr(t('signup_business_email'));
    //   isValid = false;
    // } else if (!validEmail(email)) {
    //   setEmailErr(t('signup_valid_email'));
    //   isValid = false;
    // }
    if (brandNameEnglish.trim() === "") {
      setBrandNameEnglishErr(t('signup_brandNameEnglishError'));
      isValid = false;
    }
    if (brandNameArabic.trim() === "") {
      setBrandNameArabicErr(t('signup_brandNameArabicError'));
      isValid = false;
    }
    
    if (!bType) {
      setBTypeErr(t('signup_business_type'));
      isValid = false;
    }
    if (bName.trim() === "") {
      setBNameErr(t('signup_business_name'));
      isValid = false;
    }
    if (bNameArabic.trim() === "") {
      setBNameArabicErr(t('signup_business_name'));
      isValid = false;
    }
    if (!buildingAddress) {
      setBuildingAddressErr(t('signup_enterBusinessAddress'));
      isValid = false;
    }
    if (!businessLogo && businessLogo === null) {
      setBusinessLogoErr(t('signup_business_logo'));
      isValid = false;
    }
    if (companyRegistrationFile.length === 0) {
      setCompanyRegistrationErr(t('signup_company_registration'));
      isValid = false;
    }
    if (ownerName.trim() === "") {
      setOwnerNameErr(t('signup_owner_name'));
      isValid = false;
    }
    if (!mobile) {
      setMobileErr(t('signup_mobile_email'));
      isValid = false;
    } else if (mobile.length !== 8) {
      setMobileErr(t('signup_mobile_no_8_digit'));
      isValid = false;
    }
    if (ownerEmail.trim() === "") {
      setOwnerEmailErr(t('signup_owner_email'));
      isValid = false;
    } else if (!validEmail(ownerEmail)) {
      setOwnerEmailErr(t('signup_valid_email'));
      isValid = false;
    }
    if (!isAgree) {
      setIsAgreeErr(t('signup_term_and_condition'));
      isValid = false;
    }
    if (!isCaptchaDone) {
      setCaptchaErr(t('signup_verify_captcha'));
      isValid = false;
    }
    if (password === "") {
      setPasswordErr(t('signup_password'));
      isValid = false;
		} else if (password.length < 8) {
      setPasswordErr(t('signup_password_length'));
      isValid = false;
		} else if (!validatePassword(password)) {
      setPasswordErr(t('signup_password_valid'));
      isValid = false;
		}
		if (confirmPassword === '') {
      setConfirmPasswordErr(t('signup_confirmPassword'));
      isValid = false;
		} else if (confirmPassword !== password) {
      setConfirmPasswordErr(t('signup_password_not_match'));
      isValid = false;
		}
    if(isValid) {
      const formData = new FormData();
      formData.append("brand_name", brandNameEnglish);
      formData.append("brand_name_ar", brandNameArabic);
      formData.append("business_name", bName);
      formData.append("business_name_arabic", bNameArabic);
      formData.append("businessTypeId", bType);
      formData.append("business_address", buildingAddress);
      formData.append("zone_number", '');
      formData.append("street_number", floorNo);
      formData.append("building_number", buildingNo);
      formData.append("apartment_number", apartmentNo);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("country", country);
      formData.append("full_name", ownerName);
      formData.append("country_code", '974');
      formData.append("contact_number", mobile);
      formData.append("email", ownerEmail);
      // formData.append("other_email", ownerEmail);
      formData.append("role", ROLE);
      formData.append("password", password);
      formData.append("business_logo", businessLogo);
      if (companyRegistrationFile[0]) {
        formData.append("image", companyRegistrationFile[0].file);
      }
      if (companyRegistrationFile[1]) {
        formData.append("image", companyRegistrationFile[1].file);
      }
      if (companyRegistrationFile[2]) {
        formData.append("image", companyRegistrationFile[2].file);
      }
      dispatch(changeLoader(true));
      globalRequest("post", API.SIGN_UP_NEW, formData, {}, false)
        .then((res) => {
          let data = res?.data;
          if (data?.ack == 1) {
            setOpenDialog(true);
          } else {
            let msg = data?.msg;
            if(data?.errMsg) {
              msg = '';
              data?.errMsg.map((item, index) => {
                if(index > 0) {
                  msg += ', ';
                }
                msg += item?.msg;
              });
            }
            dispatch(
              changeSnackbar({
                state: "error",
                isOpen: true,
                message: msg,
              })
            );
          }
          dispatch(changeLoader(false));
        })
        .catch((err) => {
          dispatch(
            changeSnackbar({
              state: "error",
              isOpen: true,
              message: t('somethingWentWrong')
            })
          );
          dispatch(changeLoader(false));
        });
    } else {
      dispatch(changeLoader(false));
    }
  };

  const removeFile = (index) => {
    setCompanyRegistrationFile(
      companyRegistrationFile.filter((obj) => {
        return obj.index !== index;
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setShowMaps(true);
    }, 1000);
  }, []);

  return (
    <>
      <Header />
      <div className="signup-wrapper" style={{ marginTop: "30px" }}>
        <div className="container-body">
          <div className="signup-block">
            <Grid container columnSpacing={{ xs: 0, sm: 2, md: 4, lg: 5 }}>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
                <div className="signup-left">
                  <h1 className="h1">{t('signup_reachNewCustomersGetMoreSales')}</h1>
                  <h3 className="h3">
                    {t('signup_joinBaladiExpressTheMiddleEastLargestDeliveryPlatform')}
                  </h3>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={7} xl={6}>
                <div className="signup-right">
                  <div className="white-box">
                    <h3 className="h3">{t('signup_joinBaladiExpressPlatform')}</h3>
                    {/* Brand Information */}
                    <p className="p1">{t('signup_brandInformation')}</p>
                    <p className="p3"> {t('signup_brandInformationText')} </p>
                    <Grid container columnSpacing={{ xs: 0, sm: 3, md: 3, lg: 3 }}>
                      <Grid item xs={6}>
                        <div className="form-group">
                          <TextField
                            type="text"
                            value={brandNameEnglish}
                            onChange={(e) => {
                              setBrandNameEnglish(e.target.value);
                              setBrandNameEnglishErr('');
                            }}
                            required={true}
                            label={t('signup_brandNameEnglish')}
                          />
                          <span className="errorspan">{brandNameEnglishErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="form-group">
                          <TextField
                            type="text"
                            value={brandNameArabic}
                            onChange={(e) => {
                              setBrandNameArabic(e.target.value);
                              setBrandNameArabicErr('');
                            }}
                            required={true}
                            label={t('signup_brandNameArabic')}
                          />
                          <span className="errorspan">{brandNameArabicErr}</span>
                        </div>
                      </Grid>
                    </Grid>
                    {/* Brand Information */}
                    {/* Store Information */}
                    <p className="p1">{t('signup_storeInformation')}</p>
                    <p className="p3">{t('signup_storeInformationText')}</p>
                    <Grid container columnSpacing={{ xs: 0, sm: 3, md: 3, lg: 3 }}>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <TextField
                            value={bName}
                            onChange={(e) => {
                              setBName(e.target.value);
                              setBNameErr('');
                            }}
                            required={true}
                            label={t('signup_storeNameEnglish')}
                            inputProps={{ maxLength: 256 }}
                          />
                          <span className="errorspan">{bNameErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="form-group">
                          <TextField
                            value={bNameArabic}
                            onChange={(e) => {
                              setBNameArabic(e.target.value);
                              setBNameArabicErr('');
                            }}
                            required={true}
                            label={t('signup_storeNameArabic')}
                            inputProps={{ maxLength: 256 }}
                          />
                          <span className="errorspan">{bNameArabicErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              {t('signup_storeType')}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={bType}
                              label={t('signup_storeType')}
                              onChange={(e) => {
                                setBType(e.target.value);
                                setBTypeErr('');
                              }}
                            >
                              {businessTypeList.map((obj, index) => 
                              <MenuItem key={index} value={obj.id}>
                                {obj.business_type_locales[0].name}
                              </MenuItem>
                              )}
                            </Select>
                            <span className="errorspan">{bTypeErr}</span>
                          </FormControl>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 0, sm: 3, md: 3, lg: 3 }}>
                      <Grid item xs={12} mb={"16px"}>
                        {/* <p className="p1">{t('signup_storeAddress')}</p> */}
                        <p className="p1">{t('signup_LocateYourAddress')}</p>
                      </Grid>
                      
                     {showMaps && <Grid item xs={12} sm={12} style={{ position: 'relative' }} mb={"25px"}>
                        {placeAutocompleteOptions === 'google'?
                        <GoogleAutocompletePlaces
                          googleAddress={googleAddress}
                          onChange={populateAddress}
                        />: <AutocompleteAddress
                          setAddress={populateAddress}
                        />}
                      </Grid>}
                     {showMaps? <Grid item xs={12} sm={12} style={{ position: 'relative' }} mb={"25px"}>
                      <MyMapComponent
                        lat={latitude}
                        lng={longitude}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${KEYS.GOOLGE_MAP_ADDRESS_KEY}&libraries=places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `200px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        ChangeAddress={(lat, lng) => getAddressFromLatLng(lat, lng)}
                      />
                      </Grid>: null}
                      <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            required={true}
                            label={t('signup_storeAddress')}
                            value={buildingAddress}
                            inputProps={{ maxLength: 2 }}
                          />
                          <span className="errorspan">{buildingAddressErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <TextField
                            value={apartmentNo}
                            onChange={(e) => {
                              let m = e.target.value.replace(/\D/g, "");
                              setApartmentNo(m);
                            }}
                            label={t('signup_ApartmentNumber')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <TextField
                            value={buildingNo}
                            onChange={(e) => {
                              let m = e.target.value.replace(/\D/g, "");
                              setBuildingNo(m);
                            }}
                            label={t('signup_buildingNumber')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <TextField
                            label={t('signup_FloorNumber')}
                            value={floorNo}
                            onChange={(e) => {
                              let m = e.target.value.replace(/\D/g, "");
                              setFloorNo(m);
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className="form-group">
                          <TextField
                            inputProps={{ readOnly: true }}
                            value={country}
                            required={true}
                            label={t('signup_country')}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} mb={"16px"}>
                        <p className="p1">
                          {t('signup_businessLogo')} <span style={{ color: "red" }}>*</span>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <label className="img-upload-file">
                          <Boxed
                            display={"flex"}
                            alignItems={"center"}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={uploadBlackIcon} className="upload-icon" alt="" />
                            <div className="">
                              <p className="p2">{t('signup_uploadFile')}</p>
                              <p className="p3">{t('signup_fileFormat')}</p>
                            </div>
                          </Boxed>
                          <input
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={(e) => {
                              let files = e.target.files[0];
                              if (files) {
                                let sizeInMB = (files.size / (1024 * 1024)).toFixed(2);
                                if (sizeInMB <= 5) {
                                  if (
                                    files.type == "image/jpeg" ||
                                    files.type == "image/png" ||
                                    files.type == "image/jpg"
                                  ) {
                                    setBusinessLogo(files);
                                    setBusinessLogoErr('');
                                  } else {
                                    dispatch(
                                      changeSnackbar({
                                        isOpen: true,
                                        message: t('signup_accept_file_logo'),
                                        state: "error",
                                      })
                                    );
                                  }
                                } else {
                                  dispatch(
                                    changeSnackbar({
                                      isOpen: true,
                                      message: t('signup_mb5_file'),
                                      state: "error",
                                    })
                                  );
                                }
                              }
                            }}
                          />
                          <span className="errorspan">{businessLogoErr}</span>
                        </label>
                        <Boxed>
                          {businessLogo !== null ? (
                            <div className="uploaded-image-box">
                              <img
                                id={"imageFile"}
                                src=""
                                className="store-logo"
                                alt=""
                              />
                              <img
                                src={cancelRed}
                                onClick={() => setBusinessLogo(null)}
                                className="cancel-red"
                                alt=""
                              />
                            </div>
                          ) : null}
                        </Boxed>
                      </Grid>
                      <Grid item xs={12} mb={"16px"}>
                        <p className="p1">
                          {t('signup_companyRegistrationIDDocuments')}{" "}
                          <span style={{ color: "red" }}>*</span>
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <label className="img-upload-file">
                          <Boxed
                            display={"flex"}
                            alignItems={"center"}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={uploadBlackIcon} className="upload-icon" alt="" />
                            <div className="">
                              <p className="p2">{t('signup_uploadFile')}</p>
                              <p className="p3">{t('signup_Max3Files')}, {t('signup_fileFormatDoc')}</p>
                            </div>
                          </Boxed>
                          <input
                            type="file"
                            accept=".png,.jpeg,.jpg,.png,.doc,.pdf"
                            onChange={(e) => {
                              let files = e.target.files[0];

                              if (files) {
                                let sizeInMB = (files.size / (1024 * 1024)).toFixed(2);

                                if (sizeInMB <= 5) {
                                  if (
                                    files.type == "image/jpeg" ||
                                    files.type == "image/png" ||
                                    files.type == "image/jpg" ||
                                    files.type == "application/pdf" ||
                                    files.type == "application/msword"
                                  ) {
                                    if (companyRegistrationFile.length < 3) {
                                      setCompanyRegistrationFile((companyRegistrationFile) => [
                                        ...companyRegistrationFile,
                                        {
                                          index: parseInt(Math.random() * 1000),
                                          file: e.target.files[0],
                                        },
                                      ]);
                                      setCompanyRegistrationErr('');
                                    } else {
                                      dispatch(
                                        changeSnackbar({
                                          isOpen: true,
                                          message: t('signup_cannot_upload_more_than_3'),
                                          state: "error",
                                        })
                                      );
                                    }
                                  } else {
                                    dispatch(
                                      changeSnackbar({
                                        isOpen: true,
                                        message: t('signup_accept_file_doc'),
                                        state: "error",
                                      })
                                    );
                                  }
                                } else {
                                  dispatch(
                                    changeSnackbar({
                                      isOpen: true,
                                      message: t('signup_mb5_file'),
                                      state: "error",
                                    })
                                  );
                                }
                              }
                            }}
                          />
                          <span className="errorspan">{companyRegistrationErr}</span>
                        </label>
                        {companyRegistrationFile.map((obj, index) => {
                          return (
                            <div key={index} className="uploaded-text-block">
                              <span className="p2">{obj.file?.name}</span>
                              <img
                                src={closeGray}
                                className="close-icon"
                                onClick={(e) => removeFile(obj.index)}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </Grid>
                      <Grid item xs={12} mb={"16px"}>
                        <p className="p1">{t('signup_BrandOwnerLoginInformation')}</p>
                        <p className="p3 mb-1">{t('signup_BrandOwnerLoginInformationText')}</p>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            value={ownerName}
                            onChange={(e) => {
                              setOwnerName(e.target.value)
                              setOwnerNameErr('');
                            }}
                            required
                            label={t('signup_ownerFullName')}
                            inputProps={{ maxLength: 256 }}
                          />
                          <span className="errorspan">{ownerNameErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            value={mobile}
                            onChange={(e) => {
                              let mob = e.target.value.replace(/\D/g, "");
                              setMobile(mob);
                              setMobileErr('');
                            }}
                            required={true}
                            label={t('signup_OwnerMobileNumber')}
                            inputProps={{ maxLength: 8 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{COUNTRY_CODE}</InputAdornment>
                              ),
                            }}
                          />
                          <span className="errorspan">{mobileErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            type="email"
                            value={ownerEmail}
                            onChange={(e) => {
                              setOwnerEmail(e.target.value)
                              setOwnerEmailErr('');
                            }}
                            required
                            label={t('signup_ownerEmailAddress')}
                          />
                          <span className="errorspan">{ownerEmailErr}</span>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            type="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              setEmailErr('');
                            }}
                            required={true}
                            label={t('signup_businessEmail')}
                          />
                          <span className="errorspan">{emailErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <TextField
                            value={mobile}
                            onChange={(e) => {
                              let mob = e.target.value.replace(/\D/g, "");
                              setMobile(mob);
                              setMobileErr('');
                            }}
                            required={true}
                            label={t('signup_businessMobileNumber')}
                            inputProps={{ maxLength: 8 }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">{COUNTRY_CODE}</InputAdornment>
                              ),
                            }}
                          />
                          <span className="errorspan">{mobileErr}</span>
                        </div>
                      </Grid> */}
                      <Grid item xs={12}>
                        <div className="form-group">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t('password')}*
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword((show) => !show)}
                                    edge="end"
                                  >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordErr('');
                              }}
                              label={t('password')}
                            />
                            <span className="redspan">{passwordErr}</span>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t('confirmPassword')}*
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPasswordConfirm ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswordConfirm((show) => !show)}
                                    edge="end"
                                  >
                                    {!showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t('confirmPassword')}
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setConfirmPasswordErr('');
                              }}
                            />
                            <span className="redspan">{confirmPasswordErr}</span>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group checkbox-block">
                          <Checkbox
                            onChange={(e) => {
                              setIsAgree(e.target.checked);
                              setIsAgreeErr('');
                            }}
                            {...label}
                          />
                          <Boxed className="">
                            <span className="p2">{t('signup_iAgreeTo')}</span>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {window.open("/terms-conditions", "_blank");}}
                              className="p2-semibold"
                            >
                              {t('signup_termsAndConditions')}
                            </span>
                            <span className="errorspan">{isAgreeErr}</span>
                          </Boxed>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <Recaptcha
                            elementID="sign-up"
                            sitekey={KEYS.GOOGLE_RECAPTCHA_SITE_KEY}
                            verifyCallback={captchCallback}
                          />
                          <span className="errorspan">{captchaErr}</span>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} textAlign="right">
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          size="large"
                          sx={{ width: 1, marginTop: "16px",width: '140px' }}
                        >
                          {t('signup_submit')}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Footer />
      <ThanksDialog
        isOpen={openDialog}
        handleClose={() => {
          navigate("/");
        }}
      />
    </>
  );
};

export default BecomeAPartnerForm;
