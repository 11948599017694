import React, { useEffect, useState } from "react";
import "./ManageTiming.scss";
import { Button, Grid, Divider, IconButton, Checkbox, InputLabel, Select, MenuItem, FormControl, OutlinedInput } from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { currentLocalData } from "../../redux/reducers/localData";
import globalRequest from "../../global-modules/globalRequest";
import { API, PAGES } from "../../Enum";
import { changeLoader } from "../../redux/reducers/loader";
import { changeLocalData } from "../../redux/reducers/localData";
import { changePage } from "../../redux/reducers/page";
import { useTranslation } from "react-i18next";
import plusIcon from "../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../assets/images/structure/minus-icon.svg";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg"
import YesNoConfirmationBox from "../../dialog/YesNoConfirmationBox";
import OverrideModal from "../../dialog/OverrideModal";
import SearchSelectStore from "../../shared/SearchSelectStore";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";
import {
  generateHours, convertTimeTo12Hour, getDataByKeyLocale
} from '../../Helpers/Helpers'
import { currentStoreTimingData } from "../../redux/reducers/storeTiming";

const ManageTimingEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allHours = generateHours(15);
  const currentUserStoreData = useSelector(currentLocalData);
	const storeTimingData = useSelector(currentStoreTimingData);
  const [showOverwrite, setShowOverwrite] = useState(false);
  let [selectedStores, setSelectedStores] = useState([]);
  let [storeOptions, setStoreOptions] = useState([]);
  let [overwrite, setOverwrite] = useState(true);
  let [affectedStores, setAffectedStores] = useState([]);
  const [dayOpenClose, setDayOpenClose] = useState([
    {
      day: "sunday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      day: "monday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      day: "tuesday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      day: "wednesday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      day: "thursday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      day: "friday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }, {
      day: "saturday",
      open: true,
      sync: false,
      time: [{
        startTime: "01:00",
        endTime: "18:00",
        startTimeError: "",
        endTimeError: ""
      }]
    }
  ]);
  let [open247, setOpen247] = useState(false);
  let [syncDays, setSyncDays] = useState(false);

  const addTime = (index) => {
    let temp = [...dayOpenClose];
    const prevEndTime = temp[index].time[temp[index].time.length -1].endTime
    let newStartTime = generateHours(15).find(time => parseInt(time.split(':').join('')) > parseInt(prevEndTime.split(':').join('')))
    let newEndTime = generateHours(60).find(time => parseInt(time.split(':').join('')) > parseInt(newStartTime.split(':').join('')))
    temp[index].time.push({
      startTime: newStartTime,
      endTime: newEndTime,
      startTimeError: "",
      endTimeError: ""
    });
    setDayOpenClose(temp);
  };

  const subTime = (index, index2) => {
    let temp = [...dayOpenClose];
    temp[index].time.splice(index2, 1);
    setDayOpenClose(temp);
  }

  const changeTime = (e, type, index, index1) => {
    let temp = [...dayOpenClose];
    temp[index].time.map((time, ind) => {
      time.startTimeError = '';
      time.endTimeError = '';
      if (ind === index1) {
        if (type === 'start') {
          const thisStartTime = parseInt(e.target.value.split(':').join(''));
          const thisEndTime = parseInt(temp[index].time[ind].endTime.split(':').join(''));
          time.startTimeError = '';
          const otherTimes = temp[index].time.filter((time, ind2) => ind2 !== ind);
          
          const isOverlapping = otherTimes.find((time) => {
            const startTime = parseInt(time.startTime.split(':').join(''));
            const endTime = parseInt(time.endTime.split(':').join(''));
            if (thisStartTime >= startTime && thisStartTime <= endTime) {
              return true;
            }
            if (thisEndTime >= startTime && thisEndTime <= endTime) {
              return true;
            }
            if (thisStartTime <= startTime && thisEndTime >= endTime) {
              return true;
            }
            if (thisStartTime === thisEndTime) {
              return true;
            }
            return false;
          });
          if (isOverlapping) {
            time.startTimeError = t('manageStore_shiftTimeAreOverlapping');
          }
          time.startTime = e.target.value;
          time.endTime = temp[index].time[ind].endTime;
        } else {
          const thisStartTime = parseInt(temp[index].time[ind].startTime.split(':').join(''));
          const thisEndTime = parseInt(e.target.value.split(':').join(''));
          time.endTimeError = '';
          const otherTimes = temp[index].time.filter((time, ind2) => ind2 !== ind);
          const isOverlapping = otherTimes.find((time) => {
            const startTime = parseInt(time.startTime.split(':').join(''));
            const endTime = parseInt(time.endTime.split(':').join(''));
            if (thisStartTime >= startTime && thisStartTime <= endTime) {
              return true;
            }
            if (thisEndTime >= startTime && thisEndTime <= endTime) {
              return true;
            }
            if (thisStartTime <= startTime && thisEndTime >= endTime) {
              return true;
            }
            if (thisStartTime === thisEndTime) {
              return true;
            }
            return false;
          });
          if (isOverlapping) {
            time.endTimeError = t('manageStore_shiftTimeAreOverlapping');
          }
          time.startTime = temp[index].time[ind].startTime;
          time.endTime = e.target.value;
        }
      }
    });
    
    // if (type === 'start') {
    //   if (
    //     index1 > 0 &&
    //     parseInt(temp[index].time[index1 - 1].endTime.split(':').join('')) > parseInt(e.target.value.split(':').join(''))
    //   ) {
    //     temp[index].time[index1].startTimeError = t('manageStore_shiftTimeAreOverlapping');
    //   } else {
    //     temp[index].time[index1].startTimeError = '';
    //   }
    //   temp[index].time[index1].startTime = e.target.value;
    // } else {
    //   if (
    //     parseInt(temp[index].time[index1].startTime.split(':').join('')) > parseInt(e.target.value.split(':').join(''))
    //   ) {
    //     temp[index].time[index1].endTimeError = t('manageStore_CloseTimeShouldBeGreater');
    //   } else if ( parseInt(temp[index].time[index1 + 1]?.startTime.split(':').join('')) < parseInt(e.target.value.split(':').join(''))) {
    //     temp[index].time[index1].endTimeError = t('manageStore_shiftTimeAreOverlapping');
    //   } else {
    //     temp[index].time[index1].endTimeError = ''
    //   }
    //   temp[index].time[index1].endTime = e.target.value;
    // }
    setDayOpenClose(temp);
  }

  const checkOpen274 = (e) => {
    setOpen247(e.target.checked);
    if (e.target.checked) {
      let temp = dayOpenClose.map(workHours => {
        let obj = workHours;
        obj.open = true;
        obj.sync = false;
        obj.time = [{
          startTime: "00:00",
          endTime: "23:59",
          startTimeError: "",
          endTimeError: ""
        }]
        return obj;
      })
      setDayOpenClose(temp);
    }
  }

  const handleDayCheck = (e, index) => {
    const temp = dayOpenClose.map((day, ind) => {
      let obj = day;
      if (index === ind) {
        obj.open = e.target.checked
      }
      return obj
    });
    setDayOpenClose(temp);
  }

  const handleSync = (ind) => {
    setSyncDays(true)
    const checkedTime = dayOpenClose.find((day, index) => index === ind)?.time;
    let temp = dayOpenClose.map((day) => {
      let obj = day;
      obj.time = [...checkedTime];
      return obj;
    });
    setDayOpenClose(temp)
    setSyncDays(false)
  }

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + currentUserStoreData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions = []
    if (response.data.ack === 1) {
      storeOptions = [{ label: "All Stores", value: 0, secondLabel: "" }]
      storeOptionArray = response.data.stores.filter(
        (store) => store.is_approved === null || store.is_approved === true
      ).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    dispatch(changeLoader(false));
    getStoreDetails();
  };
  
  const getStoreDetails = () => {
		if (storeTimingData && storeTimingData?.store_id !== null) {
      selectedStores = storeOptions.find((store) => store.value === storeTimingData?.store_id);
      setSelectedStores(selectedStores);
    } else {
      selectedStores = storeOptions;
      setSelectedStores(selectedStores);
    }
    if (
      storeTimingData  &&
      (storeTimingData?.type !== 'edit-all' && storeTimingData?.type !== 'add-all') &&
      storeTimingData?.store_timing.length > 0
    ) {
      setOpen247(storeTimingData?.open247 === 1);
      if (storeTimingData?.open247 === 1) {
        let temp = dayOpenClose.map(workHours => {
          let obj = workHours;
          obj.open = true;
          obj.sync = false;
          obj.time = [{
            startTime: "00:00",
            endTime: "23:59",
            startTimeError: "",
            endTimeError: ""
          }]
          return obj;
        })
        setDayOpenClose(temp);
      } else {
        let days = dayOpenClose.map((day) => {
          let obj = day;
          const dayTiming = storeTimingData?.store_timing?.find((timing) => timing.day === day.day);
          const time = dayTiming.timejson !==''? JSON.parse(dayTiming.timejson): day.time;
          obj.open = dayTiming.open;
          obj.sync = false;
          obj.time = time.map((shift) => {
            return {
              startTime: shift.starttime,
              endTime: shift.endtime,
              startTimeError: '',
              endTimeError: ''
            }
          })
          return obj;
        });
        setDayOpenClose(days);
      }
    }
  };

  const submit = async () => {
    if (selectedStores.length === 0) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t("manageStore_selectStore"),
        })
      );
      return;
    }

    let bool = true;
    let days = [];
    let a = [];
    if (open247) {
      dayOpenClose.forEach((obj, ind) => {
        a.push({
          day: obj.day,
          open: obj.open,
          time: [{
            starttime: '00:00',
            endtime: '24:00'
          }]
        });
      })
    } else {
      dayOpenClose.forEach((obj, ind) => {
        a.push({
          day: obj.day,
          open: obj.open,
          time: obj.time.map((shift) => {
            if (shift.startTimeError !== '' || shift.endTimeError !== '') {
              bool = false;
            }
            if (parseInt(shift.startTime.split(':').join('')) > parseInt(shift.endTime.split(':').join(''))) {
              bool = false;
            }
            return {
              starttime: shift.startTime,
              endtime: shift.endTime
            }
          })
        });
      });
    }
    if (bool) {
      dispatch(changeLoader(true));
      let url = API.MANAGE_STORE_TIMING.UPDATE_WORKING_HOURS_STORE(storeTimingData?.store_id);
      let data = {
        hoursdata: JSON.stringify(a),
        open247: open247 ? 1: 0
      };
      if (storeTimingData?.type === 'edit-all' || storeTimingData?.type === 'add-all') {
        url = API.MANAGE_STORE_TIMING.UPDATE_WORKING_HOURS_ALL_STORES;
        data.overwrite_all = overwrite ? 1: 0;
        data.storeId = selectedStores.map((store) => store.value).join(',');
      }
      globalRequest("post", url, data, {}, true, true)
        .then(async (res) => {
        let data = res.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: data?.ack == 1 ? "success" : "error",
            message: data?.msg,
          })
        );
        dispatch(changeLoader(false));
        if (data?.ack == 1) {
          dispatch(changeLocalData());
          dispatch(changePage(PAGES.MANAGE_TIMING));
        }
      }).catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
        dispatch(changeLoader(false));
      });
    } else {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t('manageStore_shiftTimeAreOverlapping'),
        })
      );
    }
  };

  const checkOverwrite = async () => {
    if (selectedStores.length === 0) {
      dispatch(
        changeSnackbar({
          isOpen: true,
          state: "error",
          message: t("manageStore_selectStore"),
        })
      );
      return;
    }
    globalRequest("post", API.MANAGE_STORE_TIMING.CHECK_STORE_OVERWRITE, {
      storeId: selectedStores.map((store) => store.value).join(',')
    }, {}, true, true)
      .then(async (res) => {
        let data = res.data;
        if (data?.ack == 1) {
          if (data.overwrite_all) {
            affectedStores = data.affectedStores.map(
              (store) => getDataByKeyLocale(store.stores_locales, 'business_name')
            );
            setAffectedStores(affectedStores)
            setShowOverwrite(true);
          } else {
            submit();
          }
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: data?.msg,
            })
          );
        }
      }).catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  useEffect(() => {
    getStoreOptions();
  }, []);

    return (
        <div className="main-mid-wrapper">
          <div className="store-detail-wrapper  max-w-990">
            <form onSubmit={(e) => {
              e.preventDefault();
              if (storeTimingData?.type === 'edit-all' || storeTimingData?.type === 'add-all') {
                checkOverwrite();
              } else {
                submit(e);
              }
            }}>
              <Boxed
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                mb={"20px"}
              >
                <div className="store-detail-head" style={{ marginBottom: "0px" }}>
                  <img
                    src={arrowLeftBlack} alt="" className="rotate180"
                    onClick={() => {
                      dispatch(changePage(PAGES.MANAGE_TIMING));
                    }}
                  />
                  <h3 className="h3" style={{ marginRight: "0px" }}>
                    {
                      storeTimingData?.type === 'add-all'?
                        t("manageStore_AddTiming"):
                        storeTimingData?.type === 'edit-all'?
                          t("manageStore_EditTiming"):
                          t("manageStore_EditTiming") + ' | ' + storeTimingData?.store_name
                    }
                  </h3>
                </div>
              </Boxed>
              <div className="card-white">
                <div className="business-detail-wrap">
                  <p className="p2">
                    {t("manageStore_selectStoresForWhichYouWantToSet")}
                  </p>
                  <div className="signup-right max-w-720">
                      <Grid container item={true} xs={12} sm={12} md={12} lg={12} xl={12} mr={"auto"} mt={"24px"}>
                        <Grid item={true} xs={12} sm={8} md={8} lg={7} xl={7} >
                           <div className="form-group">
                          {storeTimingData && storeTimingData?.store_id !== null?
                            <SearchSelectSingleStore
                              options={storeOptions}
                              value={selectedStores}
                              disabled={true}
                            />:
                            <SearchSelectStore
                              options={storeOptions}
                              value={selectedStores.map((store) => store.value)}
                              changeSelectedStores={(e) => {
                                setSelectedStores(storeOptions.filter((store) => e.includes(store.value)));
                              }}
                            />}
                          </div>
                        </Grid>
                        <Grid item={true} xs={12} sm={8} md={8} lg={7} xl={7}>
                        <h6 className="h6 mb-4">{t("accountSetup_SetWorkingHours")}</h6>
                          <div className="checkbox-block">
                            <Checkbox
                              value={open247}
                              checked={open247}
                              onChange={checkOpen274}
                            />
                            <Boxed className="">
                              <span style={{ textTransform: "capitalize" }} className="p2">
                                {t('manageStore_Open247')}
                              </span>
                            </Boxed>
                          </div>
                        </Grid>
                      </Grid>
                    <Divider sx={{my: '24px'}} />
                    <Grid
                      container
                      columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4 }}
                      className={`${open247?'disable-hours':''}`}
                    >
                      {dayOpenClose.map((obj, ind) => {
                        return (
                            <>
                          <Grid key={ind} item xs={12} sm={12} md={12} lg={12} mr={"auto"} >
                            <Grid
                              container
                              columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2 }}
                            >
                              <Grid
                                item
                                xs={3.6} sm={3} md={3} lg={3} display={"flex"} alignItems={"center"}
                              >
                                <div className="checkbox-block">
                                  <Checkbox
                                    value={obj.open}
                                    onChange={(e) => {handleDayCheck(e, ind)}}
                                    checked={obj.open}
                                  />
                                  <Boxed className="">
                                    <span style={{ textTransform: "capitalize" }} className="p2">{obj.day}</span>
                                  </Boxed>
                                </div>
                              </Grid>
                              <Grid item={true} xs={12} sm={9}>

                              {obj?.time?.map((time, indexC) =>
                                <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }} mb={1} key={`times-${obj.day}-${indexC}`}>
                                  <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                    <Boxed className="">
                                      <span style={{ textTransform: "capitalize" }} className="p2 text-info bold">{t('accountSetup_shift')} {indexC + 1}</span>
                                    </Boxed>
                                  </Grid>
                                  <Grid item 
                                    xs={4.2} sm={4} md={4} lg={4}>
                                    <div className="form-control clock-picker">
                                      <InputLabel id="openTime">{t("accountSetup_openTime")}</InputLabel>
                                      <Select
                                        disabled={!obj.open}
                                        labelId="openTime"
                                        label={t("accountSetup_openTime")}
                                        value={time.startTime}
                                        onChange={(e) => { changeTime(e, 'start', ind, indexC); }}
                                      >
                                        {allHours.map((obj1, ind) =>  <MenuItem key={ind} value={obj1}> {convertTimeTo12Hour(obj1)} </MenuItem> )}
                                      </Select>
                                      <span className="redspan">{time.startTimeError}</span>
                                    </div>
                                  </Grid>
                                  <Grid item 
                                    xs={4.2} sm={4} md={4} lg={4}>
                                    <div className="form-control clock-picker">
                                      <InputLabel id="closeTime">
                                        {t("accountSetup_closeTime")}
                                      </InputLabel>
                                      <Select
                                        disabled={!obj.open}
                                        labelId="closeTime"
                                        label={t("accountSetup_closeTime")}
                                        value={time.endTime}
                                        onChange={(e) => { changeTime(e, 'end', ind, indexC); }}
                                      >
                                        {allHours.map((obj1, ind) => {
                                          return (
                                            <MenuItem key={ind} value={obj1}>
                                              {convertTimeTo12Hour(obj1)}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                      <span className="redspan">{time.endTimeError}</span>
                                    </div>
                                  </Grid>
                                  <Grid item xs={6} sm={2}>
                                    {indexC === 0?
                                      obj?.time[obj?.time.length - 1].endTime !== '23:59'?<IconButton onClick={() => {addTime(ind);}} sx={{mt: 1}}><img src={plusIcon} alt="plus icon"  /></IconButton>: null:
                                    <IconButton onClick={() => {subTime(ind, indexC);}} sx={{mt: 1}}><img src={minusIcon} alt="minus icon"  /></IconButton>}
                                  </Grid>
                                </Grid>)}


                              </Grid>
                            </Grid>

                            <Grid container={true} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 3 }} mb={3}>
                              <Grid item={true} xs={12} sm={3} display={"flex"} alignItems={"center"}></Grid>
                              <Grid item={true} xs={12} sm={9} display={"flex"} alignItems={"center"}>
                              {!syncDays?<span className="p2 mb-0 text-primary"
                                style={{ textTransform: "capitalize", cursor: 'pointer', }}
                                onClick={() => { handleSync(ind) }}
                              >{t('manageStore_syncTo', {day: obj.day})}</span>:<span> {t('manageStore_syncIngTo', {day: obj.day})} </span>}
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Divider sx={{my: '24px', w: '100%'}} />
                            </Grid>
                          </Grid>
                            
                            </>
                        );
                      })}
                    </Grid>
                    </div>
            </div>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Button
              variant="contained"
              size="large"
              sx={{ width: "150px" }}
              type="submit"
              className="date-picker-btn"
            >
              {t("manageStore_save")}
            </Button>
          </Boxed>
        </form>
      </div>
      {showOverwrite?<OverrideModal
        handleClose={() => setShowOverwrite(false)}
        message={t('manageStore_DoYouWantToOverrideTimingText', {
          noOfStores: `
            ${affectedStores?.length > 1?
              affectedStores?.length + " stores": affectedStores?.length + " store"
            } (
            ${affectedStores?.length > 4?
              affectedStores?.splice(0, 4).join(', ') + '...' :affectedStores?.join(', ')
            })`,
        })}
        handleYes = {() => {
          overwrite = true;
          setOverwrite(overwrite);
          setShowOverwrite(false);
          submit();
        }}
        handleNo = {() => {
          overwrite = false;
          setOverwrite(overwrite);
          setShowOverwrite(false);
          submit();
        }}
      />:null}
    </div>
  );
};

export default ManageTimingEdit;