import { createSlice } from '@reduxjs/toolkit'

export const myNewOrder = createSlice({
  name: 'myNewOrder',
  initialState: {
    value: false,  //notificationEnabled
  },
  reducers: {
    changeNewOrder: (state, action) => {
      state.value = action.payload 
    }
  },
})

// Action creators are generated for each case reducer function
export const { changeNewOrder , clickNewOrder  } = myNewOrder.actions
export const currentNewOrder = (state) => state.myNewOrder.value;
export default myNewOrder.reducer
