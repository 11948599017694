import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Grid } from "@mui/material";
import "./ManageHoliday.scss";
import { Boxed } from "../../../Styles-Elements/Box";
import editIcon from "../../../assets/images/structure/edit-black.svg";
import deleteIcon from "../../../assets/images/structure/delete-black.svg";
import noCalender from "../../../assets/images/structure/no-calender.png";
import AddHoliday from "../../../dialog/AddHoliday";
import globalRequest from "../../../global-modules/globalRequest";
import { API } from "../../../Enum";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeSnackbar } from "../../../redux/reducers/snackbar";
import skipReg from "../../../global-modules/skipReg";
import { changePage } from "../../../redux/reducers/page";
import { PAGES } from "../../../Enum";
import { currentLocalData, } from "../../../redux/reducers/localData";
import ConfirmBox from "../../../dialog/ConfirmBox";
import { useTranslation } from "react-i18next";

const ManageHoliday = () => {

  const [futureYear, setFutureYear] = useState("All");
  const [openAddHoliday, setOpenAddHoliday] = useState(false);
  const [updateValue, setUpdateValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isAdded, setIsAdded] = useState(true);
  const [isEditted, setIsEditted] = useState(false);
  const [data, setData] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  let tokenData = localData?.token;
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [holidayId, setHolidayId] = useState(0);
  const [years, setYears] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (isAdded || isDeleted || isEditted) {
      getHoliday();
      setIsAdded(false);
      setIsDeleted(false);
      setIsEditted(false);
    }
  }, [isAdded, isDeleted, isEditted]);

  const getHoliday = () => {
    dispatch(changeLoader(true));
    globalRequest(
      "get",
      API.GET_WORKING_HOLIDAY,
      {},
      {
        params: {
          sort_by: "date",
          order_by: "asc",
        },
      },
      true,
      true
    )
      .then((res) => {
        let data1 = res?.data;

        if (data1.ack == 1) {
          setData(data1.result);
          setSaveData(data1.result);
        }
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
      });
  };

  useEffect(() => {
    if (saveData.length) {
      let a = [];
      saveData.forEach((obj) => {
        let date = new Date(obj.date);
        a.push({
          year: date.getFullYear(),
        });
      });
      setYears(a);
    }
  }, [saveData]);

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dates = (da) => {
    let d = new Date(da);
    let day = d.getDate();
    return `${month[d.getMonth()]} ${
      day < 10 ? `0${day}` : day
    }, ${d.getFullYear()}`;
  };

  const deletes = (id) => {
    dispatch(changeLoader(true));
    globalRequest("delete", API.DELETE_WORKING_HOLIDAY(id), {}, {}, true, true)
      .then((res) => {
        let data = res?.data;
        if (data.ack == 1) {
          setIsDeleted(true);
          setHolidayId(0);
          setOpenConfirmBox(false);
        }

        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t("somethingWentWrong"),
          })
        );
        dispatch(changeLoader(false));
      });
  };

  const filterTheData = (e) => {
    let value = e.target.value;
    setFutureYear(value);
    if (value == "All") {
      setData(saveData);
    } else {
      let a = saveData.filter((a) => new Date(a.date).getFullYear() == value);
      setData(a);
    }
  };

  const skipR = async () => {
    await skipReg(2);
    dispatch(changePage(PAGES.PAYOUT_DETAIL));
  };

  return (
    <>
      <div className="manage-workinghours-wrapper">
        <div className="manage-workinghours-head">
          <h3 className="h3">{t("accountSetup_completeYourAccountSetup")}</h3>
        </div>
        <div className="container-body">
          <Grid container columnSpacing={{ xs: 0, sm: 1, md: 1, lg: 1 }}>
            <Grid item xs={12} sm={12} md={10} mx={"auto"}>
              <div className="working-holiday-block">
                <div className="card-white">
                  {saveData.length ? (
                    <>
                      <div
                        className="holiday-head"
                        style={{ alignItems: "center" }}
                      >
                        <div className="relative">
                          <h4 className="h4">
                            {t("accountSetup_ManageHolidays")}
                          </h4>
                          <p className="p2 mb-0">
                            {t("accountSetup_YouCanAddYourHolidayHere")}
                          </p>
                        </div>
                        <div className="relative">
                          <FormControl
                            sx={{ m: "0px 12px", width: 120 }}
                            size="small"
                            className="calender-icon"
                          >
                            <Select
                              value={futureYear}
                              displayEmpty
                              onChange={filterTheData}
                            >
                              <MenuItem value={"All"}>{t('accountSetup_All')}</MenuItem>
                              {years.map((obj) =><MenuItem value={obj.year}>{obj.year}</MenuItem>)}
                            </Select>
                          </FormControl>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => { setFutureYear("All"); setOpenAddHoliday(true) }}
                            sx={{ width: "140px", height: 40, marginTop: "0px", }}
                          >
                            {t("accountSetup_addHoliday")}
                          </Button>
                        </div>
                      </div>
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell >{t('accountSetup_HolidayName')}</TableCell>
                              <TableCell >{t('accountSetup_Date')}</TableCell>
                              <TableCell align="center">{t('accountSetup_Action')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.map((obj, ind) => {
                              return (
                                <TableRow key={ind}>
                                  <TableCell component="th" scope="row"  >{obj.holidayName}</TableCell>
                                  <TableCell >{dates(obj.date)}</TableCell>
                                  <TableCell align="center">
                                    <img
                                      src={editIcon}
                                      alt="..."
                                      onClick={() => {
                                        setOpenAddHoliday(true);
                                        setUpdateValue({
                                          name: obj.holidayName,
                                          date: new Date(obj.date),
                                          id: obj.id,
                                        });
                                        setIsEdit(true);
                                      }}
                                      className="icon20"
                                    />
                                    <img
                                      src={deleteIcon}
                                      onClick={() => {
                                        setOpenConfirmBox(true);
                                        setHolidayId(obj.id);
                                      }}
                                      alt="..."
                                      className="icon20"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <>
                      <Boxed
                        display={"flex"}
                        alignItems={"flex-start"}
                        justifyContent={"space-between"}
                        className="empty-header-row"
                        mb={"20px"}
                        onClick={() => {
                          skipR();
                        }}
                      >
                        <div className="relative">
                          <h6 className="h6">{t("accountSetup_manageHoliday")}</h6>
                          <p className="p2">{t("accountSetup_YouCanAddYourHolidayHere")}</p>
                        </div>
                        <Button variant="standard" size="medium">{t("accountSetup_skipThatStep")}</Button>
                      </Boxed>
                      <div className="empty-box">
                        <img
                          src={noCalender}
                          alt="..."
                          className="empty-img width220"
                        />
                        <Button
                          onClick={() => {setOpenAddHoliday(true);}}
                          variant="contained"
                          size="large"
                        >{t("accountSetup_addHoliday")}</Button>
                      </div>
                    </>
                  )}
                </div>
                <Boxed display={"flex"} justifyContent={"space-between"}>
                  <Button
                    variant="outlined"
                    size="large"
                    className={
                      saveData.length ? "" : "secondary-outline-gray-disable"
                    }
                    sx={{ width: "150px", marginTop: "16px" }}
                    onClick={() =>
                      dispatch(changePage(PAGES.MANAGE_WORKING_HOUR))
                    }
                  >
                    {t("accountSetup_back")}
                  </Button>
                  <Button
                    onClick={async () => {
                      if (saveData.length) {
                        skipR();
                        dispatch(changePage(PAGES.PAYOUT_DETAIL));
                      }
                    }}
                    disabled={saveData.length ? false : true}
                    variant={saveData.length ? "contained" : "contained"}
                    // className="secondary-outline-gray"
                    size="large"
                    sx={{ width: "150px", marginTop: "16px" }}
                  >
                    {t("accountSetup_continue")}
                  </Button>
                </Boxed>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <AddHoliday
        updateValue={updateValue}
        isOpen={openAddHoliday}
        handleClose={() => {
          setOpenAddHoliday(false);
          setUpdateValue({});
          if (isEdit) {
            setIsEditted(true);
          } else {
            setIsAdded(true);
          }
          setIsEdit(false);
        }}
        isEdit={isEdit}
      />

      <ConfirmBox
        isOpen={openConfirmBox}
        handleClose={() => setOpenConfirmBox(false)}
        confirm={() => deletes(holidayId)}
        message={t("accountSetup_DeleteThisHoliday")}
      />
    </>
  );
};

export default ManageHoliday;
