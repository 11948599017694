const readImageFile = function (file, id) {
  try {
    const preview = document.querySelector(`#${id}`);
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        preview.src = reader.result;
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  } catch (e) {}
};

export default readImageFile;
