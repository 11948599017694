import React, { useEffect, useState } from "react";
import {
  Button, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, FormControl, Select, MenuItem
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Boxed } from "../../Styles-Elements/Box";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import AddHoliday from "../../dialog/AddHoliday";
import EditHoliday from "../../dialog/EditHoliday";
import { API } from "../../Enum";
import globalRequest from "../../global-modules/globalRequest";
import { currentLocalData } from "../../redux/reducers/localData";
import editIcon from "../../assets/images/structure/edit-black.svg";
import deleteIcon from "../../assets/images/structure/delete-black.svg";
import InactiveAllHolidays from "../../dialog/InactiveAllHolidays";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import { changeLoader } from "../../redux/reducers/loader";
import { getDataByKeyLocale } from "../../Helpers/Helpers";
import SearchSelectSingleStore from "../../shared/SearchSelectSingleStore";
import ConfirmModel from "../../dialog/ConfirmBox";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import StoreList from "../../dialog/StoreList";

const month = [
  "January", "Febuary", "March", "April", "May", "June",
  "July", "Auguest", "September", "October", "November", "Decmber",
];

const ManageHolidays = () => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  const [futureYear, setFutureYear] = useState("all");
  const [openAddHoliday, setOpenAddHoliday] = useState(false);
  const [openInactiveHoliday, setOpenInactiveHoliday] = useState(false);
  const [updateValue, setUpdateValue] = useState({});
  let [isEdit, setIsEdit] = useState(false);
  const [years, setYears] = useState([]);
  const [allHolidays, setAllHolidays] = useState([]);
  const [openDeleteHoliday, setOpenDeleteHoliday] = useState(false);
  let [selectedHoliday, setSelectedHoliday] = useState(null);
  let [editHoliday, setEditHoliday] = useState({});
  let [storeOptions, setStoreOptions] = useState([]);
  let [selectedStore, setSelectedStore] = useState(null);
  let [showEditHoliday, setShowEditHoliday] = useState(false);
  let [showEditStoreIds, setShowEditStoreIds] = useState([]);
  const [shoeStoreList, setShoeStoreList] = useState(false);

  let [sortBy, setSortBy] = useState("date");
  let [orderBy, setOrderBy] = useState("asc");

  const dates = (da) => {
    let d = new Date(da);
    let day = d.getDate();
    return `${day < 10 ? `0${day}` : day} ${month[d.getMonth()]} ${d.getFullYear()}`;
  };

  const deleteHoliday = () => {
    setOpenDeleteHoliday(false)
    dispatch(changeLoader(true));
    // let url = (localData.token.role === 'vendor')?
    //   API.MANAGE_HOLIDAYS.DELETE_HOLIDAY_FOR_BRAND(selectedHoliday.id):
    //   API.MANAGE_HOLIDAYS.DELETE_HOLIDAY(selectedHoliday.id);
    let url = API.MANAGE_HOLIDAYS.DELETE_HOLIDAY(selectedHoliday.id);
    globalRequest('delete', url, {}, {}, true, true)
      .then((res) => {
        if (res.data.ack === 1) {
          dispatch(changeSnackbar({
            isOpen: true, state: "success",
            message: res.data.msg,
          }));
          getWorkingHoliday();
        } else {
          dispatch(changeSnackbar({
            isOpen: true, state: "error",
            message: res.data.msg,
          }));
        }
      })
      .catch((err) => {
        dispatch(changeSnackbar({
          isOpen: true, state: "error",
          message: t("somethingWentWrong"),
        }));
      });
    dispatch(changeLoader(false));
  };

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + localData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    if (response.data.ack === 1) {
      storeOptionArray = response.data.stores.filter(
        (store) => store.is_approved === null || store.is_approved === true
      ).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = storeOptionArray;
    setStoreOptions(storeOptions);
    selectedStore = storeOptions.find((store) => store.value == localData.token.store.id) || storeOptions[0];
    setSelectedStore(selectedStore)
    getWorkingHoliday();
    dispatch(changeLoader(false));
  };

  const getWorkingHoliday = () => {
    dispatch(changeLoader(true));
    // let url = (localData.token.role === 'vendor')?
    //   API.MANAGE_HOLIDAYS.GET_ALL_HOLIDAYS_FOR_BRAND:
    //   API.MANAGE_HOLIDAYS.GET_ALL_HOLIDAYS + "/" + localData.token.store.id;

    let url = API.MANAGE_HOLIDAYS.GET_ALL_HOLIDAYS + "/" + selectedStore.value
    url += "?sort_by=" + sortBy + "&order_by=" + orderBy;
    globalRequest('get', url, {}, {}, true, true)
      .then((res) => {
        if (res.data.ack === 1) {
          const holidayData = res.data.result.map((obj) => {
            const totalStoreIds = obj?.storeIdes?.split(',');
            const toralStores = storeOptions.filter((store) => totalStoreIds.includes(store.value.toString()));
            obj.totalStores = toralStores.length;
            return obj;
          });
          setAllHolidays(holidayData);
          const dates = holidayData.map((obj) => obj.date);
          const yearsAll = [...new Set(dates.map((date) => new Date(date).getFullYear()))];
          setYears(yearsAll);
        } else {
          dispatch(changeSnackbar({
            isOpen: true, state: "error",
            message: res.data.msg
          }));
        }
      })
      .catch((err) => {
        dispatch(changeSnackbar({
          isOpen: true, state: "error",
          message: t("somethingWentWrong"),
        }));
      });
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    getStoreOptions();
    // getWorkingHoliday();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper">
        <Boxed display={"flex"} alignItems={"center"} justifyContent={"space-between"} mb={"20px"} >
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}> {t("manageStore_manageHolidays")} </h3>
          </div>
          <div className="relative">
            {localData.token.role === 'vendor'?<FormControl className="payout-status" sx={{ margin: "0px 0px", width: '231px' }}>
              <SearchSelectSingleStore
                options={storeOptions}
                value={selectedStore}
                handleOptionsChange={(e, value) => {
                  // page = 1;
                  // setPage(page);
                  selectedStore = value;
                  setSelectedStore(selectedStore);
                  getWorkingHoliday();
                }}
              />
            </FormControl>: null}
            <FormControl sx={{ m: "0px 12px", width: 120 }} size="small" className="calender-icon">
              <Select value={futureYear} onChange={(e) => setFutureYear(e.target.value)} >
                <MenuItem value={"all"}>{t('accountSetup_All')}</MenuItem>
                {years?.map((obj, index) => <MenuItem  key={`year-${index}`} value={obj}>{obj}</MenuItem>)}
              </Select>
            </FormControl>
            {localData.token.role === 'vendor'?<Button
              variant="contained"
              size="small"
              sx={{ width: "150px" }}
              className="date-picker-btn"
              onClick={() => {
                isEdit = false
                setIsEdit(isEdit)
                editHoliday = null
                setEditHoliday(editHoliday)
                setOpenAddHoliday(true);
              }}
            >{t("manageStore_addHoliday")}</Button>: null}
          </div>
        </Boxed>
        {allHolidays?.filter((holiday) => futureYear == "all" || new Date(holiday.date).getFullYear() == futureYear).length == 0 ?
          <NoDataFound text={t("manageStore_noHolidayFound")} /> :
          <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('manageStore_holidayName')}</TableCell>
                <TableCell>{t('manageStore_holidayDate')}</TableCell>
                {/* <TableCell align="center">{t('manageStore_ActiveInactive')}</TableCell> */}

                {/* {localData.token.role === 'vendor'?<TableCell>{t('manageStore_Stores')}</TableCell>: null} */}
                
                <TableCell align="center">{t('manageStore_Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allHolidays
                ?.filter((holiday) => futureYear == "all" || new Date(holiday.date).getFullYear() == futureYear)
                .map((holiday, index) => (
                <TableRow key={`holiday-${index}`} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
                  <TableCell component="th" scope="row">{holiday.holidayName}</TableCell>
                  <TableCell >{dates(holiday.date)}</TableCell>

                  {/* {localData.token.role === 'vendor'?<TableCell
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => {
                      showEditStoreIds = holiday.storeIdes.split(',');
                      setShowEditStoreIds(showEditStoreIds)
                      editHoliday = holiday
                      setEditHoliday(editHoliday)
                      setShoeStoreList(true)
                    }}
                  > {holiday?.totalStores} {holiday?.totalStores > 1?t('manageStore_Stores'):t('manageStore_Store')} </TableCell>: null} */}

                  {/* <TableCell align="center">
                    <Switch
                      checked={holiday.status == 'active'}
                      onClick={() => {setOpenInactiveHoliday(true);}} />
                 </TableCell> */}
                  <TableCell align="center">
                    <img
                      src={editIcon} alt="edit"
                      className="icon16 mx-2 pointer"
                      onClick={() => {
                        isEdit = true
                        setIsEdit(isEdit)
                        editHoliday = holiday
                        setEditHoliday(editHoliday)
                        if (localData.token.role === 'vendor') {
                          showEditStoreIds = holiday.storeIdes.split(',');
                          setShowEditStoreIds(showEditStoreIds)
                          setShowEditHoliday(true)
                        } else {
                          setOpenAddHoliday(true);
                        } 
                      }}
                    />
                    <img
                      src={deleteIcon}
                      alt="delete"
                      className="icon16 mx-2 pointer"
                      onClick={() => {
                        selectedHoliday = holiday
                        setSelectedHoliday(selectedHoliday);
                        setOpenDeleteHoliday(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
      </div>

      {openAddHoliday?<AddHoliday
        updateValue={updateValue}
        isOpen={openAddHoliday}
        editHoliday={editHoliday}
        storeId={editHoliday?.storeId}
        holidayData={editHoliday}
        handleClose={() => {
          setOpenAddHoliday(false);
          setUpdateValue({});
          getWorkingHoliday();
        }}
        isEdit={isEdit}
      />: null}

      {showEditHoliday?<EditHoliday
        updateValue={updateValue}
        isOpen={showEditHoliday}
        editHoliday={editHoliday}
        storeId={editHoliday?.storeId}
        holidayData={editHoliday}
        selectedStoreIds={showEditStoreIds.map((id) => parseInt(id))}
        handleClose={() => {
          setShowEditHoliday(false);
          setUpdateValue({});
          getWorkingHoliday();
        }}
        isEdit={isEdit}      
      /> : null}

      {openInactiveHoliday?<InactiveAllHolidays
        isOpen={openInactiveHoliday} 
        handleClose={() => {
        setOpenInactiveHoliday(false);
      }} />: null}

      {openDeleteHoliday?<ConfirmModel
        isOpen={true}
        handleClose={() => setOpenDeleteHoliday(false)}
        confirm={deleteHoliday}
        title={t("manageStore_deleteHoliday")}
        message={t("manageStore_wantToDelete")}
      />: null}
      
      {shoeStoreList?<StoreList
        holiday={editHoliday}
        stores={storeOptions}
        storeId={showEditStoreIds}
        handleClose={() => setShoeStoreList(false)}
      />: null}
    </div>
  );
};

export default ManageHolidays;
