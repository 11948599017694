import { Upload } from "@mui/icons-material";
import modalClose from "../assets/images/structure/close-black.svg"
import defaultImage from "../assets/images/structure/default.jpeg"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import { API_URL } from "../BaseUrl";
import { useDispatch } from "react-redux";
import globalRequest from "../global-modules/globalRequest";
import { API } from "../Enum";
import { changeSnackbar } from "../redux/reducers/snackbar";

const CategoryImageModal = ({ isOpen = false, handleClose, uploadImageData, handleSuccess, storeId }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  let [imageData, setImageData] = useState({
		file: "",
		imagePreviewUrl: '',
	});

  const editImageChangeHandler = (e) => {
		if (e.target.files[0]) {
			imageData = {
				file: e.target.files[0],
				imagePreviewUrl: URL.createObjectURL(e.target.files[0]),
			};
			setImageData(imageData);
		} else {
			imageData = { file: '', imagePreviewUrl: '' };
			setImageData(imageData);
		}
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
		formData.append("image", imageData.file);
		formData.append("categoryId", uploadImageData.id);
    formData.append("storeId", storeId);
		const response = await globalRequest('post', API.CATEGORY_MANAGEMENT.CATEGORY_IMAGE_UPLOAD, formData, {}, true, true);
    if (response.data.ack === 1) {
      handleSuccess()
    } else {
      if (response.data.msg !== '') {
        dispatch(changeSnackbar({
          message: response.data.msg,
          state: 'error',
          isOpen: true,
        }));
      } else {
        response.data.errMsg.forEach((element) => {
          for (const [key, value] of Object.entries(element)) {
            dispatch(changeSnackbar({
              message: value,
              state: 'error',
              isOpen: true,
            }));
          }
        });
      }
    }
  };

  useEffect(() => {
    if (uploadImageData?.image && uploadImageData?.image !== '' && uploadImageData?.image !== '/') {
      imageData = {
        file: '',
        imagePreviewUrl: API_URL + uploadImageData?.image,
      };
      setImageData(imageData);
    } else {
      imageData = { file: '', imagePreviewUrl: '' };
      setImageData(imageData);
    }
  }, [uploadImageData]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
            <h4 className="h4" style={{
              marginBottom: 24,
              maxWidth: '300px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textWrap: 'nowrap'
              }}>{t('Category image')} {uploadImageData.name}</h4>
            <div className="category-img-row">
                <div className="category-upload">
                    <img src={(imageData.imagePreviewUrl && imageData.imagePreviewUrl !== '') ?imageData.imagePreviewUrl:defaultImage} alt="..." className="category-img" />
                </div>
                <Button type="button" size="medium" variant="outlined" color="primary" className="upload-btn">
                    <Upload />
                    {t('Upload Image')}
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      onChange={(e) => editImageChangeHandler(e)}
                    />
                </Button>
                {/* {imageData.file?
                  <Button type="button" size="medium" variant="text" color="secondary"
                    onClick={() => {
                      imageData = { file: '', imagePreviewUrl: '' };
                      setImageData(imageData);
                    }}
                  >Remove</Button>: null} */}
            </div>
            <div className="category-img-row" style={{
              marginTop: '24px'
            }}>
              <Button variant="contained" size="medius" color="info" type='button' onClick={handleSubmit}>Save</Button>
            </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CategoryImageModal;