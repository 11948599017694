export default {
  business_email: "Please enter your business email address.",
  valid_email: "Please enter valid email",
  otp_send: "Otp sent successfully",
  mobile_no_8_digit: "Mobile number must be of 8 digits",
  business_type: "Please select the business type.",
  business_name: "Please enter business name",
  zone_no: "Please enter zone no ",
  street_no: "Please enter street no",
  building_no: "Please enter building no",
  business_logo: "Please upload your business logo",
  company_registration: "Please upload your Company Registration ID Documents",
  cannot_upload_more_than_3: "You cannot upload more than 3 documents",
  owner_name: "Please enter your owner name.",
  owner_email: "Please enter owner's email.",
  term_and_condition: "Please accept terms and conditions",
  verify_captcha: "Please verify your captcha",
  mobile_email: "Please enter email or mobile number",
  password: "Please enter your password",
  mobile_email_to_send_otp: "Please enter email or mobile number to send OTP.",
  password_8_digit: "Password must be at least 8 characters",
  enter_confirm_password: "Please enter confirm password",
  password_confirm_password_does_not_match: "Password and confirm password does not match",
  enter_otp: "Please enter OTP",
  endTime: "End time should not be less than Start time",
  beneficiaryName: "Please enter beneficiary name",
  account_no: "Please enter account number",
  iban: "Please enter IBAN ",
  swiftCode: "Please enter swift code",
  holidayName: "Please enter holiday name",
  holidayDate: "Please enter holiday date",
  country: "Please enter country",
  productDescription: "please enter product description - English.",
  productDescriptionArabic: "please enter product description - Arabic.",
  productName: "Please enter product name - english",
  productNameArabic: "Please enter product name - arabic",
  itemCode: "Please enter item code",
  category: "Please select category",
  subCategory: "Please select sub-category",
  subSubCategory: "Please select sub-sub-category",
  productUnit: "Please select unit",
  productQuantity: "Please enter product quantity",
  productPrice: "Please enter price ",
  itemCodeDup: "Product itemcode already exists",
  featureImage: "Please select the featured image.",
  descriptionLength: "The product description will have a limit of min 200 or maximum 1000 characters",
  accept_file_doc: "Please select only JPEG,JPG,PNG,DOC & PDF file",
  accept_file_logo: "Please select only JPEG,JPG & PNG files",
  mb5_file: "Please choose less than 5 MB of file",
};
