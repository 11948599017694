/**
 * @file This file contains all the English language translations
 * English Language
 * @type {Object}
 * @returns {Object} language - The language object
 */
import common from "./en/common.json";
import set_password from "./en/set_password.json";
import payout from "./en/payout.json";
import notification from "./en/notification.json";
import change_password from "./en/change_password.json";
import account_setup from "./en/account_setup.json";
import dashboardPage from "./en/dashboardPage.json";
import header_footer from "./en/header_footer.json";
import home from "./en/home.json";
import sidebar from "./en/sidebar.json";
import manageProducts from "./en/manageProducts.json";
import manageModifier from "./en/manageModifier.json";
import manageStore from "./en/manageStore.json";
import manageCategory from "./en/manageCategory.json";
import order from "./en/order.json";
import signup from "./en/signup.json";
import login from "./en/login.json";
import forgot from "./en/forgot.json";
import holiday from "./en/holiday.json";
import termsCondition from "./en/termsCondition.json";
import faq from "./en/faq.json";
import static_pages from "./en/static_pages.json";
import myOffer from "./en/myOffer.json";
import ManageUser from "./en/manageUsers.json";

const en = () => {
  const language = {
    ...common,
    ...set_password,
    ...payout,
    ...notification,
    ...change_password,
    ...account_setup,
    ...dashboardPage,
    ...header_footer,
    ...home,
    ...sidebar,
    ...manageProducts,
    ...manageModifier,
    ...manageStore,
    ...manageCategory,
    ...order,
    ...signup,
    ...login,
    ...forgot,
    ...holiday,
    ...termsCondition,
    ...faq,
    ...static_pages,
    ...myOffer,
    ...ManageUser,
  };
  return language;
};

export default en;
