/** @format */
import { useEffect, useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./DashboardPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { API } from "../../Enum";
import globalRequest from "../../global-modules/globalRequest";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { MONTHS, getLastTenYears } from "../../Helpers/Helpers";
import Revenue from "./Revenue";
import { useTranslation } from "react-i18next";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import { currentLocalData } from "../../redux/reducers/localData";
import { getDataByKeyLocale } from "../../Helpers/Helpers";

const DashboardPage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tokenData = useSelector(currentLocalData);
  let [topTenProducts, setTopTenProducts] = useState([]);
  let [graphData, setGraphData] = useState([]);
  let [currentDate, setCurrentDate] = useState(new Date().getFullYear());
  let [hasData, setHasData] = useState(false);
  let [storeOptions, setStoreOptions] = useState([]);
  let [selectedStore, setSelectedStore] = useState('');

  const getTopProducts = () => {
    dispatch(changeLoader(true));
    const url = API.GET_TOP_PRODUCTS;
    globalRequest("get", url, {}, {}, true, true)
    .then(({ status, data }) => {
      if (status === 200) {
        if (data.ack === 1) {
          setTopTenProducts(data.data);
          dispatch(changeLoader(false));
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data.msg,
              state: "error",
            })
          );
          dispatch(changeLoader(false));
        }
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("somethingWentWrong"),
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    });
  };

  const getGraphData = () => {
    dispatch(changeLoader(true));
    globalRequest( "post", API.GET_GRAPH_DATA + '/' + selectedStore, {
      year: currentDate,
      storeId: selectedStore
    }, {}, true, true )
    .then(({ status, data }) => {
      if (status === 200) {
        if (data.ack === 1) {
          hasData = (data?.data?.length > 0)?true:false;
          setHasData(hasData);
          graphData = [];
          setGraphData(graphData);
          graphData = MONTHS.map((item, index) => {
            const monthTotal = data.data.find((item2) => parseInt(item2.month) === index + 1);
            return {
              label: item,
              value:  parseFloat(monthTotal?.amount) || 0,
            };
          });
          setGraphData(graphData);
          dispatch(changeLoader(false));
        } else {
          hasData = false;
          setHasData(hasData);
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: data.msg,
              state: "error",
            })
          );
          dispatch(changeLoader(false));
        }
      } else {
        hasData = false;
        setHasData(hasData);
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      }
    }).catch((err) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t("somethingWentWrong"),
          state: "error",
        })
      );
      dispatch(changeLoader(false));
    });
  };

  const sortBy = (key, type = 'asc') => {
    if (key === 'itemCode') {
      if (type === 'asc') {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (b.itemCode > a.itemCode) {return -1;}
        })
      } else {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (a.itemCode < b.itemCode) {return 1;} else {return -1;}
        })
      }
    }
    if (key === 'title') {
      if (type === 'asc') {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (b.title > a.title) {return -1;}
        })
      } else {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (a.title < b.title) {return 1;} else {return -1;}
        })
      }
    }
    if (key === 'category') {
      if (type === 'asc') {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (b.catgeoryName > a.catgeoryName) {return -1;}
        })
      } else {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (a.catgeoryName < b.catgeoryName) {return 1;} else {return -1;}
        })
      }
    }
    if (key === 'orderCount') {
      if (type === 'asc') {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (b.orderCount > a.orderCount) {return -1;}
        })
      } else {
        topTenProducts = [...topTenProducts].sort((a, b) => {
          if (a.orderCount < b.orderCount) {return 1;} else {return -1;}
        })
      }
    }
    setTopTenProducts(topTenProducts)
  }

  const getStoreOptions = async () => {
    dispatch(changeLoader(true));
    let storeOptionArray = [];
    let url = API.MANAGE_USERS.GET_STORES_FOR_DROPDOWN;
    url += "?brandId=" + tokenData.token.store.parent_id;
    const response = await globalRequest('get', url, {}, {}, true, true);
    storeOptions =[];
    if (response.data.ack === 1) {
      storeOptionArray = response.data.stores
        .filter((store) => (store.is_approved === null || store.is_approved === true)).map((store) => {
        return {
          label: getDataByKeyLocale(store.stores_locales, 'business_name'),
          value: store.id,
          secondLabel: store.business_address
        }
      });
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        isOpen: true,
        state: "error",
      }));
    }
    storeOptions = [...storeOptions, ...storeOptionArray];
    setStoreOptions(storeOptions);
    selectedStore = '';
    if (tokenData.token.role === 'vendor_users') {
      selectedStore = storeOptions.find((store) => store.value === tokenData.token.store.id)?.value;
    }
    setSelectedStore(selectedStore);
    getTopProducts();
    getGraphData();
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    getStoreOptions();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <h3 className="h3">{t('Dashboard')}</h3>
      <div className="graph-box">
        <div className="graph-top-flex">
          <h6 className="h6">{t('dashboardPage_RevenueComparison')}</h6>
          <div>
          <FormControl className="payout-status width200" >
            <Select
              id="demo-simple-select"
              value={currentDate}
              onChange={(e) => {
                currentDate = e.target.value;
                setCurrentDate(currentDate);
                getGraphData();
              }}
              size="small"
              className="bg-white"
              displayEmpty
              inputProps={{"aria-label": "Without label",}}
              MenuProps={{ disableScrollLock: false }}
              startAdornment={<InputAdornment position="start">{t('dashboardPage_Year')}: </InputAdornment>}
            >
              {getLastTenYears()?.length > 0
                ? getLastTenYears()?.map((year, index) => <MenuItem key={`year-${index}`} value={year}>{year}</MenuItem>)
                : null}
            </Select>
          </FormControl>

          {tokenData.token.role === 'vendor'?
            <FormControl className="payout-status width200" fullWidth style={{ marginLeft: '20px' }}>
            <Select
              id="demo-simple-select"
              value={selectedStore}
              onChange={(e) => {
                selectedStore = e.target.value;
                setSelectedStore(selectedStore);
                getTopProducts();
                getGraphData();
              }}
              size="small"
              className="bg-white"
              displayEmpty
              inputProps={{"aria-label": "Without label",}}
              MenuProps={{ disableScrollLock: false }}
              startAdornment={<InputAdornment position="start">{t('Store')}: </InputAdornment>}
            >
              <MenuItem key={`store-all`} value={''}>All </MenuItem>
              {storeOptions.map((store, index) =>
                <MenuItem key={`store-${index}`} value={store.value}>{store.label}</MenuItem>
              )}
            </Select>
          </FormControl>: null}

          </div>
        </div>
        {hasData ? (
          graphData.length > 0 ? <Revenue barChartData={graphData} />: null
        ) : <NoDataFound text={t("dashboardPage_NoDataFound")} /> }
      </div>

      {tokenData.token.role === 'vendor' && topTenProducts?.length > 0?<>
        <h3 className="h3">{t('dashboardPage_Top10Products')}</h3>
        <div className="table-block">
          <div className="table-wrapper">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="short-div">{t("dashboardPage_S_No")}</div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("dashboardPage_itemCode")}
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => sortBy("itemCode", "asc")} />
                          <ArrowDropDownIcon onClick={() => sortBy("itemCode", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("dashboardPage_productTitle")}
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => sortBy("title", "asc")} />
                          <ArrowDropDownIcon onClick={() => sortBy("title", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("dashboardPage_category")}
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => sortBy("category", "asc")} />
                          <ArrowDropDownIcon onClick={() => sortBy("category", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <div className="short-div">
                        {t("dashboardPage_subCategory")}
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => sortBy("categories1", "asc")} />
                          <ArrowDropDownIcon onClick={() => sortBy("categories1", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="short-div">
                        {t("dashboardPage_subSubCategory")}
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => sortBy("categories2", "asc")} />
                          <ArrowDropDownIcon onClick={() => sortBy("categories2", "desc")} />
                        </div>
                      </div>
                    </TableCell> */}
                    <TableCell>
                      <div className="short-div">
                        {t("dashboardPage_quantityOrdered")}
                        <div className="short-box">
                          <ArrowDropUpIcon onClick={() => sortBy("orderCount", "asc")} />
                          <ArrowDropDownIcon onClick={() => sortBy("orderCount", "desc")} />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topTenProducts?.length > 0
                    ? topTenProducts?.map((product, index) => (
                        <TableRow key={`prod-${index}`}>
                          <TableCell component="th" scope="row">{index + 1}</TableCell>
                          <TableCell>#{product?.itemCode}</TableCell>
                          <TableCell>{product?.title}</TableCell>
                          <TableCell>{product?.catgeoryName}</TableCell>
                          {/* <TableCell>{product?.categories1?.name ? product?.categories1?.category_locales[0].name : "-"}</TableCell>
                          <TableCell>{product?.categories2?.name ? product?.categories2?.category_locales[0].name : "-"}</TableCell> */}
                          <TableCell>{product?.orders_count}</TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </>: null}
    </div>
  );
};

export default DashboardPage;
