import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Button } from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import OfferTable from "./OfferTable";
import { useDispatch, useSelector } from "react-redux";
import { API, PAGES } from "../../Enum";
import { changePage } from "../../redux/reducers/page";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Boxed>{children}</Boxed>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewProduct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-mid-wrapper pb-0">
      <div className="order-listing-wrapper">
        <Boxed className="top-flex-row" mb={"10px"}>
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3">{t("myOffers_MyOffers")}</h3>
          </div>
          <Boxed
            display={"flex"}
            alignItems={"center"}
            className="filter-search"
          >
            <Button variant="contained" size="small" sx={{ width: "150px" }} 
            onClick={() => {
                        dispatch(changePage(PAGES.ADD_OFFER));
                      }}>
              {t("myOffers_AddNew")}
            </Button>
          </Boxed>
        </Boxed>
        <div className="">
          <div className="tabs-wrap">
            <Tabs
              value={value}
              onChange={handleChange}
              className="border-bottom" variant="scrollable"
              scrollButtons="auto" aria-label="scrollable auto tabs example"
            >
              <Tab label={t("myOffer_AwaitingApproval")} {...a11yProps(0)} />
              <Tab label={t("myOffer_Active_Upcoming")} {...a11yProps(1)} />
              <Tab label={t("myOffer_Past")} {...a11yProps(2)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <OfferTable />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            <OfferTable />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
            <OfferTable />
            </CustomTabPanel>
          </div>
        </div>
      </div>      
    </div>
  );
};

export default ViewProduct;
