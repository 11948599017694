import { createSlice } from "@reduxjs/toolkit";

export const earningPayoutData = createSlice({
  name: "earningPayoutData",
  initialState: {
    value: "null",
  },
  reducers: {
    changePayoutsId: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePayoutsId } = earningPayoutData.actions;
export const currentPayoutId = (state) => state.earningAndPayoutData.value;
export default earningPayoutData.reducer;
