import "./EarningsPayouts.scss";
import React, { useState, useEffect } from "react";
import { Boxed } from "../../Styles-Elements/Box";
import PropTypes from "prop-types";
import {
  Button, Tabs, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material";
import { currentPayoutId } from "../../redux/reducers/earningAndPayoutData";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { useTranslation } from "react-i18next";
import { formatPrice } from ".././../Helpers/Helpers";
import { changePage } from "../../redux/reducers/page";
import { PAGES, ORDER_STATUS, API, IMAGE_URL } from "../../Enum";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import globalRequest from "../../global-modules/globalRequest";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import infoGray from "../../assets/images/structure/info-gray.png"
import standardTime from "../../global-modules/standardTime";
import arrowLeftBlack from "../../assets/images/structure/arrow-left-black.svg";
const monthArr = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December",
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PayoutDetail = () => {

  const dispatch = useDispatch();
  const currentOrderId = useSelector(currentPayoutId);
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [orderdetails, setOrderDetails] = useState([]);
  const [salesPeriod, setSalesPeriod] = useState([]);
  const [expectedPayoutDate, setExpectedpayoutDate] = useState("");
  const [actualPayoutDate, setActualPayoutDate] = useState("");
  const [salesPeriodDate, setSalesPeriodDate] = useState("");
  const [dataArrived, setDataArrived] = useState(false);
  const [isCenceldataFounded, setIsCenceldataFounded] = useState(false);
  const [storeData, setStoreData] = useState(null);
  let [timeZone, setTimeZone] = useState("");

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getInvoiceDetails = async (order_id) => {
    globalRequest( "get", API.GET_ORDER_INVOICE(order_id), {}, {}).then(({status, data}) => {
      if (status === 200) {
        window.open(IMAGE_URL(data?.invoice), '_blank')
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t('somethingWentWrong'),
            state: "error",
          })
        );
      }
    }).catch((e) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t('somethingWentWrong'),
          state: "error",
        })
      );
    });
  }

  const getTimeZone = () => {
    const url = API.GET_TIMEZONE;
    globalRequest("get", url, false, {}, true, true)
      .then(({data, status}) => {
        timeZone = data.time_zone
        setTimeZone(timeZone)
      }).catch((e) => {
        console.error('error', e);
      });
  }

  const getHumanDate = (dateTime) => {
    if (timeZone !== "") {
      let newDateTime = new Date(dateTime);
      newDateTime = newDateTime.toLocaleString("en-US", {
        timeZone: timeZone,
      });
      const finalDate = new Date(newDateTime); 
      return `${finalDate.getDate()} ${monthArr[finalDate.getMonth()]} ${finalDate.getFullYear()}`
    }
  }

  const sortTheOrders = (value, type) => {
    let currentData = orderdetails;
    if (type == "ASC") {
      currentData.sort((a, b) =>
        a[value] > b[value] ? 1 : b[value] > a[value] ? -1 : 0
      );
    } else if (type == "DESC") {
      currentData.sort((a, b) => b[value] - a[value]);
    }
    let newArr = [...currentData];
    setOrderDetails(newArr);
  };

  const downloadCSV = async () => {
    dispatch(changeLoader(true));
    let url = API.GET_ORDER_PAYOUT_DETAILS_DOWNLOAD(currentOrderId);
    url += `?type=${tabValue === 0 ? "completed" : "cancelled"}`;
    globalRequest("get", url, {}, {}, true, true).then(({status, data}) => {
      if (data.ack === 1) {
        const link = document.createElement("a");
        link.setAttribute("href", IMAGE_URL(data?.path))
        link.setAttribute("download", "payouts.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: "error",
          })
        );
      }
    }).catch((e) => {
      dispatch(
        changeSnackbar({
          isOpen: true,
          message: t('somethingWentWrong'),
          state: "error",
        })
      );
    });
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    dispatch(changeLoader(true));
    const url = API.GET_ORDER_PAYOUT_DETAILS(currentOrderId);
    setDataArrived(false);
    setIsCenceldataFounded(false);
    globalRequest("get", url, false, {}, true, true).then((response) => {
      const data = response?.data;
      if (data.ack == 1) {
        setStoreData(data.payout.store);
        setOrderDetails(data.payout.payouts_items);
        const cancelOunt = data.payout.payouts_items.map((obj) => obj.type == ORDER_STATUS.CANCELLED);
        setIsCenceldataFounded(cancelOunt.length > 0 ? true : false);
        setSalesPeriod(data.payout);
        setDataArrived(true);
        dispatch(changeLoader(false));
      }
    }).catch((e) => {
      dispatch(changeLoader(false));
      dispatch(
        changeSnackbar({
          state: "error",
          isOpen: true,
          message: t("somethingWentWrong"),
        })
      );
    });
  }, []);

  useEffect(() => {
    if (dataArrived) {
      const date1 = getHumanDate(salesPeriod?.expected_payout_date);
      const date2 = getHumanDate(salesPeriod?.period_start_date);
      const date3 = getHumanDate(salesPeriod?.period_end_date);
      let date4;
      if (salesPeriod.actual_payout_date) {
        date4 = getHumanDate(salesPeriod?.actual_payout_date);
      }
      setExpectedpayoutDate(date1);
      setSalesPeriodDate(`${date2} - ${date3}`);
      setActualPayoutDate(date4);
    }
  }, [salesPeriod]);

  useEffect(() => {
    getTimeZone();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="order-listing-wrapper">
        <Boxed className="top-flex-row" mb={"20px"}>
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <img
              src={arrowLeftBlack} alt=""
              className="rotate180"
              onClick={() => { dispatch(changePage(PAGES.EARNINGS_PAYOUTS)); }}
            />
            <h3 className="h3" style={{ marginRight: "0px" }}>{t("SalesPeriod")}</h3>
          </div>
          <div className="store-detail-head">
          <Button
            variant="contained" size="medium" type="button"
            sx={{ marginLeft: "16px", minWidth: "140px" }}
            onClick={downloadCSV}
          >{t("exportCSV")}</Button>
          </div>
        </Boxed>
        <div className="">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("SalesPeriod")}</TableCell>
                  <TableCell>{t("Store")}</TableCell>
                  <TableCell>{t("ExpectedPayoutDate")}</TableCell>
                  <TableCell>{t("ActualPayoutDate")}</TableCell>
                  <TableCell>{t("SalesAmount")}</TableCell>
                  <TableCell>{t("ExpectedPayoutAmount")}</TableCell>
                  <TableCell>{t("ActualPayoutAmount")}</TableCell>
                  <TableCell>{t("PayoutStatus")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell><span dir="ltr">{salesPeriodDate}</span></TableCell>
                  <TableCell>
                    <span dir="ltr" style={{ fontWeight: "bold" }}>{storeData?.stores_locales[0].business_name}</span> <br/>
                    <span dir="ltr">{storeData?.business_address}</span>
                  </TableCell>
                  <TableCell><span dir="ltr">{expectedPayoutDate}</span></TableCell>
                  <TableCell>{actualPayoutDate ? actualPayoutDate : "-"}</TableCell>
                  <TableCell>QAR {formatPrice(salesPeriod.sales_amount)}</TableCell>
                  <TableCell>QAR {formatPrice(salesPeriod.expected_payout_amount)}</TableCell>
                  <TableCell>QAR {formatPrice(salesPeriod.actual_payout_amount)}</TableCell>
                  <TableCell><span className="chips-fill gray"> {salesPeriod.status}</span></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className="tabs-wrap" style={{ marginTop: "20px" }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              className="border-bottom"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label={t("CompletedOrder")} {...a11yProps(0)} />
              <Tab label={t("CancelledOrder")} {...a11yProps(1)} />
              <span className="total-order-text">
                {t("TotalOrders")} - {
                  tabValue === 0?
                    orderdetails.filter((obj) => obj.type == ORDER_STATUS.COMPLETED).length:
                    orderdetails.filter((obj) => obj.type == ORDER_STATUS.CANCELLED).length 
                }
              </span>
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              { orderdetails.filter((obj) => obj.type == ORDER_STATUS.COMPLETED).length === 0?
                <NoDataFound text={t("NoDataFound")} />:
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="short-div">
                            {t("OrderID")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("orderId", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("orderId", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>

                        <TableCell>
                          <div className="short-div">
                            {t("OrderTotal")}
                            <div className="info-box">
                              <img src={infoGray} className="info-icon" alt="infoGray" />
                              <div className="info-content">
                                <p className="p3">{t("OrderTotalInfo")}</p>
                              </div>
                            </div>
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("amount", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("amount", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("DeliveryFee")}
                            <div className="info-box">
                              <img src={infoGray} className="info-icon" alt="infoGray" />
                              <div className="info-content">
                                <p className="p3">{t("DeliveryFeeInfo")}</p>
                              </div>
                            </div>
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("amount", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("amount", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("PlatformFee")}
                            <div className="info-box">
                              <img src={infoGray} className="info-icon" alt="infoGray" />
                              <div className="info-content">
                                <p className="p3">{t("PlatformAmountInfo")}</p>
                              </div>
                            </div>
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("platform_fee", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("platform_fee", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("ExpectedPayoutAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("expected_payout_amount", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("expected_payout_amount", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>

                        {/* <TableCell>
                          <div className="short-div">
                            {t("ActualPayoutAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("actual_payout_amount", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("actual_payout_amount", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell> */}

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderdetails.map((obj, index) => {
                        if (obj.type == ORDER_STATUS.COMPLETED) {
                          let mainamcount =
                            obj?.amount -
                            (
                              obj?.amount -
                              (Number(obj?.expected_payout_amount) + Number(obj?.platform_fee))
                            ).toFixed(2);

                          return (
                            <TableRow key={"payout" + index}>
                              <TableCell
                                onClick={() => { getInvoiceDetails(obj.orderId); }}
                                style={{ cursor: "pointer" }}
                              >{obj.order_id}</TableCell>
                              <TableCell>QAR {formatPrice(mainamcount)}</TableCell>
                              <TableCell> {(obj?.amount - mainamcount) > 0?'QAR': ''} {formatPrice(obj?.amount - mainamcount)}</TableCell>
                              <TableCell>
                              QAR {formatPrice(obj.platform_fee)}
                                ({((obj?.platform_fee / mainamcount) * 100).toFixed(2)}%)
                              </TableCell>
                              <TableCell>
                              QAR {formatPrice(obj.expected_payout_amount)}
                              </TableCell>
                              {/* <TableCell>
                                {formatPrice(obj.actual_payout_amount)}
                              </TableCell> */}
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {orderdetails.filter((obj) => obj.type == ORDER_STATUS.CANCELLED).length === 0?
                <NoDataFound text={t("NoDataFound")} />:
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="short-div">
                            {t("OrderID")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("OrderID", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("OrderID", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("CancelledDate")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("CancelledDate", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("CancelledDate", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("SalesAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("SalesAmount", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("SalesAmount", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="short-div">
                            {t("PayoutAmount")}
                            <div className="short-box">
                              <ArrowDropUpIcon
                                onClick={() => {
                                  sortTheOrders("ExpectedPayoutAmount", "ASC");
                                }}
                              />
                              <ArrowDropDownIcon
                                onClick={() => {
                                  sortTheOrders("ExpectedPayoutAmount", "DESC");
                                }}
                              />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderdetails.map((obj, index) => {
                        if (obj.type == ORDER_STATUS.CANCELLED) {
                          return (
                            <TableRow key={index}>
                              <TableCell>{obj.order_id}</TableCell>
                              <TableCell>{standardTime(obj.cancellation_date)}</TableCell>
                              <TableCell>QAR {obj.amount}</TableCell>
                              <TableCell>QAR {obj.actual_payout_amount}</TableCell>
                            </TableRow>
                          );
                        }
                      })}
                      {!isCenceldataFounded && "No Data Found"}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutDetail;
