/** @format */

import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";

const CompleteOrder = ({ isOpen = false, handleClose,confirm }) => {
  const {t} = useTranslation();
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} className="main-modal theme-dialog">
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "8px" }}>{t('order_CompleteOrder')}</h2>
          <p className="p3" style={{ marginBottom: "16px", fontSize: "14px" }}>{t('order_CompleteOrder_line')}</p>
          <Boxed textAlign="center" display={"flex"} justifyContent={"flex-end"}>
            <Button type="button" size="medium" variant="text" color="primary" onClick={handleClose}>{t('order_No')}</Button>
            <Button onClick={confirm} type="button" size="medium" variant="text" color="error">{t('order_Yes')}</Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompleteOrder;
