import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { API, PAGES } from "../Enum";
import { changePage, currentPage } from "../redux/reducers/page";
import { currentLocalData, changeLocalData } from "../redux/reducers/localData";
import { changeNewOrder } from "../redux/reducers/myNewOrder";
import { changeNotification } from "../redux/reducers/notification";
import { STORAGE } from "../Enum";
import { decodeToken } from "react-jwt";
import { changeSnackbar } from "../redux/reducers/snackbar";
import StoreDetail from "./StoreDetail/StoreDetail";
import Header2 from "./layout/header2/Header2";
import Sidebar from "./layout/sidebar/Sidebar";
import ViewProduct from "./ManageProducts/ViewProduct/ViewProduct";
import AddProduct from "./ManageProducts/AddProduct/AddProduct";
import MyOrders from "./MyOrders/MyOrders";
import Notification from "./Notification/Notification";
import EditProduct from "./ManageProducts/AddProduct/EditProduct";
import EditUOM from "./ManageProducts/AddProduct/EditUOM";
import MyStores from "./MyStores/MyStores";
import MyStoreDetail from "./MyStores/MyStoreDetail";
import EarningsPayouts from "./EarningsPayouts/EarningsPayouts";
import PayoutsDetail from "./EarningsPayouts/PayoutsDetail";
import ManageTiming from "./ManageTiming/ManageTiming";
import ManageTimingDetail from "./ManageTiming/ManageTimingDetail";
import ManageTimingEdit from "./ManageTiming/ManageTimingEdit";
import ManageUser from "./ManageUser/ManageUser";
import ManageUserDetail from "./ManageUser/ManageUserDetail";
import ManageHolidays from "./ManageHolidays/ManageHolidays";
import ManagePayouts from "./ManagePayouts/ManagePayouts";
import ManagePayoutsDetail from "./ManagePayouts/ManagePayoutsDetail";
import ManageWorkingHours from "./account-setup/ManageWorkingHours/ManageWorkingHours";
import ManageHoliday from "./account-setup/ManageHoliday/ManageHoliday";
import PayoutDetails from "./account-setup/PayoutDetails/PayoutDetails";
import globalRequest from "../global-modules/globalRequest";
import DashboardPage from "./DashboardPage/DashboardPage";
import ModifierView from "./ManageModifier/ModifierView/ModifierView";
import AddAddOn from "./ManageModifier/AddAddOn/AddAddOn";
import AddNewStore from "./MyStores/AddNewStore";
import ManageCategories from "./ManageCategories/ManageCategories";
import MyOffer from "./MyOffer/MyOffer";
import AddOffer from "./MyOffer/AddOffer/AddOffer";
import addDeleteGetLocalStorage from "../global-modules/addDeleteGetLocalStorage";
import socket from "../socket";
const MainPage = () => {

  const page = useSelector(currentPage);
  const localData = useSelector(currentLocalData);
  const tokenData = localData?.token;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [regStep, setRegStep] = useState({});

  const getRegistrationStep = () => {
    globalRequest("get", API.GET_REGISTRATION_STEP, {}, {}, true, true)
      .then((res) => {
        let data = res?.data;
        if (data?.ack === 1) {
          setRegStep(data?.user);
        }
      })
      .catch((err) => {});
  };

  const timeDifference = (date1, date2) => {
    return Math.floor((date1.getTime() - date2.getTime()) / 1000);
  }

  const refreshAuthToken = useCallback(async () => {
    if (addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single")) {
      const tokenLocal = addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single");
      const decoded = decodeToken(tokenLocal)
      const timeLeft = timeDifference(new Date(decoded.exp * 1000), new Date())
      if (timeLeft < 20) {
        globalRequest("GET", API.REFRESH_TOKEN, {}, {}, true, true)
          .then((res) => {
            if (res?.data?.ack === 1) {
              addDeleteGetLocalStorage(STORAGE.TOKEN, res?.data?.data, "add", "single");
              dispatch(changeLocalData());
            } else {
              navigate("/");
            }
          })
          .catch((err) => {
            dispatch(changeSnackbar({
              isOpen: true,
              message: "Something went wrong",
              state: "error",
            }));
          });
      }
    }
  })

  useEffect(() => {
    getRegistrationStep();
  }, []);

  useEffect(() => {
    if (Object.keys(regStep).length !== 0) {
      if (
        regStep?.registration_step1 &&
        regStep?.registration_step2 &&
        regStep?.registration_step3
      ) {
        dispatch(changePage(PAGES.DASHBOARD));
      } else {
        if (
          !regStep?.registration_step1 &&
          !regStep?.registration_step2 &&
          !regStep?.registration_step3
        ) {
          dispatch(changePage(PAGES.MANAGE_WORKING_HOUR));
        } else if (
          regStep?.registration_step1 &&
          !regStep?.registration_step2 &&
          !regStep?.registration_step3
        ) {
          dispatch(changePage(PAGES.MANAGE_HOLIDAY));
        } else if (
          regStep?.registration_step1 &&
          regStep?.registration_step2 &&
          !regStep?.registration_step3
        ) {
          dispatch(changePage(PAGES.PAYOUT_DETAIL));
        }
      }
    }
  }, [regStep]);

  useEffect(() => {
    if (tokenData === null) {
      navigate("/");
    }
  }, []);

  useEffect(()=>{
    let url = API.GET_ORDER
    if (tokenData.role === 'vendor_users') {
      url += '?store_id=' + tokenData.store.id
    }
    globalRequest("get",url,{},{},true,true)
    .then((res)=>{
      let data = res?.data?.orders
      if(data.length>0){
        dispatch(changeNewOrder(true));
      }
    })
  },[])

  useEffect(()=>{
    globalRequest("get",API.GET_NOTIFICATIONS,{},{},true,true)
    .then(({data, status})=>{
      const notifications = data.notifications.filter((item)=>item.read === false)
      if(notifications?.length > 0){
        dispatch(changeNotification(true));
      }
    })
  },[]);

  useEffect(()=>{
    if (addDeleteGetLocalStorage(STORAGE.TOKEN, {}, "get", "single")) {
      refreshAuthToken();
      setInterval(()=>{
        refreshAuthToken();
      },(2000))
    }
  },[])

  return (
    <div>
      {page !== PAGES.MANAGE_WORKING_HOUR &&
      page !== PAGES.MANAGE_HOLIDAY &&
      page !== PAGES.PAYOUT_DETAIL ? <Header2 /> : null}
      <Header2 />
      <div className="main-wrapper full-bg">
        {page !== PAGES.MANAGE_WORKING_HOUR &&
        page !== PAGES.MANAGE_HOLIDAY &&
        page !== PAGES.PAYOUT_DETAIL ? <Sidebar /> : null}
        {page === PAGES.DASHBOARD ? <DashboardPage /> : null}
        {page === PAGES.MANAGE_PRODUCT ? <ViewProduct /> : null}
        {page === PAGES.EDIT_PRODUCT ? <EditProduct /> : null}
        {page === PAGES.EDIT_UOM ? <EditUOM /> : null}
        {page === PAGES.ADD_PRODUCT ? <AddProduct /> : null}
        {page === PAGES.MANAGE_MODIFIER ? <ModifierView /> : null}
        {page === PAGES.ADD_ADDON ? <AddAddOn /> : null}
        {page === PAGES.MY_ORDER ? <MyOrders socket={socket} /> : null}
        {page === PAGES.EARNINGS_PAYOUTS ? <EarningsPayouts /> : null}
        {page === PAGES.PAYOUTS_DETAIL ? <PayoutsDetail /> : null}
        {page === PAGES.PAYOUT_REPORT ? <Dashboard /> : null}
        {page === PAGES.NOTIFICATION ? <Notification /> : null}
        {page === PAGES.MANAGE_CATEGORIES ? <ManageCategories /> : null}
        {page === PAGES.MANAGE_STORE ? <MyStores /> : null}
        {page === PAGES.MANAGE_STORE_DETAIL ? <MyStoreDetail /> : null}        
        {page === PAGES.ADD_STORE ? <AddNewStore /> : null}
        {page === PAGES.MY_OFFER ? <MyOffer /> : null}
        {page === PAGES.ADD_OFFER ? <AddOffer /> : null}
        {page === PAGES.MANAGE_TIMING ? <ManageTiming /> : null}
        {page === PAGES.MANAGE_TIMING_DETAIL ? <ManageTimingDetail /> : null}
        {page === PAGES.MANAGE_TIMING_EDIT ? <ManageTimingEdit /> : null}
        {page === PAGES.MANAGE_USER ? <ManageUser /> : null}
        {page === PAGES.MANAGE_USER_ADD_EDIT ? <ManageUserDetail /> : null}
        {page === PAGES.MANAGE_HOLIDAYS ? <ManageHolidays /> : null}
        {page === PAGES.MANAGE_PAYOUTS ? <ManagePayouts /> : null}
        {page === PAGES.MANAGE_PAYOUTS_DETAIL ? <ManagePayoutsDetail /> : null}
        {/* we are going to use this as the add store  */}
        {page === PAGES.MANAGE_WORKING_HOUR ? <ManageWorkingHours /> : null}
        {page === PAGES.MANAGE_HOLIDAY ? <ManageHoliday /> : null}
        {page === PAGES.PAYOUT_DETAIL ? <PayoutDetails /> : null}
        {page === PAGES.MY_STORE ? <MyStores /> : null}
      </div>
    </div>
  );
};

export default MainPage;
