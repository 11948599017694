import month from "./month";
import moment from "moment";
import 'moment-timezone';

const standardTime = (dates, withoutTime = false) => {
  try {
    return moment(new Date(dates))
      // .tz('Asia/Qatar')
      .format((withoutTime)? "DD MMMM YYYY": "DD MMMM YYYY - hh:mm A");
    let date = new Date(dates);
    let hours = date.getHours() > 12?date.getHours() - 12:date.getHours();
    let hour = hours < 10 ? `0${hours}` : hours;
    hour = hour == 0 ? `12` : hours;
    return `${date.getDate()} ${month[date.getMonth()]} ${date.getFullYear()} - ${hour > 9?hour:`0${hour}`}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()} ${date.getHours() < 12 ? "AM" : "PM"}`;
  } catch (e) {
    return "-";
  }
};

export default standardTime;
