/** @format */
import "./Notification.scss";
import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { API } from "../../Enum";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeNotification } from "../../redux/reducers/notification";
import notificationIcon from "../../assets/images/structure/notification-red-icon.svg";
import globalRequest from "../../global-modules/globalRequest";
import standardTime from "../../global-modules/standardTime";
import NoDataFound from "../../global-modules/EmptyPages/NoDataFound";
import { getDataByKeyLocale } from "../../Helpers/Helpers";
import { PAGES } from "../../Enum";
import { changePage } from "../../redux/reducers/page";

const Notification = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);

  const markAsRead = () => {
    dispatch(changeLoader(true));
    globalRequest("get", API.MARK_AS_READ, {}, {}, true, true)
      .then(({data, status}) => {
        if (status == 400) {
          dispatch(changeLoader(false));
          dispatch(
            changeSnackbar({
              isOpen: true,
              state: "error",
              message: t('somethingWentWrong'),
            })
          );
        }
        if (data.ack == 1) {
          dispatch(changeNotification(false));
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t('somethingWentWrong'),
          })
        );
      });
  };

  const getNotifications = () => {
    dispatch(changeLoader(true));
    globalRequest("GET", API.GET_NOTIFICATIONS, {}, {}, true, true)
      .then(({data, status}) => {
        if (status == 400) {
          dispatch(changeLoader(false));
          dispatch(changeSnackbar({ isOpen: true, state: "error", message: t('somethingWentWrong'), }));
        }
        if (data.ack == 1) {
          setNotifications(data.notifications);
          const unread = data.notifications.filter((item) => item.read === false);
          if (unread.length > 0) {
            markAsRead();
          }
          dispatch(changeLoader(false));
        }
      })
      .catch((err) => {
        dispatch(changeLoader(false));
        dispatch(
          changeSnackbar({
            isOpen: true,
            state: "error",
            message: t('somethingWentWrong'),
          })
        );
      });
  };

  const redirectToOrder = (orderId, storeId) => {
    let orderSearch = {
      order_id: orderId,
      store_id: storeId,
    }
    sessionStorage.setItem("orderSearch", JSON.stringify(orderSearch));
    dispatch(changePage(PAGES.MY_ORDER));
  };

  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="notification-wrapper">
        <div className="store-detail-head" style={{ marginBottom: "20px",display: 'flex',justifyContent: 'space-between' }}>
          <h3 className="h3">{t('notification')}</h3>
        </div>
        <Grid container columnSpacing={0}>
          {notifications?.length > 0 ? notifications?.map((notification, index) => 
          <Grid key={`notify-${index}`} item xs={12}>
            <div className="my-notification-wrap">
              <Boxed display={"flex"} alignItems={"center"} className="">
                <div className="img-block">
                  <img src={notificationIcon} className="logo-icon" alt="" />
                </div>
                <div className="relative">
                  <p className="p2" style={{ margin: "0px", textDecoration:'underline'}}>{getDataByKeyLocale(notification.store.stores_locales, 'business_name')}</p>
                  <p className="address-block" style={{ margin: "0px", }}>{notification?.message}</p>
                  
                  {notification?.orderId?
                  <p className="p3"
                    style={{ margin: "0px", cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => redirectToOrder(notification?.orderId, notification?.store?.id)}
                  >{notification?.orderId}</p>
                  : null}

                  <p className="p3">{standardTime(notification?.createdAt)}</p>
                </div>
              </Boxed>
            </div>
          </Grid>): <NoDataFound text={t('notification_NoNotificationsFound')} />}
        </Grid>
      </div>
    </div>
  );
};

export default Notification;
