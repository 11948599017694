import { createSlice } from '@reduxjs/toolkit'

export const baladiInfo = createSlice({
  name: 'baladiInfo',
  initialState: {
    value: {
        BALADI_BRAND_Id: 3,
        BALADI_STORE_Id: 1,
        BALADI_STORE_SLUG: "baladi-express-store"
    },
  },
  reducers: {
    changeBaladiInfo: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeBaladiInfo } = baladiInfo.actions
export const currentBaladiInfo = (state) => state.baladiInfo?.value;
export default baladiInfo.reducer
