import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTranslation } from "react-i18next";

const SearchSelectSingleStore = ({
    disabled = false,
    options,
    value,
    handleOptionsChange,
    size
}) => {

  const { t } = useTranslation();

  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.label}
        value={value}
        defaultValue={null}
        onChange={handleOptionsChange}
        disabled={disabled}
        disableClearable={true}
        renderInput={(params) => (
          <TextField {...params} label={`${t('manageUsers_Store')}*`} size={size} variant="outlined" />
        )}
        renderOption={(props, option) => (
          <List className="store-dropdown">
            <ListItem {...props}>
              <ListItemText primary={option.label} secondary={`${option.secondLabel}`} />
            </ListItem>
          </List>
        )}
      />
    </>
  );
};

export default SearchSelectSingleStore;
