import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {KEYS} from '../../Enum';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';

const GoogleAutocompletePlaces = ({
		googleAddress,
		onChange,
}) => {

	const { t } = useTranslation();
	const [showSearch, setShowSearch] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setShowSearch(true)
		}, 500);
	}, [])

    return (
      showSearch?<GooglePlacesAutocomplete
        apiKey={KEYS.GOOLGE_MAP_ADDRESS_KEY}
        autocompletionRequest={{
          componentRestrictions: {
            country: ['qa'],
          },
        }}
        selectProps={{
          placeholder: t('Address_googlePlaceholder'),
          isClearable: true,
          onError: (status, clearSuggestions) => {
            clearSuggestions();
          },
          onChange: async (val) => {
            onChange(val);
          },
          value: googleAddress,
        }}
        value={googleAddress}
      />: null
    )

}

export default GoogleAutocompletePlaces;