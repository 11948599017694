
import { useDispatch } from "react-redux";
import modalClose from "../assets/images/structure/close-black.svg";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import {
   Button, Dialog, DialogContent
} from "@mui/material";

const InactiveAllAddOnStore = ({
  handleClose,
  handleNo,
  handleYes,
  message
}) => {

  const { t } = useTranslation();
  
  return (
    <Dialog open={true} onClose={handleClose} className="main-modal maxwidth-430" >
      <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
      <DialogContent>
        <h4 className="h4" style={{ marginBottom: "10px",marginTop: '0px' }}>
          {t("manageStore_DoYouWantToOverride")}
        </h4>
        <p className="p2" style={{ marginBottom: "16px" }}>{message}</p>
         <Boxed style={{display:'flex',justifyContent:"space-between"}}>
         <Button
            type="button" variant="outlined" sx={{ width: "48%", marginTop: "10px" }}
            onClick={handleYes}
          >{t("yes")}</Button>
          <Button
            type="button" variant="contained" sx={{ width: "48%", marginTop: "10px" }}
            onClick={handleNo}
          >{t("no")}</Button>
        </Boxed>
      </DialogContent>
    </Dialog>
  );
};

export default InactiveAllAddOnStore;
