import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import { Boxed } from "../Styles-Elements/Box";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import globalRequest from "../global-modules/globalRequest";
import { API, COUNTRY_CODE, ROLE, STORAGE } from "../Enum";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../redux/reducers/snackbar";
import { useNavigate } from "react-router";
import timeOut from "../global-modules/timeOut";
import { changeLoader } from "../redux/reducers/loader";
import addDeleteGetLocalStorage from "../global-modules/addDeleteGetLocalStorage";
import validEmail from "../global-modules/validEmail";
import { changeLocalData } from "../redux/reducers/localData";
import { useTranslation } from "react-i18next";
import {PhoneMask} from "../global-modules/MaskedInputs";

const Login = ({ isOpen = false, handleClose, openForget }) => {

  const {t} = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpLogin, setIsOtpLogin] = useState(false);
  const [isPasswordLogin, setIsPasswordLogin] = useState(true);
  const [isSend, setIsSend] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [email, setEmail] = useState(""); //email and mobile
  const [emailErr, setEmailErr] = useState(true);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(true);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState(true);
  const [isPhone, setPhone] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const [otpTimer, setOtpTimer] = useState(30);
  const [viewSentButton, setViewSendButton] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setShowPassword(false);
      setIsOtpLogin(false);
      setIsPasswordLogin(true);
      setIsSend(false);
      setIsResend(false);
      setEmail("");
      setPassword("");
      setOtp("");
      setEmailErr(true);
      setPasswordErr(true);
      setOtpErr(true);
    }
  }, [isOpen]);

  const handleOtp = (type = "email", sendType = "") => {
    if (sendType == "resend") {
      if (type == "email") {
        setOtp("");
      }
    }

    dispatch(changeLoader(true));
    globalRequest(
      "post",
      API.GET_SING_IN_OTP,
      {
        uname: email,
        role: ROLE,
      },
      {},
      false
    )
      .then((res) => {
        let data = res?.data;
        if (data?.ack == 1) {
          if (type == "email" && sendType == "") {
            setIsOtpLogin(true);
            setIsPasswordLogin(false);
            setPassword("");
          }

          if (type == "email" && sendType == "resend") {
            setOtpTimer(30);
            setViewSendButton(false);
          }

          if (sendType == "resend") {
            setIsResend(true);
          } else {
            setIsSend(true);
          }
        }
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    if (isSend) {
      timeOut(
        (bool) => {
          setViewSendButton(bool);
        },
        (i) => {
          setOtpTimer(i);
        }
      );
    }
  }, [isSend]);

  useEffect(() => {
    if (isResend) {
      timeOut(
        (bool) => {
          setViewSendButton(bool);
          setOtpTimer(30);
        },
        (i) => {
          setOtpTimer(i);
        }
      );
    }
  }, [isResend]);

  const checkChangeIdentity = async (e) => {
    setEmail(e.target.value);
    if (e.target.value.length > 3 && !isNaN(e.target.value)) {
      setPhone(true);
      document.getElementById("identity").focus();
    } else {
      setPhone(false);
      setTimeout(() => {
        document.getElementById("identity").focus();
      }, 100);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isValue = true;

    if (!email) {
      setEmailErr(false);
    }

    if (isPasswordLogin) {
      if (!password) {
        setPasswordErr(false);
      }
    } else {
      if (!otp) {
        setOtpErr(false);
      }
    }

    if (!email) {
      isValue = false;
    } else if (!isNaN(email) && email.length !== 8) {
      isValue = false;
    } else {
      if (isPasswordLogin) {
        if (!password) {
          isValue = false;
        }
      } else {
        if (!otp) {
          isValue = false;
        }
      }
    }

    if (isValue) {
      let data = {};
      let url = "";
      if (isPasswordLogin) {
        url = API.SIGN_IN_PASSWORD;
        data = {
          uname: email,
          password: password,
          role: ROLE,
        };
      } else {
        url = API.SIGN_IN_OTP;
        data = {
          uname: email,
          otp: otp,
          role: ROLE,
        };
      }

      dispatch(changeLoader(true));
      globalRequest("post", url, data, {}, false)
        .then((res) => {
          let data = res?.data;
          if (data?.ack == 1) {
            addDeleteGetLocalStorage(STORAGE.TOKEN, data?.token, "add", "single");
            dispatch(changeLocalData());
            navigate("/dashboard");
          }
          if (data?.errMsg && data?.errMsg.length > 0) {
            data?.errMsg.forEach((element) => {
              for (const [key, value] of Object.entries(element)) {
                setError(value);
              }
            });
          } else {
            dispatch(
              changeSnackbar({
                isOpen: true,
                state: data?.ack == 1 ? "success" : "error",
                message: data?.msg,
              })
            );
            dispatch(changeLoader(false));
          }
        })
        .catch((e) => {
          dispatch(changeLoader(false));
        });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
          <h2 className="h3 text-center">{t("login_welcome")}</h2>
          <p className="p3 text-center">{t("login_pleaseEnterYourDetails")}</p>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div className="form-group">
                <TextField
                  id="identity"
                  value={email}
                  onChange={(e) => {
                    checkChangeIdentity(e);
                    addDeleteGetLocalStorage(STORAGE.EMAIL, e.target.value, "add", "single");
                  }}
                  label={t("login_emailOrMobileNo") + " *"}
                  InputProps={ isPhone? {
                      startAdornment: (<InputAdornment position="start">{COUNTRY_CODE}</InputAdornment>),
                      inputComponent: PhoneMask,
                    }
                  : null}
                />
                {!emailErr && !email ?
                  <span className="errorspan">{t("login_mobile_email")} </span>
                : isNaN(email) ? (
                  validEmail(email) ? null : <span className="errorspan">{t("login_valid_email")} </span>
                ) : email.length !== 8 && email ?
                  <span className="errorspan">{t("login_mobile_no_8_digit")} </span>
                : null}
              </div>
            </Grid>

            {isPasswordLogin ? (
              <>
                <Grid item xs={12}>
                  <div className="form-group">
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">{t("login_password") + " *"}</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => { setShowPassword((show) => !show); }}
                              onMouseDown={(e) => { e.preventDefault(); }}
                              edge="end"
                            >{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                          </InputAdornment>
                        }
                        label={t("login_password")}
                      />
                    </FormControl>
                    {!passwordErr && !password ? (
                      <span className="errorspan">{t("login_password")} </span>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                  <span
                    onClick={() => {
                      if (email) {
                        let isValid = false;
                        if (isNaN(email)) { isValid = validEmail(email);
                        } else { isValid = email.length == 8; }
                        if (isValid) { handleOtp("email"); }
                      } else {
                        dispatch(
                          changeSnackbar({
                            isOpen: true,
                            state: "error",
                            message: t("accountSetup_enterEmailOrMobile"),
                          })
                        );
                      }
                    }}
                    className="login-otp"
                  >{t("login_loginViaOTP")}</span>
                  <span onClick={openForget} className="forgot-password">{t("login_forgotPassword")}</span>
                </Grid>
              </>
            ) : null}
            {isOtpLogin ? (
              <>
                <Grid item xs={12}>
                  <div className="form-group">
                    <TextField
                      value={otp}
                      onChange={(e) => {
                        let r = e.target.value.replace(/\D/g, "");
                        setOtp(r);
                      }}
                      label={t("login_otp") + " *"}
                      placeholder={t("login_enterOTP")}
                      id="outlined-start-adornment"
                      inputProps={{ maxLength: 4 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            00:{otpTimer < 10 ? `0${otpTimer}` : otpTimer}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!otpErr && !otp ? (
                      <span className="errorspan">{t("login_enter_otp")} </span>
                    ) : null}
                  </div>
                </Grid>
                <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                  <span
                    onClick={() => {
                      setIsOtpLogin(false);
                      setIsPasswordLogin(true);
                      setOtp("");
                    }}
                    className="login-otp"
                  >
                    {t("login_loginViaPassword")}
                  </span>
                  <Boxed display={"flex"} alignItems={"center"}>
                    <span className="dont-account">{t("login_didntReceivedOTP")}</span>
                    {viewSentButton ? (
                      <span
                        onClick={(e) => {
                          handleOtp("email", "resend");
                        }}
                        className="sign-up"
                      >
                        {t("login_resend")}
                      </span>
                    ) : null}
                  </Boxed>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} textAlign="center">
              <Button
                // onClick={handleSubmit}
                type="submit"
                variant="contained"
                size="large"
                sx={{ width: 1, marginTop: "40px", marginBottom: "30px" }}
              >
                {t("login_logIn")}
              </Button>
            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
              <span className="dont-account">{t("login_dontHaveAnAccountYet")}</span>
              <span onClick={() => navigate("/sign-up") } className="sign-up" >{t("login_signUp")}</span>
            </Grid>
          </Grid>
        </form>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default Login;
