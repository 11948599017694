/** @format */
import { useState, useEffect } from "react";
import { Box, Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import minusIcon from "../assets/images/structure/minus-icon.svg";
import plusIcon from "../assets/images/structure/plus-icon.svg";
import modalClose from "../assets/images/structure/close-black.svg";
import deleteIcon from "../assets/images/structure/delete-red.svg";
import { generateHours, convertTimeTo12Hour, getNextTimeSlot } from '../Helpers/Helpers';
import globalRequest from "../global-modules/globalRequest";
import { API } from "../Enum";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../redux/reducers/snackbar";

const AddEditTimeSlots = ({ isOpen = false, handleClose, timeSlots, selectedId, storeId, onSuccess }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  let [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const hours = generateHours(15);

  const handleSave = async () => {
    if (selectedTimeSlots.length === 0) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: t('manageStore_pleaseAddTimeSlots'),
        state: "error"
      }));
      return;
    }
    const hasError = selectedTimeSlots.find(timeSlot => timeSlot.hasError !== '' || timeSlot.hasEndTimeError !== '');
    if (hasError) {
      dispatch(changeSnackbar({
        isOpen: true,
        message: t('manageStore_pleaseCheckTimeSlots'),
        state: "error"
      }));
      return;
    }
    const timeJsonObject = selectedTimeSlots.map(timeSlot => ({
      starttime: timeSlot.starttime,
      endtime: timeSlot.endtime,
    }));
    const data = {
      storeId: storeId,
      categoryId: selectedId,
      timejson: JSON.stringify(timeJsonObject),
    };
    const response = await globalRequest('post', API.CATEGORY_MANAGEMENT.UPDATE_CATEGORY_HOURS, data, {}, true, true);
    if (response.data.ack === 1) {
      onSuccess();
    } else {
      if (response.data.msg !== '') {
        dispatch(changeSnackbar({
          isOpen: true,
          message: response.data.msg,
          state: "error"
        }));
      } else {
        response?.data?.errMsg?.forEach(element => {
          for (const [key, value] of Object.entries(element)) {
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: value,
                state: "error",
              })
            );    
          }
        });
      }
    }
  };
  
  const changeTime = (e, type, index1) => {
    let selectedTimeSlotsCopy = selectedTimeSlots.map((time, ind) => {
      if (ind === index1) {
        time.hasError = '';
        time.endTimeError = '';
        if (type === 'start') {
          const thisStartTime = parseInt(e.target.value.split(':').join(''));
          const thisEndTime = parseInt(selectedTimeSlots[index1].endtime.split(':').join(''));
          time.hasError = '';
          const otherTimes = selectedTimeSlots.filter((time, ind2) => ind2 !== ind);
          const isOverlapping = otherTimes.find((time) => {
            const startTime = parseInt(time.starttime.split(':').join(''));
            const endTime = parseInt(time.endtime.split(':').join(''));
            if (thisStartTime >= startTime && thisStartTime <= endTime) {
              return true;
            }
            if (thisEndTime >= startTime && thisEndTime <= endTime) {
              return true;
            }
            if (thisStartTime <= startTime && thisEndTime >= endTime) {
              return true;
            }
            return false;
          });
          if (isOverlapping) {
            time.hasError = t('manageStore_shiftTimeAreOverlapping');
          }
          time.starttime = e.target.value;
          time.endTime = selectedTimeSlots[index1].endtime
  
        } else {
          const thisStartTime = parseInt(selectedTimeSlots[index1].starttime.split(':').join(''));
          const thisEndTime = parseInt(e.target.value.split(':').join(''));
          time.hasEndTimeError = '';
          const otherTimes = selectedTimeSlots.filter((time, ind2) => ind2 !== ind);
          const isOverlapping = otherTimes.find((time) => {
            const startTime = parseInt(time.starttime.split(':').join(''));
            const endTime = parseInt(time.endtime.split(':').join(''));
            if (thisStartTime >= startTime && thisStartTime <= endTime) {
              return true;
            }
            if (thisEndTime >= startTime && thisEndTime <= endTime) {
              return true;
            }
            if (thisStartTime <= startTime && thisEndTime >= endTime) {
              return true;
            }
            return false;
          });
          if (isOverlapping) {
            time.hasEndTimeError = t('manageStore_shiftTimeAreOverlapping');
          }
          time.starttime = selectedTimeSlots[index1].starttime
          time.endtime = e.target.value;
        }
      }
      return time;
    })
    setSelectedTimeSlots(selectedTimeSlotsCopy);
  }

  useEffect(() => {
    if (timeSlots && timeSlots.length > 0) {
      setSelectedTimeSlots(timeSlots.map(timeSlot => ({ ...timeSlot, hasError: '', hasEndTimeError: '' })));
    } else {
      setSelectedTimeSlots([{ starttime: "09:00", endtime: "17:00", hasError: '', hasEndTimeError: '' }]);
    }
  }, [timeSlots]);

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className="main-modal maxwidth-600"
      >
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
        <h4 className="h4" style={{ marginBottom: 18 }}>{t('Time slots')}</h4>
        <div className="timeslots-content">

          {selectedTimeSlots.length > 0 ? selectedTimeSlots.map((timeSlot, index) =>
          <Grid container spacing={2} key={`time-${index}`}>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <div className="form-group clock-picker">
                <InputLabel id="openTime">
                  {t("manageStore_openTime")}
                </InputLabel>
                <Select
                  labelId="openTime"
                  label={t("manageStore_openTime")}
                  className={``}
                  value={timeSlot.starttime}
                  onChange={(e) => changeTime(e, 'start', index)}
                >
                  {hours.map((hour, index2) => <MenuItem
                    key={`hour-${index}-${index2}`}
                    value={hour}>{convertTimeTo12Hour(hour)}</MenuItem>)}
                </Select>
                <span className="redspan">{timeSlot.hasError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5}>
              <div className="form-group clock-picker">
                <InputLabel id="openTime">
                  {t("manageStore_closeTime")}
                </InputLabel>
                <Select labelId="openTime" className={``} value={timeSlot.endtime}
                  onChange={(e) => changeTime(e, 'end', index)}
                >
                {hours.map((hour, index2) => <MenuItem
                    key={`hour-${index}-${index2}`}
                    value={hour}>{convertTimeTo12Hour(hour)}</MenuItem>)}
                </Select>
                <span className="redspan">{timeSlot.hasEndTimeError}</span>
              </div>
            </Grid>
            <Grid item xs={12} sm={1} md={1} lg={1}>
              <img
                src={index === selectedTimeSlots.length - 1? plusIcon:minusIcon}
                className="plus-minus-icon" alt="..."
                onClick={() => {
                  if (index === selectedTimeSlots.length - 1) {
                    const startTime = getNextTimeSlot(selectedTimeSlots[index].endtime, 15);
                    const endTime = getNextTimeSlot(startTime, 15);
                    setSelectedTimeSlots([...selectedTimeSlots, {
                      starttime: startTime,
                      endtime: endTime,
                      hasError: '',
                      hasEndTimeError: ''
                    }]);
                  } else {
                    const newTimeSlots = [...selectedTimeSlots];
                    newTimeSlots.splice(index, 1);
                    setSelectedTimeSlots(newTimeSlots);
                  }
                }}
              />
            </Grid>
          </Grid>):
            <Grid container spacing={2}>
              <Grid item xs={12} sm={5} md={5} lg={5}>
              <Button variant="contained" size="large"
                onClick={() => {
                  setSelectedTimeSlots([{ starttime: "09:00", endtime: "17:00" }]);
                }}
              >Add Time</Button>
              </Grid>
            </Grid>
          }
          </div>
          <div className="timeslots-footer">
              <Box className="sign-up" display={'flex'} alignItems={'center'}
                onClick={() => setSelectedTimeSlots([])}
              ><img src={deleteIcon} className="icon16 mx-1" alt="..."/>{t('Remove all')}</Box>
              <Button variant="contained" size="large"
                onClick={handleSave}
              >{t('Save')}</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEditTimeSlots;
