import { createSlice } from "@reduxjs/toolkit";
import { PAGES, STORAGE } from "../../Enum";
import addDeleteGetLocalStorage from "../../global-modules/addDeleteGetLocalStorage";
import { isExpired, decodeToken } from "react-jwt";

export const page = createSlice({
  name: "page",
  initialState: {
    value: PAGES.DASHBOARD,
  },
  reducers: {
    changePage: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePage } = page.actions;
export const currentPage = (state) => state.page.value;
export default page.reducer;
