import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Grid, Button } from "@mui/material";
import "./../signUp/BecomeAPartnerForm.scss";
import setPasswordImg from "../../../assets/images/structure/set-password-img.png";
import { useParams, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar, currentSnackbar } from "../../../redux/reducers/snackbar";
import { changeLoader } from "../../../redux/reducers/loader";
import { changeLocalData } from "../../../redux/reducers/localData";
import { API, STORAGE } from "../../../Enum";
import {validatePassword} from "../../../Helpers/Helpers";
import globalRequest from "../../../global-modules/globalRequest";
import addDeleteGetLocalStorage from "../../../global-modules/addDeleteGetLocalStorage";
import base64 from 'base-64'
import { useTranslation } from "react-i18next";
import Header from "../../layout/header/Header";

const SetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const params = useParams();
  const id = base64.decode(params.id);
  const hash = params.hash;
  const other = base64.decode(params.other);
  const dispatch = useDispatch();
  const snackbar = useSelector(currentSnackbar);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");
  const [userData, setUserData] = useState([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const checkHash = async () => {
    dispatch(changeLoader(true));
    globalRequest('post', API.CHECK_SET_PASSWORD, {
      user_id: id,
      code: hash
    }, {}, false).then(({data}) => {
      if (data.ack === 0) {
        dispatch(changeSnackbar({ isOpen: true, message: data.msg, state: "error" }));
        navigate("/"); 
      }
      dispatch(changeLoader(false));
    }).catch((err) => {
      dispatch(changeLoader(false));
    });
  }

  const submit = (e) => {
    dispatch(changeLoader(true));
    e.preventDefault();
    let isValid = true;
    if (password === "") {
      setPasswordErr(t('set_password_Password_is_required'));
      isValid = false;
    } else if (password.length < 8) {
      setPasswordErr(t('set_password_Password_must_be_at_least_8_characters'));
      isValid = false;
    } else if (!validatePassword(password)) {
      setPasswordErr(t('set_password_Password_is_invalid'));
      isValid = false;
    }
    if (confirmPassword === "") {
      setConfirmPasswordErr(t('set_password_Confirm_Password_is_required'));
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordErr(t('set_password_Password_and_Confirm_Password_do_not_match'));
      isValid = false;
    }
    if (isValid) {
      globalRequest('post', API.SET_PASSWORD, {
        user_id: id,
        code: hash,
        password: password
      }, {}, false).then(({data}) => {
        if (data.ack === 1) {
          dispatch(changeSnackbar({ isOpen: true, message: data.msg, state: "success" }));
          addDeleteGetLocalStorage(STORAGE.TOKEN, data?.token, "add", "single");
          dispatch(changeLocalData());
          navigate("/dashboard");
        } else {
          dispatch(changeSnackbar({ isOpen: true, message: data.msg, state: "error" }));
        }
        dispatch(changeLoader(false));
      }).catch((err) => {
        dispatch(changeLoader(false));
      });
    } else {
      dispatch(changeLoader(false));
    }
  };

  useEffect(() => {
    checkHash();
  }, []);

  return (
    <>
    <Header />
    <form onSubmit={submit} action="#" method="post">

      <div className="signup-wrapper">
        <div className="signup-block" style={{ padding: "0", height: "100vh" }}>
          <div className="container-body">
            <Grid container columnSpacing={{ xs: 0, sm: 3, md: 4, lg: 5 }}>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                <div className="signup-left" style={{ marginTop: "26px" }}>
                  <img src={setPasswordImg} style={{ marginBottom: "20px" }} alt="" />
                  <h1 className="h1">{t('reachNewCustomersGetMoreSales')}</h1>
                  <h3 className="h3">
                    {t('joinBaladiExpressTheMiddleEastLargestDeliveryPlatform')}
                  </h3>
                </div>
              </Grid>
              <Grid item xs={0} sm={0} md={0} lg={2} xl={2}></Grid>
              <Grid item xs={12} sm={12} md={12} lg={5} xl={5} mt={"60px"}>
                <div className="signup-right">
                  <div className="white-box">
                    <h3 className="h3" style={{ marginBottom: "12px" }}>
                      Hi {other}!
                    </h3>
                    <p className="p3" style={{ fontSize: "14px" }}>
                      {t('pleaseSetThePasswordToCompleteTheRegistrationProcess')}
                    </p>
                    <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 3 }}>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t('password')}*
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPassword ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword((show) => !show)}
                                    edge="end"
                                  >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordErr('');
                              }}
                              label={t('password')}
                            />
                            <span className="redspan">{passwordErr}</span>
                          </FormControl>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form-group">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {t('confirmPassword')}*
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-password"
                              type={showPasswordConfirm ? "text" : "password"}
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPasswordConfirm((show) => !show)}
                                    edge="end"
                                  >
                                    {!showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label={t('confirmPassword')}
                              value={confirmPassword}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setConfirmPasswordErr('');
                              }}
                            />
                            <span className="redspan">{confirmPasswordErr}</span>
                          </FormControl>
                        </div>
                      </Grid>

                      <Grid item xs={12} textAlign="center">
                        <Button
                          variant="contained"
                          size="large"
                          sx={{ width: 1, marginTop: "16px" }}
                          type="submit"
                        >
                          {t('confirm')}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </form>
    </>
  );
};

export default SetPassword;
