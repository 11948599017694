import Slider from "react-slick";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.scss";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import vedorHomeSec1Img from "../../assets/images/structure/vedor-home-sec1-img.png";
import clientUser1 from "../../assets/images/products/client-user1.png";
import clientUser2 from "../../assets/images/products/client-user2.png";
import clientUser3 from "../../assets/images/products/client-user3.png";
import starYellow from "../../assets/images/structure/star-yellow.svg";
import starGray from "../../assets/images/structure/star-gray.svg";
import sec2RevenueImg from "../../assets/images/structure/sec2-revenue-img.svg";
import sec2NewCustomersImg from "../../assets/images/structure/sec2-new-customers-img.svg";
import sec2DeliveryImg from "../../assets/images/structure/sec2-delivery-img.svg";
import { Boxed } from "../../Styles-Elements/Box";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  var settings = {
    centerMode: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <div className="home-wrapper">
        <section className="home-sec1">
          <div className="container-body">
            <div className="home-sec1-wrap">
              <Grid container columnSpacing={{ xs: 0, sm: 2, md: 4, lg: 5 }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="sec1-left-block">
                    <h1 className="h1">
                      {t("home_reachNewCustomersGetMoreSales")}
                    </h1>
                    <Button
                      onClick={() => navigate("/sign-up")}
                      variant="contained"
                      size="large"
                      sx={{ width: "265px" }}
                    >
                      {t("home_becomeABaladiPartner")}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="sec1-right-block">
                    <img
                      src={vedorHomeSec1Img}
                      className="sec1-right-block-img"
                      alt=""
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>
        <section className="home-sec2">
          <div className="container-body">
            <div className="home-sec2-wrap">
              <h2 className="h2 text-center">
                {t("home_benefitsOfBeingABaladiPartner")}
              </h2>
              <Grid container columnSpacing={{ xs: 3, sm: 3, md: 3, lg: 4, xl: 5 }}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    className="sec2-boxes-block"
                  >
                    <div className="boxes-img-block">
                      <img
                        src={sec2RevenueImg}
                        className="boxes-image"
                        alt=""
                      />
                    </div>
                    <div className="boxes-content-block">
                      <h3 className="h3">
                        {t("home_additionalRevenueStream")}
                      </h3>
                      <p className="p1">{t("home_getMoreOrders")}</p>
                    </div>
                  </Boxed>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    className="sec2-boxes-block"
                  >
                    <div className="boxes-img-block">
                      <img
                        src={sec2NewCustomersImg}
                        className="boxes-image"
                        alt=""
                      />
                    </div>
                    <div className="boxes-content-block">
                      <h3 className="h3">{t("home_newCustomers")}</h3>
                      <p className="p1">
                        {t("home_moreVisibilityThroughBaladiExpressPlatform")}
                      </p>
                    </div>
                  </Boxed>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Boxed
                    display={"flex"}
                    alignItems={"center"}
                    className="sec2-boxes-block"
                  >
                    <div className="boxes-img-block">
                      <img
                        src={sec2DeliveryImg}
                        className="boxes-image"
                        alt=""
                      />
                    </div>
                    <div className="boxes-content-block">
                      <h3 className="h3">{t("home_deliveryDone")}</h3>
                      <p className="p1">{t("home_noMoreDriverHeadaches")}</p>
                    </div>
                  </Boxed>
                </Grid>
              </Grid>
            </div>
          </div>
        </section>
        {/* <section className="home-sec3">
          <div className="container-body">
            <div className="home-sec3-wrap">
              <h2 className="h2 text-center">
                {t("home_whatOurClientsSayAboutUs")}
              </h2>
              <Slider {...settings}>
                <div className="about-client-box">
                  <img src={clientUser1} className="client-box-img" alt="" />
                  <h3 className="h3">{t("home_jamesPattinson")}</h3>
                  <div className="star-showing">
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starGray} alt="" />
                  </div>
                  <p className="p2">
                    {t("home_lobortisLeoPretiumFacilisisAmetNislAtNec")}
                  </p>
                </div>
                <div className="about-client-box">
                  <img src={clientUser2} className="client-box-img" alt="" />
                  <h3 className="h3">{t("home_gregStuart")}</h3>
                  <div className="star-showing">
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                  </div>
                  <p className="p2">
                    {t("home_lobortisLeoPretiumFacilisisAmetNislAtNec")}
                  </p>
                </div>
                <div className="about-client-box">
                  <img src={clientUser3} className="client-box-img" alt="" />
                  <h3 className="h3">{t("home_trevorMitchell")}</h3>
                  <div className="star-showing">
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starGray} alt="" />
                    <img src={starGray} alt="" />
                  </div>
                  <p className="p2">
                    {t("home_lobortisLeoPretiumFacilisisAmetNislAtNec")}
                  </p>
                </div>
                <div className="about-client-box">
                  <img src={clientUser2} className="client-box-img" alt="" />
                  <h3 className="h3">{t("home_gregStuart")}</h3>
                  <div className="star-showing">
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                  </div>
                  <p className="p2">
                    {t("home_lobortisLeoPretiumFacilisisAmetNislAtNec")}
                  </p>
                </div>
                <div className="about-client-box">
                  <img src={clientUser1} className="client-box-img" alt="" />
                  <h3 className="h3">{t("home_jamesPattinson")}</h3>
                  <div className="star-showing">
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starGray} alt="" />
                  </div>
                  <p className="p2">
                    {t("home_lobortisLeoPretiumFacilisisAmetNislAtNec")}
                  </p>
                </div>
                <div className="about-client-box">
                  <img src={clientUser3} className="client-box-img" alt="" />
                  <h3 className="h3">{t("home_trevorMitchell")}</h3>
                  <div className="star-showing">
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starYellow} alt="" />
                    <img src={starGray} alt="" />
                    <img src={starGray} alt="" />
                  </div>
                  <p className="p2">
                    {t("home_lobortisLeoPretiumFacilisisAmetNislAtNec")}
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </section> */}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
