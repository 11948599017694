import { useEffect, useState } from "react";
import {
  Grid, Button, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Boxed } from "../../Styles-Elements/Box";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changePage } from "../../redux/reducers/page";
import { API, PAGES } from "../../Enum";
import globalRequest from "../../global-modules/globalRequest";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import { changeStoreTimingData } from "../../redux/reducers/storeTiming";
import { currentLocalData } from "../../redux/reducers/localData";
import editIcon from "../../assets/images/structure/edit-black.svg";
import deleteIcon from "../../assets/images/structure/delete-black.svg";
import {getDataByKeyLocale} from "../../Helpers/Helpers";

const daysArray = [ 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' ];

const ManageTiming = () => {
  
  const { t } = useTranslation();
  const localData = useSelector(currentLocalData);
  const dispatch = useDispatch();
  const [storeList, setStoreList] = useState([]);

  const getStoreTiming = async () => {
    dispatch(changeLoader(true));
    let url = API.MANAGE_STORE_TIMING.GET_STORE_TIMING;
    const storeTimingResponse = await globalRequest('get', url, {}, {}, true, true);
    const storeTimingData = storeTimingResponse.data;
    setStoreList(storeTimingData.result.filter(
      (store) => store.is_approved === null || store.is_approved === true
    ).map((store) => {
      const storeTiming = store.manage_working_hours.length > 0 ?
        store.manage_working_hours
          .filter((timing) => timing.open)
          .map((timing) => {return timing.day;}) :
        [];
      return {
        ...store,
        days: daysArray.filter((day) => storeTiming.includes(day)).join(', ')
      }
    }));
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    getStoreTiming();
  }, []);

  return (
    <div className="main-mid-wrapper">
      <div className="store-detail-wrapper">
        <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={"20px"}
        >
          <div className="store-detail-head" style={{ marginBottom: "0px" }}>
            <h3 className="h3" style={{ marginRight: "0px" }}>
              {t("manageStore_manageTimings")}
            </h3>
          </div>
          {localData.token.role === 'vendor_users'? null :
            <Button
            variant="contained"
            size="small"
            sx={{ width: "150px" }}
            className="date-picker-btn"
            onClick={() => {
              dispatch(changeStoreTimingData({
                type: 'add-all',
                store_id: null,
                store_name: null,
                store_timing: null,
                open247: null,
              }));
              dispatch(changePage(PAGES.MANAGE_TIMING_EDIT));
            }}
          >
            {t("manageStore_AddTiming")}
          </Button>}
        </Boxed>
       {localData.token.role === 'vendor_users'?null: <Boxed
          display={"flex"}
          alignItems={"center"}
          justifyContent={"start"}
          mb={"20px"}
          padding={"16px 16px"}
          className="table-switch-box"
        >
          <div className="relative">
            <h6 className="h6 mb-1">
              {t('manageStore_wantToEdittimingsOfAllStores')}
            </h6>
            <span className="p3">
              {t('manageStore_wantToEdittimingsOfAllStoresText')} {" "}
              <a
                className="text-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(changeStoreTimingData({
                    type: 'edit-all',
                    store_id: null,
                    store_name: null,
                    store_timing: null,
                    open247: null,
                  }));
                  dispatch(changePage(PAGES.MANAGE_TIMING_EDIT));
                }}
              >
                {t('manageStore_wantToEdittimingsOfAllStoresTextToEdit')}
              </a>{" "}
              {t('manageStore_wantToEdittimingsOfAllStoresTextHere')}.
            </span>
          </div>
        </Boxed>}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('manageStore_Stores')}</TableCell>
                <TableCell>{t('manageStore_storeAddress')}</TableCell>
                <TableCell>{t('manageStore_Days')}</TableCell>
                <TableCell align="center">{t('manageStore_Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {storeList.map((store, index) => (
                <TableRow
                  key={`store-${index}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {getDataByKeyLocale(store.stores_locales, 'business_name')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {store.business_address}
                  </TableCell>
                  
                  <TableCell align="right"
                    style={{
                      textTransform: 'capitalize'
                    }}
                  >{store.days || '-'}</TableCell>
                  <TableCell align="center">
                    <img
                      src={editIcon}
                      alt="edit"
                      className="icon16 mx-2 pointer"
                      onClick={() => {
                        dispatch(changeStoreTimingData({
                          type: 'edit',
                          store_id: store.id,
                          store_name: getDataByKeyLocale(store.stores_locales, 'business_name'),
                          store_timing: store.manage_working_hours,
                          open247: store.open247,
                        }));
                        dispatch(changePage(PAGES.MANAGE_TIMING_EDIT));
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ManageTiming;
