
import itemsEmpty from "../assets/images/structure/items-empty.png";
import modalClose from "../assets/images/structure/close-black.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Boxed } from "../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "@mui/material";
import SearchInput from "../shared/SearchInput";

const AddItemsOffer = ({ isOpen = false, handleClose }) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        className="main-modal maxwidth-765"
      >
        <img
          src={modalClose}
          alt="..."
          onClick={handleClose}
          className="modal-close"
        />
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "8px" }}>
            {t("myOffer_AddItems")}
          </h2>
          <div className="search-300">
              <SearchInput
                onChange={(e) => {
                  search = e.target.value;
                  setSearch(search);
                }}
              />
          </div>
          <div className="offer-item-box">
            <div className="relative">
              <div className="offer-items-header">
                <h6 className="h6">{t("myOffer_Category")} (7)</h6>
                <div className="h6">
                  <Checkbox />
                  {t("myOffer_SelectAll")}
                </div>
              </div>
              <div className="offer-item-body">
                <div className="offer-item-row">
                  <h6 className="h6">Starters</h6>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <h6 className="h6">Soups</h6>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <h6 className="h6">Fresh Juices</h6>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <h6 className="h6">Main Course</h6>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <h6 className="h6">Italian</h6>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <h6 className="h6">Beverages</h6>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <h6 className="h6">Noodles</h6>
                  <Checkbox />
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="offer-items-header">
                <h6 className="h6">{t("myOffer_Items")} (55)</h6>
                <div className="h6">
                  <Checkbox />
                  {t("myOffer_SelectAll")}
                </div>
              </div>
              <div className="offer-item-body">
                {/* <div className="offer-item-empty">
                  <img src={itemsEmpty} alt="..." />
                  <h6 className="h6">{t("myOffer_selectCategoryFirstToViewItems")}</h6>
                </div> */}
                <div className="offer-item-row">
                  <div className="relative">
                    <h6 className="h6">Chinese Platter</h6>
                    <span className="font12-bold">{t("myOffer_QAR")} 145.00</span>
                  </div>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <div className="relative">
                    <h6 className="h6">Crispy Corn</h6>
                    <span className="font12-bold">{t("myOffer_QAR")} 145.00</span>
                  </div>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <div className="relative">
                    <h6 className="h6">Noodles with Manchurian Ball</h6>
                    <span className="font12-bold">{t("myOffer_QAR")} 145.00</span>
                  </div>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <div className="relative">
                    <h6 className="h6">Veg Momos Steamed (6 Pcs)</h6>
                    <span className="font12-bold">{t("myOffer_QAR")} 145.00</span>
                  </div>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <div className="relative">
                  <h6 className="h6">Chinese Platter</h6>
                    <span className="font12-bold">{t("myOffer_QAR")} 145.00</span>
                  </div>
                  <Checkbox />
                </div>
                <div className="offer-item-row">
                  <div className="relative">
                  <h6 className="h6">Chinese Platter</h6>
                    <span className="font12-bold">{t("myOffer_QAR")} 145.00</span>
                  </div>
                  <Checkbox />
                </div>
              </div>
            </div>
          </div>
          <Boxed className="text-left">
            <Button variant="contained"
                  size="small"
                  sx={{ width: "140px", marginTop: "20px" }}>{t("myOffer_Add")}</Button>
          </Boxed>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddItemsOffer;
