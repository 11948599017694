import { TextField, Button } from "@mui/material";
import modalClose from "../assets/images/structure/close-black.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const RejectOrder = ({ isOpen = false, handleClose, submit }) => {

  const {t} = useTranslation();
  const [message, setMessage] = useState("");

  const submits = (e) => {
    e.preventDefault();
    if (message) {
      submit(message);
    }
  };
  
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} className="main-modal maxwidth-430">
        <img src={modalClose} alt="..." onClick={handleClose} className="modal-close" />
        <DialogContent>
          <h2 className="h3" style={{ marginBottom: "8px", fontSize: "20px" }}>
            {t('order_RejectOrder')}
          </h2>
          <p className="p3" style={{ marginBottom: "30px", fontSize: "14px" }}>
            {t('order_Areyousureyouwanttorejectorder')}
          </p>
          <form onSubmit={submits}>
            <TextField
              onChange={(e) => setMessage(e.target.value)}
              id="outlined-multiline-flexible"
              label="Reason"
              required
              multiline
              rows={3}
            />
            <Button type="submit" fullWidth variant="contained" size="large" style={{ marginTop: "30px" }}>
              {t('order_submit')}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RejectOrder;
