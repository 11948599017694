import { useState, useEffect, useRef } from "react";
import { Typography, Box, Switch } from "@mui/material";
import uploadBlackIcon from "../../assets/images/structure/upload-black-icon.svg";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import globalRequest from "../../global-modules/globalRequest";
import { API, IMAGE_URL } from "../../Enum";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "../../redux/reducers/snackbar";

const ChildCategory = ({childCategory, handleSelectedId, openUploadImage, storeId, handleCategoryget, handleProductget}) => {

  const dispatch = useDispatch();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [childCategoryList, setChildCategoryList] = useState([]);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  
  const drop = async (e, index) => {
    const copyListItems = [...childCategoryList];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    
    setChildCategoryList(copyListItems);
    const newSequence = copyListItems.map((item, index) => {
      return {
        catStoreId: item.catStoreId,
        sequence: index + 1,
      };
    });
    await globalRequest('post', API.CATEGORY_MANAGEMENT.CHANGE_SEQUENCE, newSequence, {}, true, true);
  };

  const handleCategoryStatusChange = async (event, category) => {
    const status = event.target.checked ? 'active' : 'inactive';
    const data = {
      storeId: storeId,
      categoryId: category.id,
      status: status
    };
    const response = await globalRequest('post', API.CATEGORY_MANAGEMENT.UPDATE_CATEGORY_HOURS, data, {}, true, true);
    if (response.data.ack === 1) {
      dispatch(changeSnackbar({
        message: response.data.msg,
        state: 'success',
        isOpen: true,
      }));
      handleProductget(category.id);
    } else {
      dispatch(changeSnackbar({
        message: response.data.msg,
        state: 'error',
        isOpen: true,
      }));
    }
    handleCategoryget();
  }

  useEffect(() => {
    setChildCategoryList(childCategory);
}, [childCategory]);

  return (
    <>
      {childCategoryList?.map((childCategoryItem, index) =><Box
        p={"8px 34px 8px 34px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        style={{ cursor: "pointer" }}
        onClick={() => handleSelectedId(childCategoryItem.id, childCategoryItem.name, childCategoryItem.timejson)}
        onDragStart={(e) => dragStart(e, index)}
					onDragEnter={(e) => dragEnter(e, index)}
					onDragEnd={(e) => drop(e, index)}
					draggable
      >
        <DragIndicatorIcon sx={{ color: "#a7a7a7" }} />
        <Typography
          variant="body2"
          component="body2"
          width={"100%"}
        >
          {childCategoryItem.name}
        </Typography>
        <Box display={"flex"} alignItems={"center"}>
          <img
            src={childCategoryItem.image ? IMAGE_URL(childCategoryItem.image): uploadBlackIcon}
            onError={(e) => { e.target.onerror = null; e.target.src = uploadBlackIcon; }}
            width={"20px"} alt=""
            style={{ marginRight: "10px" }}
            onClick={() => openUploadImage(childCategoryItem.id, childCategoryItem.name, childCategoryItem.image) }
          />
          <Switch
            className="mx-1"
            checked={childCategoryItem.status === 'active' ? true : false}
            onChange={(e) => {
              handleCategoryStatusChange(e, childCategoryItem)
            }}
            />
        </Box>
      </Box>)}
    </>
  );
};

export default ChildCategory;
