import * as React from 'react';
import Paper from '@mui/material/Paper';
import { Chart, ArgumentAxis, ValueAxis, Legend, Tooltip  } from '@devexpress/dx-react-chart-material-ui';
import { BarSeries } from '@devexpress/dx-react-chart';
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';
import { useTranslation } from "react-i18next";

const Root = props => (<Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />);
const Label = props => (<Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />);

const Revenue = ({barChartData}) => {	
	const { t } = useTranslation();

	return (
		<Paper>
			<Chart data={barChartData} >
				<ArgumentAxis />
				<ValueAxis />
				<BarSeries
					name={t("dashboardPage_Revenue")}
					valueField="value"
					argumentField="label"
					color="#1EA896" />
				<Animation />
				<Legend position="bottom" rootComponent={Root} labelComponent={Label} />
				<EventTracker />
				<Tooltip
					contentComponent={({ targetItem, text, ...restProps }) => (
						<Tooltip.Content
							{...restProps}
							text={targetItem ? `${targetItem.series} : QAR ${parseFloat(text).toFixed(2)}` : text}
						/>
					)}
				/>
				<Stack />
			</Chart>
		</Paper>
	);
}

export default Revenue;