import { useEffect, useState } from "react";
import {
  IconButton,
  Collapse,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Rating,
} from "@mui/material";
import { Boxed } from "../../Styles-Elements/Box";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarIcon from "@mui/icons-material/Star";
import deliverIcon from "../../assets/images/structure/deliver-icon.svg";
import calenderIcon from "../../assets/images/structure/calender-schedule.svg";
import plusIcon from "../../assets/images/structure/plus-icon.svg";
import minusIcon from "../../assets/images/structure/minus-icon.svg";
// import RejectOrder from "../../dialog/RejectOrder";
import ShippingOrder from "../../dialog/ShippingOrder";
import ReadyToPick from "../../dialog/ReadyToPick";
import CompleteOrder from "../../dialog/CompleteOrder";
import standardTime from "../../global-modules/standardTime";
import globalRequest from "../../global-modules/globalRequest";
import DownloadIcon from "../../assets/images/structure/download-icon.svg";
import { API, COUNTRY_CODE, ORDER_STATUS, IMAGE_URL } from "../../Enum";
import { formatPrice, getFrontImage, getDataByKeyLocale } from "../../Helpers/Helpers";
import { useDispatch, useSelector } from "react-redux";
import { currentLocalData } from "../../redux/reducers/localData";
import { changeLoader } from "../../redux/reducers/loader";
import { changeSnackbar } from "../../redux/reducers/snackbar";
import alertLogo from "../../assets/images/logo/bell_icon.png";

const OrderItem = ({ order, type, getOrders, rejectOrder, storeOptions }) => {
  let user = order?.addressJson !== "" ? JSON.parse(order?.addressJson) : order?.user;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const localData = useSelector(currentLocalData);
  const tokenData = localData.token;
  const adminCommission = tokenData?.store?.admin_commission;
  const store = storeOptions.find((storeData) => storeData.value === order?.storeId)
  // const [openDialogRejectOrder, setOpenDialogRejectOrder] = useState(false);
  const [openRow2, setOpenCollapse2] = useState(false);
  const [openOrderDetail1, setOpenOrderDetail1] = useState(false);
  const [mrpPrice, setMrpPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [openDialogReadyToPick, setOpenDialogReadyToPick] = useState(false);
  const [youEarn, setYouEarn] = useState(0);
  const [ButtonBlock,setButtonBlock] = useState(false)
  const [reasonForReject, setReasonForReject] = useState(
    String(order?.reason_of_reject).substring(0, 20)
  );
  let [walletAmount, setWalletAmount] = useState(null);
  let [refundTransaction, setRefundTransaction] = useState(null);
  let [storeRefundAmount, setStoreRefundAmount] = useState(0);
  let [rateReview, setRateReview] = useState({ rating: 0, review: "" });
  let [totalItems, setTotalItems] = useState(0);
  const orderDate = new Date(order?.createdAt);
  let [placedDate, setPlacedDate] = useState(new Date(order?.createdAt));
  const [openDialogShipping, setOpenDialogShipping] = useState(false);
  const [openDialogCompleted, setOpenDialogCompleted] = useState(false);
  let [orderDetail, setOrderDetail] = useState();

  const readMoreLess = (type = "more") => {
    if (type == "less") {
      setReasonForReject(String(order?.reason_of_reject).substring(0, 20));
      setReasonForReject(String(order?.reason_of_reject).substring(0, 20));
    } else {
      setReasonForReject(order?.reason_of_reject);
    }
  };

  const DownloadInvoice = (order_id) => {
    globalRequest("get", API.GET_ORDER_INVOICE(order_id), {}, {})
      .then(({ data, status }) => {
        if (status === 200) {
          window.open(IMAGE_URL(data?.invoice), "_blank");
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: t("somethingWentWrong"),
              state: "error",
            })
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: error?.message,
              state: "error",
            })
          );
        } else {
          dispatch(
            changeSnackbar({
              isOpen: true,
              message: t("somethingWentWrong"),
              state: "error",
            })
          );
        }
      });
  };

  const collapseOrderDetail1 = () => {
    setOpenOrderDetail1(!openOrderDetail1);
  };

  const orderLastUpdate = (order) => {
    let lastModifiedDate = standardTime(order?.updatedAt);
    if (order?.order_status_log !== "" && order?.order_status_log.length > 0) {
      let status_log = JSON.parse(order?.order_status_log).pop();
      lastModifiedDate = standardTime(status_log?.updated_at);
    }
    return lastModifiedDate;
  };

  const TableRows = ({ orderObj }) => {
    let [addOnName, setAddOnName] = useState("");

    useEffect(() => {
      if (orderObj?.addons_json !== "") {
        let jsonAddon = JSON.parse(orderObj?.addons_json);
        addOnName = jsonAddon
          .map((addon) => {
            return getDataByKeyLocale(addon?.addon_items_locales, "name");
          })
          .join(", ");
        setAddOnName(addOnName);
      }
    }, [orderObj]);

    return (
      <TableRow>
        <TableCell>
          <div className="flex">
        
            <img
              src={IMAGE_URL(getFrontImage(orderObj?.uom?.uom_images))}
              alt=""
              className="img32"
            />
            <span className="product-title">
              {orderObj?.product?.products_locales[0].title}{" "}
              {orderObj?.uom?.uomName}
              {addOnName !== "" ? (
                <>
                  <br /> {addOnName}
                </>
              ) : null}
              {orderObj?.specialRequest !== "" ? (
                <>
                  <br /> <b>{t("order_Note")}</b>: {orderObj?.specialRequest}
                </>
              ) : null}
              <br />
              {localData.token.store.businessTypeId == 97?
                orderObj?.product?.itemCode: ''}
            </span>
           
          </div><div>
          <span>
            {(![1, 2, 3].includes(localData?.token?.store?.businessTypeId) &&   orderObj?.uom?.uom_barcode_relations?.[0]?.barcode && <>
                <span style={{fontWeight:800 }}>Barcode:</span>
                {orderObj?.uom?.uom_barcode_relations[0]?.barcode}
              </>)}{" "}
            </span>
          </div>
        </TableCell>
        <TableCell dir="ltr">
          {orderObj?.quantity} x {t("order_QAR")}{" "}
          {orderObj?.salePrice !== 0 && orderObj?.salePrice !== orderObj?.price
            ? formatPrice(orderObj?.salePrice + orderObj?.addonPrice)
            : formatPrice(orderObj?.price + orderObj?.addonPrice)}
          {orderObj?.salePrice !== 0 &&
          orderObj?.salePrice !== orderObj?.price ? (
            <span className="gray-through">
              {" "}
              {t("order_QAR")}{" "}
              {formatPrice(orderObj?.price + orderObj?.addonPrice)}
            </span>
          ) : null}
        </TableCell>
        <TableCell align="right">
          {t("order_QAR")}{" "}
          {orderObj?.salePrice !== 0
            ? formatPrice(
                orderObj?.quantity *
                  (orderObj?.salePrice + orderObj?.addonPrice)
              )
            : formatPrice(
                orderObj?.quantity * (orderObj?.price + orderObj?.addonPrice)
              )}
        </TableCell>
      </TableRow>
    );
  };

  const OrderLine = ({ name, date, status = "" }) => {
    return (
      <div className={`order-status ${status}`}>
        <p className="p2">{name}</p>
        <p className="p4">
          <span dir="ltr">{date}</span>
        </p>
      </div>
    );
  };

  const TrackOrder = ({ types }) => {
    return (
      <>
        {types == ORDER_STATUS.PROCESSING ? (
          <div className="order-tracking-box">
            <OrderLine
              name={t("order_OrderReceived")}
              date={orderStatus().placed}
              status="done"
            />
            <OrderLine
              name={t("order_Processing")}
              date={orderStatus().processing}
              status="active"
            />
            <OrderLine
              name={t("order_ReadyToPick")}
              date={orderStatus().readyToPick}
            />
            <OrderLine name={t("order_Shipped")} date={orderStatus().shipped} />
            <OrderLine
              name={t("order_Completed")}
              date={orderStatus().completed}
            />
          </div>
        ) : null}
        {types == ORDER_STATUS.READYTOPICK ? (
          <div className="order-tracking-box">
            <OrderLine
              name={t("order_OrderReceived")}
              date={orderStatus().placed}
              status="done"
            />
            <OrderLine
              name={t("order_Processing")}
              date={orderStatus().processing}
              status="done"
            />
            <OrderLine
              name={t("order_ReadyToPick")}
              date={orderStatus().readyToPick}
              status="active"
            />
            <OrderLine name={t("order_Shipped")} date={orderStatus().shipped} />
            <OrderLine
              name={t("order_Completed")}
              date={orderStatus().completed}
            />
          </div>
        ) : null}
        {types == ORDER_STATUS.SHIPPED ? (
          <div className="order-tracking-box">
            <OrderLine
              name={t("order_OrderReceived")}
              date={orderStatus().placed}
              status="done"
            />
            <OrderLine
              name={t("order_Processing")}
              date={orderStatus().processing}
              status="done"
            />
            <OrderLine
              name={t("order_ReadyToPick")}
              date={orderStatus().readyToPick}
              status="done"
            />
            <OrderLine
              name={t("order_Shipped")}
              date={orderStatus().shipped}
              status="active"
            />
            <OrderLine
              name={t("order_Completed")}
              date={orderStatus().completed}
            />
          </div>
        ) : null}
        {types == ORDER_STATUS.COMPLETED ? (
          <div className="order-tracking-box">
            <OrderLine
              name={t("order_OrderReceived")}
              date={orderStatus().placed}
              status="done"
            />
            <OrderLine
              name={t("order_Processing")}
              date={orderStatus().processing}
              status="done"
            />
            <OrderLine
              name={t("order_ReadyToPick")}
              date={orderStatus().readyToPick}
              status="done"
            />
            <OrderLine
              name={t("order_Shipped")}
              date={orderStatus().shipped}
              status="done"
            />
            <OrderLine
              name={t("order_Completed")}
              date={orderStatus().completed}
              status="done"
            />
          </div>
        ) : null}
        {types == ORDER_STATUS.REJECTED ? (
          <div className="order-tracking-box">
            <OrderLine
              name={t("order_OrderReceived")}
              date={orderStatus().placed}
              status="done"
            />
            <OrderLine
              name={t("order_rejected")}
              date={orderStatus().rejected}
              status="done"
            />
          </div>
        ) : null}
        {types == ORDER_STATUS.CANCELLED ? (
          <div className="order-tracking-box">
            <OrderLine
              name={t("order_OrderReceived")}
              date={orderStatus().placed}
              status="done"
            />
            <OrderLine
              name={t("order_cancelled")}
              date={orderStatus().cancelled}
              status="done"
            />
          </div>
        ) : null}
      </>
    );
  };

  const orderStatus = () => {
    let s = null;
    try {
      s =
        order?.order_status_log !== "" && order?.order_status_log.length > 0
          ? JSON.parse(order?.order_status_log).filter(log => log?.role === undefined)
          : [];
    } catch (e) {}
    const findAndGiveDate = (types) => {
      if (s != null) {
        let a = s?.find((f) => f.status == types);
        if (a !== undefined) {
          let d = standardTime(a.updated_at);
          return d;
        } else {
          return "";
        }
      } else {
        return "";
      }
    };
    let placed = findAndGiveDate(ORDER_STATUS.PLACED);
    let processing = findAndGiveDate(ORDER_STATUS.PROCESSING);
    let readyToPick = findAndGiveDate(ORDER_STATUS.READYTOPICK);
    let shipped = findAndGiveDate(ORDER_STATUS.SHIPPED);
    let completed = findAndGiveDate(ORDER_STATUS.COMPLETED);
    let rejected = findAndGiveDate(ORDER_STATUS.REJECTED);
    let cancelled = findAndGiveDate(ORDER_STATUS.CANCELLED);

    return {
      placed: placed,
      processing: processing,
      readyToPick: readyToPick,
      shipped: shipped,
      completed: completed,
      rejected: rejected,
      cancelled: cancelled,
    };
  };

  const handleShowOrderDetail = async () => {
    if (!openRow2) {
      dispatch(changeLoader(true));
      const response = await globalRequest(
        "get",
        API.GET_ORDER_DETAILS(order?.id),
        {},
        {},
        true,
        true
      );
      orderDetail = response.data.orders;
      setOrderDetail(orderDetail);
      //
      let m = 0;
      let d = 0;
      orderDetail?.orders_details?.forEach((obj) => {
        m += parseFloat(obj.price + obj.addonPrice) * obj.quantity;
        d +=
          parseFloat(
            obj.salePrice
              ? obj.salePrice + obj.addonPrice
              : obj.price + obj.addonPrice
          ) * obj.quantity;
      });
      setMrpPrice(m);
      setDiscountedPrice(d);
      const wallet = orderDetail?.transaction?.find(
        (obj) => obj.method === "wallet" && obj.type === "purchase"
      );
      walletAmount = wallet ? wallet : null;
      setWalletAmount(walletAmount);
      totalItems = 0;
      orderDetail?.orders_details?.forEach((obj) => {
        totalItems += parseInt(obj.quantity);
      });
      setTotalItems(totalItems);
      let p = (order?.sub_total / 100) * orderDetail.store.admin_commission;
      setYouEarn((order?.sub_total - p - order?.discount).toFixed(2));
      refundTransaction = orderDetail?.transaction?.find(
        (obj) => obj.type == "refund"
      );
      setRefundTransaction(refundTransaction);
      let cancelLogs = orderDetail?.order_status_log.length > 0? orderDetail?.order_status_log?.find(
        (obj) => obj.status === "cancelled"
      ): [];
      storeRefundAmount = cancelLogs ? parseFloat(cancelLogs.store_refund) : 0;
      setStoreRefundAmount(storeRefundAmount);
      //
      if (orderDetail?.review_ratings.length > 0) {
        const storeRating = orderDetail?.review_ratings?.find(
          (f) => f.storeId !== 0
        );
        rateReview = {
          rating: storeRating?.rating,
          review: storeRating?.review,
        };
        setRateReview(rateReview);
      }

      dispatch(changeLoader(false));
    }
    setOpenCollapse2(!openRow2);
  };

  const changeOrderStatusStore = (orderId, statusType) => {
    dispatch(changeLoader(true));
    let body = {
      status: statusType,
      order_id: orderId,
      store_key: tokenData?.store?.store_key,
      store_id: tokenData?.store?.id,
    };
    let url = API.UPDATE_ORDER_STATUS_MARKETPLACE;
    let method = "post";
    globalRequest(method, url, body, {}, true, true)
      .then((res) => {
        let data = res?.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      });
  };

  const changeOrderStatus = (
    orderId,
    statusType,
    message = "",
    currentType = ORDER_STATUS.PLACED
  ) => {
    dispatch(changeLoader(true));
    let body = {
      status: statusType,
      order_id: orderId,
    };
    if (message) {
      body.reason_of_reject = message;
    }
    let url = API.UPDATE_ORDER_STATUS;
    let method = "post";
    if (currentType == ORDER_STATUS.PENDING) {
      url = API.REJECT_PENDING_ORDER(orderId);
      method = "put";
    }
    globalRequest(method, url, body, {}, true, true)
      .then((res) => {
        setButtonBlock(false);
        let data = res?.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
        getOrders();
      })
      .catch((err) => {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: t("somethingWentWrong"),
            state: "error",
          })
        );
        dispatch(changeLoader(false));
      });
  };

  const handleOrderPendingConfirm = (orderId, statusType) => {
    dispatch(changeLoader(true));
    let body = { status: statusType };
    globalRequest(
      "put",
      API.UPDATE_ORDER_CONFIRM(orderId),
      body,
      {},
      true,
      true
    )
      .then((res) => {
        let data = res?.data;
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: data?.msg,
            state: data?.ack == 1 ? "success" : "error",
          })
        );
        dispatch(changeLoader(false));
        getOrders()
      })
      .catch((err) => {
        dispatch(changeLoader(false));
      });
  };

  useEffect(() => {
    if (order?.order_status_log !== "" && order?.order_status_log.length > 0) {
      let status_log = JSON.parse(order?.order_status_log).find((log) => log?.role === undefined && log?.status === "placed");
      if (status_log) {
        placedDate = new Date(status_log?.updated_at);
        setPlacedDate(placedDate);
      }
    }
    setOpenCollapse2(false);
  }, [order]);
  const blinkingStyle = {
    animation: 'blinking 1s infinite'
  };
  const getBackgroundColor = (total_amount, first_one) => {
      if (first_one) {
        return '#90ee905e';
      } else {
      if ( total_amount > 300) {
        return 'rgb(224 36 36 / 23%)';
      } else if (total_amount >= 100 && total_amount <= 300) {
        return 'rgb(255 191 0 / 36%)';
      } else {
        return '#FFFFFF'; // Default color if none of the conditions match test
      }
      }

   
  };

  return (
    <>
       <style>
        {`
          @keyframes blinking {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
      
      <div className="table-type-parent"     style={{ backgroundColor: getBackgroundColor(order?.total_amount , order?.first_order) }}>
        <div className="table-type-box" id={`orderBorderBox-${order?.id}`}>
          <div className="table-type-td">
            <IconButton onClick={handleShowOrderDetail}>
              {openRow2 ? (
                <img src={minusIcon} alt="" className="" />
              ) : (
                <img src={plusIcon} alt="" className="" />
              )}
            </IconButton>
          </div>
          <div className="table-type-td flex">
          {(order?.first_order === true || order?.average_order_value > 300) ? (
                <img 
                  src={alertLogo} 
                  alt="firstOrder" 
                  className="icon24" 
                  style={blinkingStyle} 
                />
              ) : null}     
            {order?.deliver === "now" ? (
              <img src={deliverIcon} alt="deliver" className="icon24" />
            ) : (
              <img src={calenderIcon} alt="deliver" className="icon24" />
            )}
            <div className="relative">
              <span className="type-th">{t("order_OrderType")}</span>
              <h3 className="type-td width220" style={{whiteSpace: "normal"}}>
                {order?.deliver == "now"
                  ? t("order_DeliverNow")
                  : `${t("order_Schedule")} | ${
                      order?.delivery_date !== null
                        ? standardTime(
                            order?.delivery_date + " " + order?.delivery_time
                          )
                        : null
                    }`}
              </h3>
            </div>
          </div>
          <div className="table-type-td">
            <span className="type-th">{t("order_OrderID")}</span>
            <h3 className="type-td">#{order?.order_id}</h3>
          </div>
          <div className="table-type-td">
            <span className="type-th">{t("manageUsers_Store")}</span>
            <h3 className="type-td">{store?.label}</h3>
            <span className="type-th">{store?.secondLabel}</span>
          </div>
          <div className="table-type-td">
            <span className="type-th">{t("order_CustomerName")}</span>
            <h3 className="type-td width120">{order?.user?.full_name}</h3>
          </div>
          <div className="table-type-td">
            <span className="type-th">{t("order_NoOfItems")}</span>
            <h3 className="type-td">
              {order.total_quantity} {t("order_Items")}
            </h3>
          </div>
          <div className="table-type-td">
            <span className="type-th">{t("order_totalAmount")}</span>
            <h3 className="type-td">
              {t("order_QAR")} {formatPrice(order?.total_amount)}
            </h3>
          </div>

          <div className="table-type-td">
            <span className="type-th">{t("order_orderDate")}</span>
            <h3 className="type-td">{standardTime(placedDate)}</h3>
            {/* <h3 className="type-td">{standardTime(order?.createdAt)}</h3> */}
          </div>
          {/* <div className="table-type-td"> */}
          {/* <span className="type-th">{t("order_lastUpdate")}</span> */}
          {/* <h3 className="type-td">{orderLastUpdate(order)}</h3> */}
          {/* </div> */}
          <div
            className="table-type-td"
            onClick={() => DownloadInvoice(order?.id)}
          >
            <h3 className="type-td pointer">
              <img src={DownloadIcon} className="icon20" />{" "}
              {t("order_DownloadInvoice")}
            </h3>
          </div>
        </div>

        {orderDetail ? (
          <Collapse in={openRow2} timeout="auto">
            <div className="table-type-body">
              <div className="table-type-body-inner-flex">
                <div className="left-detail-box">
                  <h6 className="h6">{t("order_ProductDetails")}</h6>
                  <Table className="product-table">
                    <TableBody>
                      {orderDetail?.orders_details?.map((obj, ind) => (
                        <TableRows key={`order-detail-${ind}`} orderObj={obj} />
                      ))}
                    </TableBody>
                  </Table>
                  {type !== ORDER_STATUS.PLACED ? (
                    <>
                      <Divider style={{ marginTop: "15px" }} />
                      <h6 className="h6 gray" style={{ marginTop: "20px" }}>
                        {t("order_OrderTracking")}
                      </h6>
                      <TrackOrder types={type} />
                    </>
                  ) : null}
                  {type == ORDER_STATUS.COMPLETED ? (
                    <>
                      <Divider style={{ marginTop: "15px" }} />
                      <h6 className="h6 gray" style={{ marginTop: "20px" }}>
                        {t("order_RatingReview")}
                      </h6>
                      <Rating
                        name="rating-read"
                        defaultValue={rateReview?.rating}
                        readOnly
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                      <p className="p2">{rateReview?.review}</p>
                    </>
                  ) : null}
                </div>
                <div className="right-detail-box">
                  <h6 className="h6 mb-4">{t("order_PaymentDetails")}</h6>
                  <div className="payment-value">
                    <span className="p2">{t("order_MRP")}</span>
                    <span className="p2-bold through-text">
                      {discountedPrice !== mrpPrice ? (
                        <strike>
                          {t("order_QAR")} {formatPrice(mrpPrice)}
                        </strike>
                      ) : (
                        `${t("order_QAR")} ${formatPrice(discountedPrice)}`
                      )}
                    </span>
                  </div>
                  {discountedPrice !== mrpPrice ? (
                    <div className="payment-value">
                      <span className="p2">{t("order_discountedPrice")}</span>
                      <span className="p2-bold">
                        {t("order_QAR")} {formatPrice(discountedPrice)}
                      </span>
                    </div>
                  ) : null}
                  {order?.delivery_charges && order?.delivery_charges !== 0 ? (
                    <div className="payment-value">
                      <span className="p2">{t("order_deliveryCharges")}</span>
                      <span className="p2-bold">
                        {t("order_QAR")} {formatPrice(order?.delivery_charges)}
                      </span>
                    </div>
                  ) : null}
                  {order?.tax > 0 ? (
                    <div className="payment-value">
                      <span className="p2">{t("order_tax")}</span>
                      <span className="p2-bold">
                        {t("order_QAR")} {formatPrice(order?.tax)}
                      </span>
                    </div>
                  ) : null}

                  {order?.discount > 0 ? (
                    <div className="payment-value">
                      <span className="p2">{t("order_CouponDiscount")}</span>
                      <span className="p2-bold">
                        {t("order_QAR")} {formatPrice(order?.discount)}
                      </span>
                    </div>
                  ) : null}
                  {order?.offer_amount > 0 ?<div className="payment-value">
                    <span className="p2">{t('order_DiscountOffer')}</span>
                    <span className="p2-bold">
                      -{t('order_QAR')} {formatPrice(order?.offer_amount)}
                    </span>
                  </div>: null}

                  <Divider />
                  <div className="payment-value-total">
                    <span className="p2">{t("order_totalAmount")}</span>
                    <span className="p2">
                      {t("order_QAR")} {formatPrice(order?.total_amount)}
                    </span>
                  </div>
                  {walletAmount ? (
                    <div className="payment-value-total">
                      <span className="p2">{t("order_WalletAmount")}</span>
                      <span className="p2">
                        <span dir="ltr">
                          - {t("order_QAR")} {formatPrice(walletAmount?.amount)}
                        </span>
                      </span>
                    </div>
                  ) : null}
                  {walletAmount &&
                  order?.total_amount - walletAmount?.amount > 0 ? (
                    <div className="payment-value-total">
                      <span className="p2">{t("order_netPaid")}</span>
                      <span className="p2">
                        {t("order_QAR")}{" "}
                        {formatPrice(
                          order?.total_amount - walletAmount?.amount
                        )}
                      </span>
                    </div>
                  ) : null}
                  {type == ORDER_STATUS.COMPLETED ? (
                    <>
                      <Divider className="my-5" />
                      <div className="pink-box">
                        <div className="payment-value">
                          <span className="p2">{t("order_YoullEarn")}</span>
                          <span className="p2-bold">
                            {t("order_QAR")} {youEarn}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {(type == ORDER_STATUS.CANCELLED ||
                    type == ORDER_STATUS.REJECTED) &&
                  refundTransaction ? (
                    <>
                      <Divider className="my-5" />
                      <div className="pink-box">
                        <div className="payment-value">
                          <span className="p2">{t("order_YoullEarn")}</span>
                          <span className="p2-bold">
                            {t("order_QAR")}{" "}
                            {storeRefundAmount > 0
                              ? formatPrice(storeRefundAmount)
                              : formatPrice(refundTransaction?.amount)}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <Divider className="my-5" />
                  {order?.specialRequest !== "" ? (
                    <>
                      <div className="payment-value">
                        <span className="p2">
                          {t("order_OrderNote")} {order?.specialRequest}
                        </span>
                      </div>
                      <Divider className="my-5" />
                    </>
                  ) : null}
                  {type == ORDER_STATUS.PLACED ? (
                    <div className="btn-row">
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        className="mx-5 secondary-gray"
                        // onClick={() => setOpenDialogRejectOrder(true)}
                        onClick={() => rejectOrder({
                          id: order?.id,
                          type:ORDER_STATUS.PLACED
                        })}
                      >
                        {t("order_Reject")}
                      </Button>
                      <Button
                      disabled={ButtonBlock}
                        onClick={() => {
                          setButtonBlock(true)
                          setTimeout(() => {
                            changeOrderStatus(order?.id, ORDER_STATUS.PROCESSING);
                          }, 2000);
                        }}


                        fullWidth
                        variant="contained"
                        size="large"
                        className="mx-5 secondary"
                      >
                        {t("order_Accept")}
                      </Button>
                    </div>
                  ) : null}
                  {type == ORDER_STATUS.PENDING ? (
                    <div className="btn-row">
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        className="mx-5 secondary-gray"
                        // onClick={() => setOpenDialogRejectOrder(true)}
                        onClick={() => rejectOrder({
                          id: order?.id,
                          type:ORDER_STATUS.PENDING
                        })}
                      >
                        {t("order_Reject")}
                      </Button>
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        className="mx-5 secondary"
                        onClick={() =>
                          handleOrderPendingConfirm(
                            order?.id,
                            ORDER_STATUS.PROCESSING
                          )
                        }
                      >
                        {t("order_Confirm")}
                      </Button>
                    </div>
                  ) : null}
                  {type == ORDER_STATUS.PROCESSING ? (
                    <div className="btn-row">
                      <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        className="mx-5"
                        onClick={() => setOpenDialogReadyToPick(true)}
                      >
                        {t("order_ReadyToPick")}
                      </Button>
                    </div>
                  ) : null}

                  {orderDetail.store.market_place == 1 ? (
                    <>
                      {type == ORDER_STATUS.READYTOPICK ? (
                        <div className="btn-row">
                          <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            className="mx-5"
                            onClick={() => setOpenDialogShipping(true)}
                          >
                            {t("order_Shipped")}
                          </Button>
                        </div>
                      ) : null}
                      {type == ORDER_STATUS.SHIPPED ? (
                        <div className="btn-row">
                          <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            className="mx-5"
                            onClick={() => setOpenDialogCompleted(true)}
                          >
                            {t("order_Completed")}
                          </Button>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  <Divider className="my-5" />
                  <Boxed
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <h6 className="h6 mb-4">Other Details</h6>
                    <IconButton onClick={collapseOrderDetail1}>
                      {openOrderDetail1 ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </Boxed>
                  <Collapse in={openOrderDetail1} timeout="auto">
                    <div className="relative mb-4">
                      <p className="p3">{t("order_orderDate")}</p>
                      <p className="p2">
                        <span dir="ltr">{standardTime(orderDate)}</span>
                      </p>
                    </div>
                    <div className="relative mb-4">
                      <p className="p3">{t("order_paymentMethod")}</p>
                      <p className="p2">{t(order?.payment_method)}</p>
                    </div>
                    <div className="relative mb-4">
                      <p className="p3">{t("order_customerDetails")}</p>
                      <p className="p2">
                        {user?.full_name} |{" "}
                        <span dir="ltr">
                          {COUNTRY_CODE} {user?.contact_number}
                        </span>{" "}
                        {user?.email}
                      </p>
                    </div>
                    {type != ORDER_STATUS.PLACED &&
                    type !== ORDER_STATUS.PROCESSING ? (
                      order?.driver ? (
                        <>
                          <div className="relative">
                            <p className="p3">{t("order_driverDetails")}</p>
                            <p className="p2">
                              {order?.driver?.first_name}{" "}
                              {order?.driver?.last_name}
                            </p>
                            <p className="p2">
                              <span dir="ltr">
                                {COUNTRY_CODE} {order?.driver?.phone}
                              </span>
                            </p>
                          </div>
                        </>
                      ) : null
                    ) : null}
                  </Collapse>
                  {type == ORDER_STATUS.REJECTED ? (
                    <>
                      <Divider className="my-5" />
                      <div className="relative ">
                        <p className="p3 semi">{t("order_rejectedReason")}</p>

                        <p className="p2">
                          {order?.rejected_by !== 0
                            ? orderDetail?.rejected_by_user?.role === "admin"
                              ? t("order_rejectedByAdmin")
                              : t("order_rejectedByVendor")
                            : t("order_cancelledBySystem")}
                        </p>

                        <p className="p2">
                          {reasonForReject}
                          {reasonForReject.length == 20 ? "..." : ""}
                        </p>
                        <p className="p2">
                          {reasonForReject.length == 20 ? (
                            <span
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => readMoreLess("more")}
                            >
                              {" "}
                              {t("order_readMore")}{" "}
                            </span>
                          ) : (
                            <span
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => readMoreLess("less")}
                            >
                              {" "}
                              {t("order_readLess")}
                            </span>
                          )}
                        </p>
                      </div>
                    </>
                  ) : null}
                  {type == ORDER_STATUS.CANCELLED &&
                  order?.reason_of_reject !== "" ? (
                    <>
                      <Divider className="my-5" />
                      <div className="relative">
                        <p className="p3 semi">{t("order_cancelDetails")}</p>
                        <p className="p2">
                          {order?.rejected_by != 0
                            ? orderDetail?.rejected_by_user?.role === "admin"
                              ? t("order_cancelledByAdmin")
                              : t("order_CancelledByCustomer")
                            : null}
                        </p>
                        <p className="p3 semi" style={{ marginTop: "10px" }}>
                          {t("order_cancelReason")}
                        </p>
                        <p className="p2">{order?.reason_of_reject}</p>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </Collapse>
        ) : null}
      </div>

      {/* {type == ORDER_STATUS.PLACED || type == ORDER_STATUS.PENDING ? (
        <RejectOrder
          isOpen={openDialogRejectOrder}
          handleClose={() => {
            setOpenDialogRejectOrder(false);
          }}
          submit={(message) => {
            if (type == ORDER_STATUS.PLACED) {
              changeOrderStatus(
                order?.id,
                ORDER_STATUS.REJECTED,
                message,
                ORDER_STATUS.PLACED
              );
            } else {
              changeOrderStatus(
                order?.id,
                ORDER_STATUS.REJECTED,
                message,
                ORDER_STATUS.PENDING
              );
            }
            setOpenDialogRejectOrder(false);
          }}
        />
      ) : null} */}
      {type == ORDER_STATUS.PROCESSING ? (
        <ReadyToPick
          isOpen={openDialogReadyToPick}
          handleClose={() => {
            setOpenDialogReadyToPick(false);
          }}
          confirm={() => {
            changeOrderStatus(order?.id, ORDER_STATUS.READYTOPICK);
            setOpenDialogReadyToPick(false);
          }}
        />
      ) : null}
      {type == ORDER_STATUS.READYTOPICK ? (
        <ShippingOrder
          isOpen={openDialogShipping}
          handleClose={() => {
            setOpenDialogShipping(false);
          }}
          confirm={() => {
            changeOrderStatusStore(order?.id, ORDER_STATUS.SHIPPED);
            setOpenDialogShipping(false);
          }}
        />
      ) : null}
      {type == ORDER_STATUS.SHIPPED ? (
        <CompleteOrder
          isOpen={openDialogCompleted}
          handleClose={() => {
            setOpenDialogCompleted(false);
          }}
          confirm={() => {
            changeOrderStatusStore(order?.id, ORDER_STATUS.COMPLETED);
            setOpenDialogCompleted(false);
          }}
        />
      ) : null}
    </>
  );
};

export default OrderItem;
